import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = process.env.REACT_APP_API_URL;

export const CheckAccNameExist = async (name, user_id, token) => {
  try {
    //console.log("CheckAccNameExist");
    const response = await axios.post(
      `${BASE_URL}/account/CheckAccNameExist`,
      {
        name: name,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllAccountsByStore = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllAccounts");
    const response = await axios.post(
      `${BASE_URL}/account/getAllAccountsByStore`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckDefaultAcc = async (account_id, user_id, token) => {
  try {
    //console.log("CheckDefaultAcc");
    const response = await axios.post(
      `${BASE_URL}/account/CheckDefaultAcc`,
      {
        account_id: account_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccountByID = async (account_id, user_id, token) => {
  try {
    //console.log("GetAccountByID");
    const response = await axios.post(
      `${BASE_URL}/account/getAccountByID`,
      {
        account_id: account_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccToIdBySaleOrder = async (so_id, user_id, token) => {
  try {
    //console.log("GetAccToIdBySaleOrder");
    const response = await axios.post(
      `${BASE_URL}/sale/GetAccToIdBySaleOrder`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccToIdBySaleOrder1 = async (so_id, user_id, token) => {
  try {
    //console.log("GetAccToIdBySaleOrder1");
    const response = await axios.post(
      `${BASE_URL}/sale/GetAccToIdBySaleOrder1`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccToIdByPusOrder = async (po_id, user_id, token) => {
  try {
    //console.log("GetAccToIdByPusOrder");
    const response = await axios.post(
      `${BASE_URL}/purchase/GetAccToIdByPusOrder`,
      {
        po_id: po_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllAccountTypes = async (user_id, token) => {
  try {
    //console.log("GetAllAccountTypes");
    const response = await axios.post(
      `${BASE_URL}/account/GetAllAccountTypes`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllAccountCategories = async (user_id, token) => {
  try {
    //console.log("GetAllAccountTypes");
    const response = await axios.post(
      `${BASE_URL}/account/GetAllAccountCategories`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAccNamesCash = async (store_id, user_id, token) => {
  try {
    //console.log("getAccNamesCash");
    const response = await axios.post(
      `${BASE_URL}/payment/getAccNamesCash`,
      {
        store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAccNamesAssetCash = async (store_id, user_id, token) => {
  try {
    //console.log("getAccNamesCash");
    const response = await axios.post(
      `${BASE_URL}/payment/getAccNamesAssetCash`,
      { store_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAccNameCusVen = async (store_id, user_id, token) => {
  try {
    //console.log("getAccNameCusVen");
    const response = await axios.post(
      `${BASE_URL}/payment/getAccNameCusVen`,
      {
        store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAccNamesCus = async (store_id, user_id, token) => {
  try {
    //console.log("getAccNameCusVen");
    const response = await axios.post(
      `${BASE_URL}/payment/getAccNameCus`,
      {
        store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAccNamesVen = async (store_id, user_id, token) => {
  try {
    //console.log("getAccNameCusVen");
    const response = await axios.post(
      `${BASE_URL}/payment/getAccNameVen`,
      {
        store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAccNamesExp = async (store_id, user_id, token) => {
  try {
    //console.log("getAccNameCusVen");
    const response = await axios.post(
      `${BASE_URL}/payment/getAccNameExp`,
      {
        store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAcc1BalFrom = async (account_id, user_id, token) => {
  try {
    //console.log("getAcc1BalFrom");
    const response = await axios.post(
      `${BASE_URL}/payment/getAcc1BalFrom`,
      {
        account_id: account_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckAccDeleteStatus = async (account_id, user_id, token) => {
  try {
    //console.log("CheckAccDeleteStatus");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/account/CheckAccDeleteStatus`,
      {
        account_id: account_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteAccount = async (account_id, user_id, token) => {
  try {
    //console.log("DeleteAccount");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/account/DeleteAccount`,
      {
        account_id: account_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddAccountOpeningBal = async (
  acc_name,
  acc_description,
  acc_type_id,
  acc_category_id,
  acc_parent_id,
  acc_notes,
  ledger_notes,
  opening_balance,
  ledger_date,
  ledger_type_id,
  store_id,
  user_id,
  token
) => {
  try {
    //console.log("AddAccountOpeningBal");
    const response = await axios.post(
      `${BASE_URL}/account/AddAccountOpeningBal`,
      {
        acc_name: acc_name,
        acc_description: acc_description,
        acc_type_id: acc_type_id,
        acc_category_id: acc_category_id,
        acc_parent_id: acc_parent_id,
        acc_notes: acc_notes,
        ledger_notes: ledger_notes,
        opening_balance: opening_balance,
        ledger_date: ledger_date,
        ledger_type_id: ledger_type_id,
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditAccountOpeningBal = async (
  account_id,
  acc_name,
  acc_description,
  acc_type_id,
  acc_notes,
  ledger_notes,
  opening_balance,
  ledger_date,
  ledger_type_id,
  user_id,
  token
) => {
  try {
    //console.log("EditAccountOpeningBal");
    const response = await axios.put(
      `${BASE_URL}/account/EditAccountOpeningBal`,
      {
        account_id: account_id,
        acc_name: acc_name,
        acc_description: acc_description,
        acc_type_id: acc_type_id,
        acc_notes: acc_notes,
        ledger_notes: ledger_notes,
        opening_balance: opening_balance,
        ledger_date: ledger_date,
        ledger_type_id: ledger_type_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccToIdBySalesReturn = async (sr_id, user_id, token) => {
  try {
    //console.log("GetAccToIdBySaleOrder");
    const response = await axios.post(
      `${BASE_URL}/salesReturn/GetAccToIdBySalesReturn`,
      {
        sr_id: sr_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllAccounts = async (user_id, token) => {
  try {
    //console.log("GetAllAccounts");
    const response = await axios.post(
      `${BASE_URL}/account/getAllAccounts`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetOverviewAccounts = async (store_id, range, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/overview/getOverviewAccounts`,
      {
        store_id: store_id,
        range: range,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccBal = async (user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/overview/getAccBal`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetCashBal = async (user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/overview/getCashBal`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetopeningBal = async (product_id, store_id, user_id, token) => {
  try {
    //console.log("GetopeningBal");
    // const headers = {
    //   "Content-Type": "application/json",
    //   product_id: product_id,
    //   store_id: store_id,
    // };
    const response = await axios.post(
      `${BASE_URL}/inventory/getopeningBal`,
      {
        //headers,

        product_id: product_id,
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const Ledger_AddOpeningBalance = async (
  account_id,
  opening_balance,
  date,
  type_id,
  note,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/account/AddOpeningBalance`,
      {
        account_id: account_id,
        opening_balance: opening_balance,
        date: date,
        type_id: type_id,
        note: note,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditOpeningBalance = async (
  store_id,
  product_id,
  opening_balance,
  mn,
  mx,
  user_id,
  token
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/inventory/EditOpeningBalance`,
      {
        store_id: store_id,
        product_id: product_id,
        opening_balance: opening_balance,
        mn: mn,
        mx: mx,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const Ledger_EditOpeningBalance = async (
  account_id,
  opening_balance,
  type_id,
  user_id,
  token
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/account/EditOpeningBalance`,
      {
        account_id: account_id,
        opening_balance: opening_balance,
        type_id: type_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const Verifyopeningbalexist = async (
  product_id,
  store_id,
  user_id,
  token
) => {
  try {
    //console.log("Verifyopeningbalexist");
    // const headers = {
    //   "Content-Type": "application/json",
    //   product_id: product_id,
    //   store_id: store_id,
    // };
    const response = await axios.post(
      `${BASE_URL}/inventory/verifyopeningbalexist`,
      {
        product_id: product_id,
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
