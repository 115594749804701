import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  GetAllPaymentDetail_Exp,
  //GetAllPaymentDetail_Pay,
  deletePayment,
} from "../../api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const Expense = () => {
  const [AllAccounts, setAllAccounts] = useState("");
  const [r_id, setr_id] = useState("");
  //const [role, setRole] = useState("");
  const { currentColor, storeG, userPermID, userID, userToken } =
    useStateContext();
  const { promiseInProgress } = usePromiseTracker();
  const [OrderDate, setOrderDate] = useState("");
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const customerGridImage2 = (props) => <div>{"E" + props.payment_id}</div>;
  const isCurrentYear = (date) =>
    new Date(
      date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
    ).getUTCFullYear() === new Date().getUTCFullYear();

  const customersGrid =
    // role === "B3" || role === "S3"
    //   ?
    [
      // { headerTemplate: ` `, type: "checkbox", width: "50",  },
      {
        headerText: "ID",
        field: "payment_id",
        template: customerGridImage2,
        minWidth: "90",
        width: "90",
        maxWidth: "100",
        textAlign: "right",
      },

      {
        field: "datetime",
        headerText: "DateTime",
        minWidth: "140",
        width: "140",
        maxWidth: "160",
        textAlign: "Center",
      },

      {
        field: "From",
        headerText: "From",
        minWidth: "180",
        width: "200",
        maxWidth: "320",
        textAlign: "left",
      },
      {
        field: "To",
        headerText: "Expense",
        minWidth: "180",
        width: "200",
        maxWidth: "320",
        textAlign: "left",
      },

      {
        field: "amount_paid",
        headerText: "Amt Paid",
        minWidth: "140",
        width: "140",
        maxWidth: "160",
        format: "C2",
        textAlign: "right",
      },
      {
        field: "note",
        headerText: "Note",
        minWidth: "140",
        width: "160",
        maxWidth: "360",
        textAlign: "left",
      },
      //   ]
      // : [
      //     // { headerTemplate: ` `, type: "checkbox", width: "50",  },
      //     {
      //       headerText: "ID",
      //       field: "payment_id",
      //       template: customerGridImage2,
      //       minWidth: "90",
      //       width: "90",
      //       maxWidth: "100",
      //       textAlign: "right",
      //     },

      //     {
      //       field: "datetime",
      //       headerText: "DateTime",
      //       minWidth: "140",
      //       width: "140",
      //       maxWidth: "160",
      //       textAlign: "Center",
      //     },
      //     {
      //       field: "note",
      //       headerText: "Note",
      //       minWidth: "140",
      //       width: "160",
      //       maxWidth: "360",
      //       textAlign: "left",
      //     },
      //     {
      //       field: "amount_paid",
      //       headerText: "Amount",
      //       minWidth: "140",
      //       width: "140",
      //       maxWidth: "160",
      //       format: "C2",
      //       textAlign: "right",
      //     },
    ];

  const handleAddEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("Add new");
      navigate(`/Expense/AddExpense/${storeG.store_id}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("edit new");
      if (r_id !== "") {
        if (isCurrentYear(OrderDate)) {
          navigate(`/Expense/EditExpense/${r_id}`);
        } else {
          alert(`E${r_id} was created previous year!\nIt is not editable.`);
        }
      } else {
        alert("Please select Expense to edit.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditEmployeesClick1 = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("delete");
      if (r_id !== "") {
        if (isCurrentYear(OrderDate)) {
          if (
            window.confirm(`Are you sure you want to delete E${r_id} Expense?`)
          ) {
            const resp = await deletePayment(r_id, userID, userToken);
            if (resp.status === 200) {
              alert("Expense deleted successfully.");
              window.location.reload();
            } else {
              alert("Expense failed to delete.");
            }
          }
        } else {
          alert(
            `E${r_id.payment_id} was created previous year!\nIt can not be deleted.`
          );
        }
      } else {
        alert("Please select Expense to delete.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    const selectedRowData = args.data;
    setr_id(selectedRowData.payment_id);
    setOrderDate(selectedRowData.datetime);
    // console.log(selectedRowData.payment_id);
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      //console.log(store_id);
      if (storeG !== null && storeG.store_id !== 0 && userID && userToken) {
        trackPromise(
          Promise.all([
            GetAllPaymentDetail_Exp(storeG.store_id, userID, userToken),
          ])
            .then(([result]) => {
              setAllAccounts(result.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [storeG, userID, userToken]);

  const settings = { checkboxMode: "ResetOnRowClick" };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Financials" title="EXPENSE" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {userPermID.includes(5041) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddEmployeesClick}
              />
            </Col>
          )}
          {userPermID.includes(5042) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Update"
                  borderRadius="10px"
                  onClick={handleEditEmployeesClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Delete"
                  borderRadius="10px"
                  onClick={handleEditEmployeesClick1}
                />
              </Col>
            </>
          )}
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          <GridComponent
            className="custom-grid"
            dataSource={AllAccounts}
            allowPaging={true}
            pageSettings={{ pageSize: 16 }}
            allowSorting
            allowResizing
            toolbar={["Search"]}
            rowSelected={handleRowSelected}
            selectionSettings={settings}
            rowHeight={36}
          >
            <ColumnsDirective>
              {customersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </>
      )}
    </div>
  );
};

export default Expense;
