import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = process.env.REACT_APP_API_URL;

export const CheckCusNameExist = async (name, user_id, token) => {
  try {
    //console.log("CheckCusNameExist");
    const response = await axios.post(
      `${BASE_URL}/customer/CheckCusNameExist`,
      {
        name: name,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetCustomerById = async (customer_id, user_id, token) => {
  try {
    //console.log("GetCustomerById");
    const response = await axios.post(
      `${BASE_URL}/customer/getCustomerByID`,
      { customer_id: customer_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllSalesByCusID = async (
  store_id,
  est_sale,
  customer_id,
  user_id,
  token
) => {
  try {
    //console.log("getAllSalesByCusID");
    const response = await axios.post(
      `${BASE_URL}/sale/getAllSalesByCusID`,
      {
        store_id: store_id,
        est_sale: est_sale,
        customer_id: customer_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllCustomersByStore = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllAccounts");
    const response = await axios.post(
      `${BASE_URL}/customer/getAllCustomersByStore`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleCustomerById = async (customer_id, user_id, token) => {
  try {
    //console.log("GetSaleCustomerById");
    const response = await axios.post(
      `${BASE_URL}/sale/getCustomerByID`,
      {
        customer_id: customer_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleOrderCustomerByID = async (so_id, user_id, token) => {
  try {
    //console.log("GetSaleOrderCustomerByID");
    const response = await axios.post(
      `${BASE_URL}/sale/getSaleOrderCustomerByID`,
      {
        so_id: so_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSaleCusBalByID = async (customer_id, user_id, token) => {
  try {
    //console.log("getSaleCusBalByID");
    const response = await axios.post(
      `${BASE_URL}/sale/getSaleCusBalByID`,
      {
        customer_id: customer_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetCustomerList = async (store_id, user_id, token) => {
  try {
    // console.log(payment_id);
    const response = await axios.post(
      `${BASE_URL}/salesReturn/GetCustomerList`,
      { store_id: store_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    //console.log(response.data);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllCustomers = async (user_id, token) => {
  try {
    //console.log("GetAllCustomers");
    const response = await axios.post(
      `${BASE_URL}/customer/getAllCustomers`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllCustomersName = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllCustomersName");
    const response = await axios.post(
      `${BASE_URL}/customer/getAllCustomersName`,
      { store_id: store_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckCusDeleteStatus = async (customer_id, user_id, token) => {
  try {
    //console.log("CheckCusDeleteStatus");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/customer/CheckCusDeleteStatus`,
      {
        customer_id: customer_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddCustomerApi = async (
  name,
  phone,
  email,
  website,
  contact_name,
  contact_phone,
  contact_email,
  profile,
  note,
  b_street,
  b_city,
  b_state,
  b_zip,
  b_country,
  b_phone1,
  b_phone2,
  s_street,
  s_city,
  s_state,
  s_zip,
  s_country,
  attention_name,
  s_phone,
  acc_name,
  acc_type_id,
  type_id,
  opening_balance,
  store_id,
  user_id,
  token
) => {
  try {
    // console.log(profile);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("website", website);
    formData.append("contact_name", contact_name);
    formData.append("contact_phone", contact_phone);
    formData.append("contact_email", contact_email);
    formData.append("profile", profile);
    formData.append("notes", note);
    formData.append("b_street", b_street);
    formData.append("b_city", b_city);
    formData.append("b_state", b_state);
    formData.append("b_zip", b_zip);
    formData.append("b_country", b_country);
    formData.append("b_phone1", b_phone1);
    formData.append("b_phone2", b_phone2);
    formData.append("s_street", s_street);
    formData.append("s_city", s_city);
    formData.append("s_state", s_state);
    formData.append("s_zip", s_zip);
    formData.append("s_country", s_country);
    formData.append("s_attention_name", attention_name);
    formData.append("s_phone", s_phone);
    formData.append("acc_name", acc_name);
    formData.append("acc_type_id", acc_type_id);
    formData.append("type_id", type_id);
    formData.append("opening_balance", opening_balance);
    formData.append("store_id", store_id);

    const response = await axios.post(
      `${BASE_URL}/customer/addCustomer`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );

    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditCustomerApi = async (
  customer_id,
  name,
  phone,
  email,
  website,
  contact_name,
  contact_phone,
  contact_email,
  profile,
  note,
  b_id,
  b_street,
  b_city,
  b_state,
  b_zip,
  b_country,
  b_phone1,
  b_phone2,
  s_id,
  s_street,
  s_city,
  s_state,
  s_zip,
  s_country,
  attention_name,
  s_phone,
  account_id,
  acc_type_id,
  type_id,
  opening_balance,
  user_id,
  token
) => {
  try {
    //console.log("EditCustomerApi");

    const formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("website", website);
    formData.append("contact_name", contact_name);
    formData.append("contact_phone", contact_phone);
    formData.append("contact_email", contact_email);
    formData.append("profile", profile);
    formData.append("notes", note);
    formData.append("billing_address_id", b_id);
    formData.append("b_street", b_street);
    formData.append("b_city", b_city);
    formData.append("b_state", b_state);
    formData.append("b_zip", b_zip);
    formData.append("b_country", b_country);
    formData.append("b_phone1", b_phone1);
    formData.append("b_phone2", b_phone2);
    formData.append("shipping_address_id", s_id);
    formData.append("s_street", s_street);
    formData.append("s_city", s_city);
    formData.append("s_state", s_state);
    formData.append("s_zip", s_zip);
    formData.append("s_country", s_country);
    formData.append("s_attention_name", attention_name);
    formData.append("s_phone", s_phone);
    formData.append("account_id", account_id);
    formData.append("acc_type_id", acc_type_id);
    formData.append("type_id", type_id);
    formData.append("opening_balance", opening_balance);

    //console.log(formData);
    const response = await axios.put(
      `${BASE_URL}/customer/editCustomer`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
        // customer_id: customer_id,
        // name: name,
        // phone: phone,
        // email: email,
        // website: website,
        // contact_name: contact_name,
        // contact_phone: contact_phone,
        // contact_email: contact_email,
        // profile: profile,
        // notes: note,

        // billing_address_id: b_id,
        // b_street: b_street,
        // b_city: b_city,
        // b_state: b_state,
        // b_zip: b_zip,
        // b_country: b_country,
        // b_phone1: b_phone1,
        // b_phone2: b_phone2,

        // shipping_address_id: s_id,
        // s_street: s_street,
        // s_city: s_city,
        // s_state: s_state,
        // s_zip: s_zip,
        // s_country: s_country,
        // s_attention_name: attention_name,
        // s_phone: s_phone,

        // account_id: account_id,
        // acc_type_id: acc_type_id,
        // type_id: type_id,
        // opening_balance: opening_balance,
      }
    );

    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const DeleteCustomer = async (customer_id, user_id, token) => {
  try {
    //console.log("DeleteAccount");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/customer/DeleteCustomer`,
      {
        customer_id: customer_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteCustomerTypeById = async (type_id, user_id, token) => {
  try {
    //console.log("DeleteUnitById");
    const response = await axios.post(
      `${BASE_URL}/customer/deleteCustomerTypeById`,
      {
        type_id: type_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddCustomerTypeApi = async (names, user_id, token) => {
  try {
    //console.log("AddUnit");
    //console.log(names);
    const response = await axios.post(
      `${BASE_URL}/customer/addCustomerType`,
      {
        name: names,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditCustomerTypeApi = async (type_id, names, user_id, token) => {
  try {
    //console.log("AddUnit");
    //console.log(names);
    const response = await axios.put(
      `${BASE_URL}/customer/editCustomerType`,
      { type_id: type_id, name: names },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetCustomerTypeById = async (type_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/customer/GetCustomerTypeById`,
      { type_id: type_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllCustomerTypes = async (user_id, token) => {
  try {
    //console.log("GetAllUnits");
    const response = await axios.post(
      `${BASE_URL}/customer/getAllCustomerTypes`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetNumCust = async (user_id, token) => {
  try {
    //console.log("GetNumCust");
    const response = await axios.post(
      `${BASE_URL}/overview/getNumCust`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
