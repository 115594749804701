import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { GetAllSalesByID, DeleteSaleOrderApi } from "../../api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const Estimates = () => {
  const [AllAccounts, setAllAccounts] = useState("");
  const [so_id, setso_id] = useState("");
  const { currentColor, storeG, userPermID, userID, userToken } =
    useStateContext();
  const navigate = useNavigate();
  //const [est_sale, setest_sale] = useState("E");
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(false);

  const customersGrid = [
    {
      headerText: "E#",
      field: "Q-S #",
      minWidth: "110",
      width: "110",
      maxWidth: "120",
      textAlign: "right",
    },
    {
      field: "Quote Date",
      headerText: "Estimate Date",
      minWidth: "170",
      width: "170",
      maxWidth: "180",
      textAlign: "center",
    },
    {
      field: "Customer",
      headerText: "Customer",
      minWidth: "160",
      width: "380",
      maxWidth: "460",
      textAlign: "left",
    },
    {
      field: "Proj",
      headerText: "Contact",
      minWidth: "170",
      width: "270",
      maxWidth: "390",
      textAlign: "left",
    },
    {
      field: `total`,
      headerText: "Total",
      format: "C2",
      minWidth: "120",
      width: "130",
      maxWidth: "160",
      textAlign: "right",
    },
  ];

  const handleAddEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      const path = `/Estimates/AddEstimation/${storeG.store_id}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      if (so_id !== "") {
        // const so_ids = so_id + "_" + store_id;
        const so_ids = so_id;
        const path = `/Estimates/EditEstimation/${so_ids}`;
        window.open(path, "_blank");
      } else {
        alert("Please Select Estimate!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      if (so_id !== "") {
        if (window.confirm(`Are you sure you want to Delete E#${so_id}?`)) {
          const response = await DeleteSaleOrderApi(so_id, userID, userToken);
          if (response.status === 200) {
            window.location.reload();
            alert("Estimate deleted successfully.");
          } else {
            alert("Estimate failed to delete.");
          }
        }
      } else {
        alert("Please select Estimate!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick1 = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      if (so_id !== "") {
        const so_ids = so_id;
        const path = `/Estimates/ConvertEstimate/${so_ids}`;
        window.open(path, "_blank");
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewSaleClick = async (event) => {
    TimeoutUtility.resetTimeout();
    // event.preventDefault();
    try {
      //console.log("view saleorder");
      if (so_id !== "") {
        navigate(`/Estimates/ViewEstimation/${so_id}`);
      } else {
        alert("Please Select Order!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    const selectedRowData = args.data;
    setso_id(selectedRowData.so_id);
    //console.log(selectedRowData.so_id);
  };

  useEffect(() => {
    async function fetchData() {
      //console.log(store_id);
      if (storeG !== null && storeG.store_id !== 0 && userID && userToken) {
        trackPromise(
          Promise.all([
            GetAllSalesByID(storeG.store_id, "E", userID, userToken),
          ])
            .then(([result]) => {
              setAllAccounts(result.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [storeG, userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Orders" title="ESTIMATES" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {userPermID.includes(5011) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddEmployeesClick}
              />
            </Col>
          )}
          {userPermID.includes(5012) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Update"
                  borderRadius="10px"
                  onClick={handleEditEmployeesClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Delete"
                  borderRadius="10px"
                  onClick={handleDeleteEmployeesClick}
                />
              </Col>
            </>
          )}
          {userPermID.includes(5014) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Convert to Sale"
                borderRadius="10px"
                onClick={handleViewEmployeesClick1}
              />
            </Col>
          )}
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          <GridComponent
            className="custom-grid"
            dataSource={AllAccounts}
            allowPaging={true}
            pageSettings={{ pageSize: 16 }}
            allowSorting
            allowResizing
            toolbar={["Search"]}
            rowSelected={handleRowSelected}
            rowHeight={36}
            recordDoubleClick={userPermID.includes(5010) && handleViewSaleClick}
          >
            <ColumnsDirective>
              {customersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </>
      )}
    </div>
  );
};

export default Estimates;
