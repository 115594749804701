import {
  DB,
  // TXN_WRITE
} from "../contexts/IndexedDB";

class IdbApi {
  async add(item, TBL) {
    try {
      const db = await DB.openDB();
      // const tbl = db.transaction([TBL], TXN_WRITE).objectStore(TBL);
      // const itemKey = await tbl.add(TBL, item);
      // return tbl.get(itemKey);

      const itemKey = await db.add(TBL, item);
      return db.get(TBL, itemKey);
    } catch {}
  }

  async addList(items, TBL) {
    try {
      const db = await DB.openDB();
      // const tbl = db.transaction([TBL], TXN_WRITE).objectStore(TBL);
      // const itemKey = await tbl.add(TBL, item);
      // return tbl.get(itemKey);

      for (let i = 0; i < items.length; i++) {
        // var id = i + 1;
        // if (TBL === "sales") {
        //   id = items[i].so_id;
        // }
        // if (TBL === "storetbl") {
        //   id = items[i].store_id;
        // }
        // if (TBL === "customer") {
        //   id = items[i].customer_id;
        // }
        await db.add(TBL, items[i]);
      }
      return db.getAll(TBL);
    } catch {}
  }

  async getLabels(TBL) {
    try {
      const db = await DB.openDB();
      let cursor = await db
        .transaction(TBL)
        .objectStore(TBL)
        .index("idx_label")
        .openKeyCursor(null, "nextunique");

      const labels = [];
      while (cursor) {
        labels.push(cursor.key);
        cursor = await cursor.continue();
      }

      return labels;
    } catch {}
  }

  async getProductLabels(TBL) {
    try {
      const db = await DB.openDB();
      let cursor = await db
        .transaction(TBL)
        .objectStore(TBL)
        .index("productname")
        .openKeyCursor(null, "nextunique");

      const labels = [];
      while (cursor) {
        labels.push(cursor.key);
        cursor = await cursor.continue();
      }

      return labels;
    } catch {}
  }

  async getAllNow(TBL) {
    try {
      const db = await DB.openDB();
      //   const tbl = db.transaction([TBL]).objectStore(TBL);
      //   if (filter?.label) {
      //     return tbl.index("idx_label").getAll(filter?.label);
      //   }
      return db.getAll(TBL);
    } catch {}
  }

  async getAllFilter(filter, TBL) {
    try {
      const db = await DB.openDB();
      const tbl = db.transaction([TBL]).objectStore(TBL);
      if (filter?.label) {
        return tbl.index("idx_label").getAll(filter?.label);
      }
      return tbl.getAll();
    } catch {}
  }

  async getAllFilterProducts(filter, TBL) {
    try {
      const db = await DB.openDB();
      const tbl = db.transaction([TBL]).objectStore(TBL);
      if (filter?.label) {
        return tbl.index("productname").getAll(filter?.label);
      }
      return tbl.getAll();
    } catch {}
  }

  async get(key, TBL) {
    try {
      const db = await DB.openDB();
      const tbl = db.transaction([TBL]).objectStore(TBL);
      return tbl.get(key);
    } catch {}
  }

  async delete(id, TBL) {
    try {
      const db = await DB.openDB();
      // const tbl = db.transaction([TBL], TXN_WRITE).objectStore(TBL);
      // return tbl.delete(id);

      return db.delete(TBL, id);
    } catch {}
  }

  async deleteAll(TBL) {
    try {
      const db = await DB.openDB();
      //   const tbl = db.transaction([TBL], TXN_WRITE).objectStore(TBL);
      return db.clear(TBL);
    } catch {}
  }

  async update(item, TBL) {
    try {
      const db = await DB.openDB();
      //const tbl = db.transaction([TBL], TXN_WRITE).objectStore(TBL);
      return db.put(item, TBL);
    } catch {}
  }
}

const idbApi = new IdbApi();

export default idbApi;
