import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
//import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
//import hello from "../data/nexusan-logo.png";
import { Col } from "react-bootstrap";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, currentColor, userPermID } =
    useStateContext();
  const [newLinks, setNewLinks] = useState([]);
  const allLinks = links;

  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const includesAny = (arr, values) => values.some((v) => arr.includes(v));
  // const includesAll = (arr, values) => values.every((v) => arr.includes(v));

  useEffect(() => {
    if (allLinks.length > 0 && userPermID.length > 0) {
      //console.log(allLinks);
      // console.log(userPermID);
      var ii = -1;
      var linkss = [];
      if (userPermID.includes(5000)) {
        linkss = [];
        linkss.push(allLinks[0]);
        ii++;
      }
      if (
        // userPermID.includes(5001) ||
        // userPermID.includes(5002) ||
        // userPermID.includes(5003) ||
        // userPermID.includes(5004) ||
        // userPermID.includes(5005) ||
        // userPermID.includes(5006) ||
        // userPermID.includes(5007) ||
        // userPermID.includes(5008) ||
        // userPermID.includes(5009)
        includesAny(
          userPermID,
          [5001, 5002, 5003, 5004, 5005, 5006, 5007, 5008, 5009]
        )
      ) {
        linkss.push(allLinks[1]);
        ii++;
        if (
          // !userPermID.includes(5001) &&
          // !userPermID.includes(5002) &&
          // !userPermID.includes(5003)
          !includesAny(userPermID, [5001, 5002, 5003])
        ) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Stores"
          );
          //console.log(index);
          linkss[ii].links.splice(index, 1);
        }
        if (
          // !userPermID.includes(5004) &&
          // !userPermID.includes(5005) &&
          // !userPermID.includes(5006)
          !includesAny(userPermID, [5004, 5005, 5006])
        ) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Inventory"
          );
          linkss[ii].links.splice(index, 1);
        }
        if (
          // !userPermID.includes(5007) &&
          // !userPermID.includes(5008) &&
          // !userPermID.includes(5009)
          !includesAny(userPermID, [5007, 5008, 5009])
        ) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Product"
          );
          linkss[ii].links.splice(index, 1);
        }
      }
      if (
        includesAny(
          userPermID,
          [5010, 5011, 5012, 5013, 5014, 5015, 5016, 5017, 5018]
        )
      ) {
        linkss.push(allLinks[2]);
        ii++;
        // if (!includesAny(userPermID, [5010, 5011, 5012])) {
        //   const index = linkss[ii].links.findIndex(
        //     (item) => item.url === "Estimates"
        //   );
        //   linkss[ii].links.splice(index, 1);
        // }
        if (!includesAny(userPermID, [5013, 5014, 5015])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Sales"
          );
          linkss[ii].links.splice(index, 1);
        }
        if (!includesAny(userPermID, [5016, 5017, 5018])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Purchase"
          );
          linkss[ii].links.splice(index, 1);
        }
      }
      if (
        includesAny(
          userPermID,
          [
            5019, 5020, 5021, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5029,
            5030,
          ]
        )
      ) {
        linkss.push(allLinks[3]);
        ii++;
        if (!includesAny(userPermID, [5019, 5020, 5021])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Account"
          );
          linkss[ii].links.splice(index, 1);
        }
        if (!includesAny(userPermID, [5022, 5023, 5024])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Customer"
          );
          linkss[ii].links.splice(index, 1);
        }
        if (!includesAny(userPermID, [5025, 5026, 5027])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Supplier"
          );
          linkss[ii].links.splice(index, 1);
        }
        if (!includesAny(userPermID, [5028, 5029, 5030])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Employee"
          );
          linkss[ii].links.splice(index, 1);
        }
      }
      if (
        includesAny(
          userPermID,
          [
            5031, 5032, 5033, 5034, 5035, 5036, 5037, 5038, 5039, 5040, 5041,
            5042,
          ]
        )
      ) {
        linkss.push(allLinks[4]);
        ii++;
        if (!includesAny(userPermID, [5031, 5032, 5033])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Receipt"
          );
          linkss[ii].links.splice(index, 1);
        }
        if (!includesAny(userPermID, [5034, 5035, 5036])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Payment"
          );
          linkss[ii].links.splice(index, 1);
        }
        if (!includesAny(userPermID, [5037, 5038, 5039])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Journal"
          );
          linkss[ii].links.splice(index, 1);
        }
        if (!includesAny(userPermID, [5040, 5041, 5042])) {
          const index = linkss[ii].links.findIndex(
            (item) => item.url === "Expense"
          );
          linkss[ii].links.splice(index, 1);
        }
      }
      if (includesAny(userPermID, [5043, 5044, 5045])) {
        linkss.push(allLinks[5]);
      }
      // console.log(linkss);

      setNewLinks(linkss);
    }
  }, [userPermID, allLinks]);

  const activeLink =
    "flex item-center gap-4 pl-4 pt-2 pb-1 rounded-lg text-white text-md m-1";
  const normalLink =
    "flex item-center gap-4 pl-4 pt-2 pb-1 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-1";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/Overview"
              onClick={handleCloseSidebar}
              style={{ textDecoration: "none" }}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <Col
                // className="d-none d-lg-block"
                sm={true}
                style={{ padding: "0" }}
              >
                {/* <img
                  src={hello}
                  style={{
                    // width: "80%",
                    // height: "80%",
                    // minWidth: "20%",
                    // minHeight: "20%",
                    objectFit: "contain",
                  }}
                /> */}
                <label
                  style={{
                    marginTop: "16%",
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "blue",
                    textAlign: "center",
                  }}
                >
                  CodeNexSys
                </label>
              </Col>
              {/* <Col
                className="d-block d-lg-none"
                sm={true}
                style={{ padding: "0" }}
              >
                <SiShopware />
                <span>Nexusan</span>
              </Col> */}
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() =>
                  setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                }
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="m-8 ml-6">
            {newLinks.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 m-2 mt-4 uppercase">{item.title}</p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.url}`}
                    key={link.name}
                    onClick={handleCloseSidebar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                      textDecoration: "none",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link.icon}
                    <label
                      // className="capitalize"
                      style={{
                        height: "20px",
                        lineHeight: "100%",
                      }}
                    >
                      {link.name}
                    </label>
                  </NavLink>
                ))}
              </div>
            ))}
            {/* <div className="form-group"> */}
            {/* <button
                className="btn btn-success login-btn"
                onClick={handleBackClick}
                type="submit"
              >
                LogOut
              </button> */}

            {/* <Link to="/" className="btn btn-danger">
                        Back
                      </Link> */}
            {/* </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
