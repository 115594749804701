import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetAllCategories,
  DeleteCategoryById,
  AddCategoryApi,
  EditCategoryApi,
  GetCategoryById,
  GetAllParentCategories,
} from "../../api";
import Select from "react-select";
import { Header, Button } from "../../components";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/AddProduct.css";
import { validateName } from "../../contexts/Utils";

const AddEditCategory = () => {
  const navigate = useNavigate();
  const { currentColor, userID, userToken } = useStateContext();
  const [category, setCategory] = useState("");
  const [getcategorys, setCategorys] = useState([]);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [category_id, setcategory_id] = useState("");
  const [categoryParent, setCategoryParent] = useState("");
  const [getcategoryParents, setCategoryParents] = useState([]);
  const [categoryParentOptions, setcategoryParentOptions] = useState([]);
  const [categoryParent_id, setcategoryParent_id] = useState("");
  const [name, setName] = useState("");
  const [ValError, setValError] = useState([]);
  const [mode, setMode] = useState("Add");

  const validName1 = (name, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (name.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (validateName(name)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleAddMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Add");
      setCategory("");
      setcategory_id("");
      setCategoryParent("");
      setcategoryParent_id("");
      setName("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Edit");
      setCategory("");
      setcategory_id("");
      setCategoryParent("");
      setcategoryParent_id("");
      setName("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Delete");
      setCategory("");
      setcategory_id("");
      setCategoryParent("");
      setcategoryParent_id("");
      setName("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddPCategoryClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      navigate("/Product/AddEditParentCategory");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      navigate("/Product/AddProduct", {
        state: { fromPage: "Category" },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();

    try {
      setValError([]);
      const updatedErrors = [...ValError];

      if (
        (mode === "Edit" || mode === "Delete") &&
        (!category_id || category_id === "")
      ) {
        updatedErrors[0] = "Select a Category!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[0] = "";

      if (name === "") {
        updatedErrors[1] = "Please enter name!";
        setValError(updatedErrors);
        return;
      }
      if (name) {
        if (validName1(name, 1) === false) {
          return;
        }
      }
      updatedErrors[1] = "";

      if (!categoryParent_id || categoryParent_id === "") {
        updatedErrors[2] = "Select a Parent Category!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[2] = "";
      setValError(updatedErrors);

      if (mode === "Add") {
        if (window.confirm("Do you want to add Category?")) {
          AddCategoryApi(name, categoryParent_id, userID, userToken)
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditCategory", {
                  state: { fromPage: "Category" },
                });
                alert("Added successfully.");
                window.location.reload();
              } else {
                alert("Fail to add Category.");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      } else if (mode === "Edit") {
        if (window.confirm("Do you want to edit this Category?")) {
          EditCategoryApi(
            category_id,
            name,
            categoryParent_id,
            userID,
            userToken
          )
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditCategory", {
                  state: { fromPage: "Category" },
                });
                alert("Updated successfully.");
                window.location.reload();
              } else {
                alert("Fail to edit Category!");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      } else if (mode === "Delete") {
        if (
          window.confirm(`Do you want to remove ${category.label} Category?`)
        ) {
          DeleteCategoryById(category_id, userID, userToken)
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditCategory", {
                  state: { fromPage: "Category" },
                });
                alert("Removed successfully.");
                window.location.reload();
              } else {
                alert("Fail to remove Category!");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeCategory = (selectedOption) => {
    try {
      if (selectedOption && selectedOption.value) {
        setCategory(selectedOption);
        const selected_id = selectedOption.value;
        setcategory_id(selected_id);

        const resp1 = GetCategoryById(selected_id, userID, userToken);
        resp1
          .then(function (result) {
            setName(result.data[0].name);
            setcategoryParent_id(result.data[0].parent_id || "");
            const categP = categoryParentOptions.find(
              (item) => item.value === result.data[0].parent_id || ""
            );
            setCategoryParent(categP);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        setCategory(selectedOption.label);
        setcategory_id("");
        setCategoryParent("");
        setcategoryParent_id("");
      }
    } catch (err) {}
  };

  const handleChangeParentCategory = (selectedOption) => {
    try {
      if (selectedOption && selectedOption.value) {
        setCategoryParent(selectedOption);
        const selected_id = selectedOption.value;
        setcategoryParent_id(selected_id);
      } else {
        setCategoryParent(selectedOption.label);
        setcategoryParent_id("");
      }
    } catch (err) {}
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken) {
        GetAllCategories(userID, userToken)
          .then((resp) => {
            setCategorys(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        GetAllParentCategories(userID, userToken)
          .then((resp) => {
            setCategoryParents(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    const fetchStoreOptions = async () => {
      const fetchedStoreOptions = getcategorys.map((item) => ({
        label: `${item.name}`,
        value: item.category_id,
      }));
      setcategoryOptions(fetchedStoreOptions);
    };

    fetchStoreOptions();
  }, [getcategorys]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    const fetchStoreOptions = async () => {
      const fetchedStoreOptions = getcategoryParents.map((item) => ({
        label: `${item.name}`,
        value: item.category_p_id,
      }));
      setcategoryParentOptions(fetchedStoreOptions);
    };

    fetchStoreOptions();
  }, [getcategoryParents]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header
        title={
          mode === "Add"
            ? "ADD CATEGORY"
            : mode === "Edit"
            ? "EDIT CATEGORY"
            : "DELETE CATEGORY"
        }
      />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            className="justify-content-center"
            style={{ padding: "0" }}
          >
            <Col md={2} className="container-col" />
            <Col md={4} className="container-col">
              {(mode === "Edit" || mode === "Delete") && (
                <>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="label">Category List:</label>
                      <Select
                        className="myreact-select container-select"
                        value={category}
                        onChange={handleChangeCategory}
                        options={categoryOptions}
                        isSearchable
                        placeholder="Select a Category"
                      />
                      {ValError[0] && (
                        <p className="label-error-select">{ValError[0]}</p>
                      )}
                    </div>
                  </div>
                  <br />
                  <br />
                </>
              )}
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Name:</label>
                  <input
                    type="text"
                    name="category_name"
                    value={name}
                    onChange={handleChangeName}
                    className="input"
                    placeholder="Category Name"
                    onBlur={(e) => validName1(e.target.value, 1)}
                    disabled={mode === "Delete"}
                  />
                  {ValError[1] && <p className="label-error">{ValError[1]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">
                    Parent Category:
                    <span style={{ float: "right", paddingRight: "22%" }}>
                      <button
                        style={{ fontSize: "13px" }}
                        onClick={handleAddPCategoryClick}
                      >
                        ➕
                      </button>
                    </span>
                  </label>
                  <Select
                    required
                    className="myreact-select container-select"
                    value={categoryParent}
                    onChange={handleChangeParentCategory}
                    options={categoryParentOptions}
                    isSearchable
                    isDisabled={mode === "Delete"}
                    placeholder="Select a Parent Category"
                  />
                  {ValError[2] && (
                    <p className="label-error-select">{ValError[2]}</p>
                  )}
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={4} className="container-col">
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddMode}
              />
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Edit"
                borderRadius="10px"
                onClick={handleEditMode}
              />
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Delete"
                borderRadius="10px"
                onClick={handleDeleteMode}
              />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          color="white"
          margin="10px"
          padding="20px"
          bgColor={currentColor}
          text={
            mode === "Add" ? "ADD NEW" : mode === "Edit" ? "UPDATE" : "REMOVE"
          }
          borderRadius="10px"
          onClick={handleAddSubmit}
        />
        <Button
          color="white"
          margin="10px"
          padding="20px"
          bgColor={currentColor}
          text="BACK"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default AddEditCategory;
