import React from "react";
import ReactDOM from "react-dom";
//import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import { loadCldr, setCulture, setCurrencyCode } from "@syncfusion/ej2-base";
// import serviceWorkerDev from "./serviceWorkerDev";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

loadCldr(
  require("cldr-data/main/en-PK/currencies.json"),
  require("cldr-data/main/en-PK/numbers.json")
);
setCulture("en-PK");
setCurrencyCode("PKR");

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
// serviceWorkerDev();
