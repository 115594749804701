import * as idb from "idb";

export const SALES_TBL = "sales";
export const PURCHASE_TBL = "purchase";
export const STORE_TBL = "storetbl";
export const CUSTOMER_TBL = "customer";
export const PRODUCT_TBL = "product";
export const ACCOUNTREC_TBL = "accountRec";
export const INVENTORY_TBL = "inventory";
export const PENDSALES_TBL = "pending_sales";
export const DB_NAME = "cns_db";
export const DB_VERSION = 2;

export const TXN_WRITE = "readwrite";

class DB {
  static openDB() {
    return idb.openDB(DB_NAME, DB_VERSION, {
      upgrade(
        db
        // oldVersion, newVersion
      ) {
        //console.log(db);
        try {
          // if (newVersion !== oldVersion) {
          //   db.deleteObjectStore(SALES_TBL);
          //   db.deleteObjectStore(PURCHASE_TBL);
          //   db.deleteObjectStore(INVENTORY_TBL);
          //   db.deleteObjectStore(CUSTOMER_TBL);
          //   db.deleteObjectStore(PRODUCT_TBL);
          //   db.deleteObjectStore(ACCOUNTREC_TBL);
          //   db.deleteObjectStore(STORE_TBL);
          // }

          const salesTbl = db.createObjectStore(SALES_TBL, {
            keyPath: "id",
            autoIncrement: true,
          });
          // store.createIndex('idx_label', 'label', {unique:false})
          salesTbl.transaction.oncomplete = () => {
            console.log(`Table ${SALES_TBL} has been created`);
          };

          const purchaseTbl = db.createObjectStore(PURCHASE_TBL, {
            keyPath: "id",
            autoIncrement: true,
          });
          purchaseTbl.transaction.oncomplete = () => {
            console.log(`Table ${PURCHASE_TBL} has been created`);
          };

          const inventoryTbl = db.createObjectStore(INVENTORY_TBL, {
            keyPath: "id",
            autoIncrement: true,
          });
          inventoryTbl.transaction.oncomplete = () => {
            console.log(`Table ${INVENTORY_TBL} has been created`);
          };

          const customerTbl = db.createObjectStore(CUSTOMER_TBL, {
            keyPath: "id",
            autoIncrement: true,
          });
          customerTbl.transaction.oncomplete = () => {
            console.log(`Table ${CUSTOMER_TBL} has been created`);
          };

          const productTbl = db.createObjectStore(PRODUCT_TBL, {
            keyPath: "id",
            autoIncrement: true,
          });
          productTbl.createIndex("product_id", "product_id", { unique: false });
          productTbl.createIndex("productname", "productname", {
            unique: false,
          });
          productTbl.transaction.oncomplete = () => {
            console.log(`Table ${PRODUCT_TBL} has been created`);
          };

          const accountRecTbl = db.createObjectStore(ACCOUNTREC_TBL, {
            keyPath: "id",
            autoIncrement: true,
          });
          accountRecTbl.transaction.oncomplete = () => {
            console.log(`Table ${ACCOUNTREC_TBL} has been created`);
          };

          const storeTbl = db.createObjectStore(STORE_TBL, {
            keyPath: "id",
            autoIncrement: true,
          });
          storeTbl.transaction.oncomplete = () => {
            console.log(`Table ${STORE_TBL} has been created`);
          };

          const pendSalesTbl = db.createObjectStore(PENDSALES_TBL, {
            keyPath: "id",
            autoIncrement: true,
          });
          pendSalesTbl.transaction.oncomplete = () => {
            console.log(`Table ${PENDSALES_TBL} has been created`);
          };
        } catch (e) {
          console.log(e);
        }
      },
    });
  }
}

export { DB };
