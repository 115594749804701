import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { validateName, ValidText } from "../../contexts/Utils";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GetAllAccountTypes,
  GetAllAccountCategories,
  AddAccountOpeningBal,
  CheckAccNameExist,
} from "../../api";
import { Header, Button } from "../../components";
//import axios from "axios";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";

const AddAccount = () => {
  const { currentColor, userID, userToken } = useStateContext();
  let param = useParams();
  const store_id = param.store_id;
  const [acc_notes, setAccNotes] = useState("");
  const [AccountType, setAccountType] = useState("select");
  const [AccountCat, setAccountCat] = useState("select");
  const [getacc_types, setacc_types] = useState([]);
  const [getacc_cats, setacc_cats] = useState([]);
  const [getfacc_cats, setfacc_cats] = useState([]);
  //const [aexist, setaexist] = useState("");
  const [checkDisabled, setcheckDisabled] = useState(true);
  const [name, setname] = useState("");
  const [ValError, setValError] = useState([]);
  const [loadBtn, setLoadBtn] = useState(false);

  const handleChangeAccNote = (e) => {
    setAccNotes(e.target.value);
  };

  const navigate = useNavigate();

  const handleBackClick = async (event) => {
    event.preventDefault();
    try {
      //console.log("Back");
      navigate("/Account");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeAccountType = (e) => {
    setAccountType(e.target.value);
    const filtaccCatg = getacc_cats.filter(
      (cat) => cat.type_name === e.target.value
    );
    setfacc_cats(filtaccCatg);
    if (e.target.value !== "select") {
      setcheckDisabled(false);
      const updatedErrors = [...ValError];
      updatedErrors[1] = "";
      setValError(updatedErrors);
    } else {
      setcheckDisabled(true);
    }
  };

  const handleChangeAccountCat = (e) => {
    setAccountCat(e.target.value);

    if (e.target.value !== "select") {
      const updatedErrors = [...ValError];
      updatedErrors[5] = "";
      setValError(updatedErrors);
    }
  };
  const validName1 = (name, ii) => {
    const updatedErrors = [...ValError];
    if (name.trim().length === 0) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return false;
    }
    if (validateName(name)) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return true;
    }
    updatedErrors[ii] = "Invalid field!";
    setValError(updatedErrors);
    return false;
  };

  const ValidText1 = (txt, ii) => {
    const updatedErrors = [...ValError];
    if (txt.trim().length === 0) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return false;
    }
    if (ValidText(txt)) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return true;
    }
    updatedErrors[ii] = "Invalid field!";
    setValError(updatedErrors);
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    TimeoutUtility.resetTimeout();
    setLoadBtn(true);
    var resp = 0;
    try {
      const { acc_description, opening_balance } = document.forms[0];

      setValError([]);
      const updatedErrors = [...ValError];

      if (AccountType === "select" || AccountType === "Select Type") {
        updatedErrors[1] = "Please select Account Type!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[1] = "";

      // if (AccountCat === "select" || AccountType === "Select Category") {
      //   updatedErrors[5] = "Please select Account Category!";
      //   setValError(updatedErrors);
      //   return;
      // }
      // updatedErrors[5] = "";

      if (name === "") {
        updatedErrors[0] = "Please enter Account Title!";
        setValError(updatedErrors);
        return;
      }
      if (name !== "") {
        if (validName1(name, 0) === false) {
          return;
        }
      }
      var exi = 0;
      await CheckAccNameExist(name, userID, userToken)
        .then((resp) => {
          //console.log(resp.data);
          exi = resp.data[0].name;
        })
        .catch((err) => {
          console.log(err.message);
        });

      if (exi === 1) {
        updatedErrors[0] = "Account Title must be unique!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[0] = "";

      if (acc_description.value !== "") {
        if (ValidText1(acc_description.value, 2) === false) {
          return;
        }
      }
      updatedErrors[2] = "";

      if (opening_balance.value === "") {
        updatedErrors[3] = "Please enter Opening Balance!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[3] = "";

      if (acc_notes !== "") {
        if (ValidText1(acc_notes, 4) === false) {
          return;
        }
      }
      updatedErrors[4] = "";

      const accounttype_id = getacc_types.find(
        (item) => item.name === AccountType
      );

      var accountcat_id = null;
      if (AccountCat !== "select" && AccountType !== "Select Category") {
        var acccats = getacc_cats.find((item) => item.name === AccountCat);
        accountcat_id = acccats.category_id;
      }
      const now = new Date();
      const offsetMs = now.getTimezoneOffset() * 60 * 1000;
      const dateLocal = new Date(now.getTime() - offsetMs);
      const str = dateLocal
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, "/")
        .replace("T", " ");
      const lnotes = "Opening Balance Set";
      const response = await AddAccountOpeningBal(
        name,
        acc_description.value,
        accounttype_id.a_type_id,
        accountcat_id,
        null,
        acc_notes,
        lnotes,
        opening_balance.value,
        str,
        900,
        store_id,
        userID,
        userToken
      );

      // console.log(response, "Response");
      resp = response.status;
      if (resp === 200) {
        alert("Account added successfully.");
      } else {
        alert("Account failed to add!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadBtn(false);
      if (resp === 200) {
        navigate("/Account");
      }
    }
  };

  const handleChangeName = (e) => {
    setname(e.target.value);
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     await CheckAccNameExist(name, userID, userToken)
  //       .then((resp) => {
  //         console.log(resp.data);
  //         setaexist(resp.data[0].name);
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }
  //   fetchData();
  // }, [name]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken) {
        GetAllAccountTypes(userID, userToken)
          .then((resp) => {
            setacc_types(resp.data || []);
            //console.log(resp.data);
          })
          .catch((err) => {
            console.log(err.message);
          });

        GetAllAccountCategories(userID, userToken)
          .then((resp) => {
            setacc_cats(resp.data || []);
            setfacc_cats([]);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="ADD ACCOUNT" />
      <form>
        {/* <div
              style={{
                textAlign: "left",
                backgroundColor: "Transparent",
                color: "black",
              }}
            >
              <div className="article-container-cus">
                <div className="flex justify-left"> */}
        {/* <div className="article-container"> */}
        {/* <div className="article-employee2"> */}
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row xs={1} sm={1} style={{ padding: "0" }}>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="AccountSelect">
                    Type:
                  </label>
                  <select
                    autoFocus
                    className="select container-select"
                    id="AccountSelect"
                    value={AccountType}
                    onChange={handleChangeAccountType}
                  >
                    <option defaultValue="select">Select Type</option>
                    {getacc_types.map((item) => (
                      <option key={item.a_type_id}>{item.name}</option>
                    ))}
                  </select>
                  <span className="label-imp">*</span>
                  {ValError[1] && <p className="label-error">{ValError[1]}</p>}
                </div>
              </div>
              <br />

              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="AccountCSelect">
                    Category:
                  </label>
                  <select
                    className="select container-select"
                    id="AccountCSelect"
                    value={AccountCat}
                    onChange={handleChangeAccountCat}
                    disabled={checkDisabled}
                  >
                    <option defaultValue="select">Select Category</option>
                    {getfacc_cats.map((item) => (
                      <option key={item.category_id}>{item.name}</option>
                    ))}
                  </select>
                  {/* <span className="label-imp">*</span> */}
                  {ValError[5] && <p className="label-error">{ValError[5]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Account Title:</label>
                  <input
                    required
                    disabled={checkDisabled}
                    type="text"
                    name="acc_name"
                    placeholder="Title"
                    value={name}
                    onChange={handleChangeName}
                    className="input"
                    //autoFocus
                    onBlur={(e) => validName1(e.target.value, 0)}
                  />
                  <span className="label-imp">*</span>
                  {ValError[0] && <p className="label-error">{ValError[0]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Opening Balance:</label>
                  <input
                    type="number"
                    disabled={checkDisabled}
                    step="1.00"
                    min="0"
                    defaultValue={0}
                    name="opening_balance"
                    placeholder="Opening Balance"
                    className="input"
                  />
                  <span className="label-imp">*</span>
                  {ValError[3] && <p className="label-error">{ValError[3]}</p>}
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Description:</label>
                  <input
                    type="text"
                    disabled={checkDisabled}
                    name="acc_description"
                    placeholder="Description"
                    className="input"
                    onBlur={(e) => ValidText1(e.target.value, 2)}
                  />
                  {ValError[2] && <p className="label-error">{ValError[2]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Note: </label>
                  <textarea
                    placeholder="Account Note"
                    disabled={checkDisabled}
                    id="noteTextarea"
                    className="textarea"
                    value={acc_notes}
                    onChange={handleChangeAccNote}
                    onBlur={(e) => ValidText1(e.target.value, 4)}
                  />
                  {ValError[4] && <p className="label-error">{ValError[4]}</p>}
                </div>
              </div>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button ml-2"
          bgColor={currentColor}
          text="Add"
          borderRadius="10px"
          onClick={handleSubmit}
          disabled={loadBtn}
        />
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button ml-2"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default AddAccount;
