import React, { useEffect, useState } from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  StackingColumnSeries,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
//import { GetSaleRevByMonth, GetPurchCostByMonth } from "../../api";
// import { useStateContext } from '../../contexts/ContextProvider';

const Stacked = ({ width, height, data, headers }) => {
  const [stackedChartData, setStackedChartData] = useState([]);

  useEffect(() => {
    //console.log(data);
    //console.log(headers);
    setStackedChartData(data || []);
  }, [data]);

  // const stackedChartData = [
  //   [
  //     { x: "Jan", y: 111.1 },
  //     { x: "Feb", y: 127.3 },
  //     { x: "Mar", y: 143.4 },
  //     { x: "Apr", y: 159.9 },
  //     { x: "May", y: 159.9 },
  //     { x: "Jun", y: 159.9 },
  //     { x: "July", y: 159.9 },
  //   ],
  //   // [
  //   //   { x: "Jan", y: 111.1 },
  //   //   { x: "Feb", y: 127.3 },
  //   //   { x: "Mar", y: 143.4 },
  //   //   { x: "Apr", y: 159.9 },
  //   //   { x: "May", y: 159.9 },
  //   //   { x: "Jun", y: 159.9 },
  //   //   { x: "July", y: 159.9 },
  //   // ],
  // ];

  const stackedPrimaryXAxis = {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    lineStyle: { width: 0 },
    labelIntersectAction: "Rotate45",
    valueType: "Category",
  };

  const stackedPrimaryYAxis = {
    lineStyle: { width: 0 },
    //minimum: 0,
    //maximum: 100000,
    //interval: 1000,
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: "{value}",
  };

  const stackedCustomSeries = [
    {
      dataSource: stackedChartData[0],
      xName: "x",
      yName: "y",
      name: headers[0],
      type: "StackingColumn",
      background: "blue",
    },
    {
      dataSource: stackedChartData[1],
      xName: "x",
      yName: "y",
      name: headers[1],
      type: "StackingColumn",
      background: "red",
    },
    stackedChartData.length > 2 && {
      dataSource: stackedChartData[2],
      xName: "x",
      yName: "y",
      name: headers[2],
      type: "StackingColumn",
      background: "green",
    },
    stackedChartData.length > 3 && {
      dataSource: stackedChartData[3],
      xName: "x",
      yName: "y",
      name: headers[3],
      type: "StackingColumn",
      background: "yellow",
    },
  ];
  return (
    <ChartComponent
      width={width}
      height={height}
      id="charts"
      primaryXAxis={stackedPrimaryXAxis}
      primaryYAxis={stackedPrimaryYAxis}
      chartArea={{ border: { width: 0 } }}
      tooltip={{ enable: true }}
      LegendSettings={{ background: "white" }}
    >
      <Inject services={[Legend, Category, StackingColumnSeries, Tooltip]} />
      <SeriesCollectionDirective>
        {stackedCustomSeries.map((item, index) => (
          <SeriesDirective key={index} {...item} />
        ))}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default Stacked;
