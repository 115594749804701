import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetAllBrands,
  DeleteBrandById,
  AddBrandApi,
  EditBrandApi,
  GetBrandById,
} from "../../api";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import Select from "react-select";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import default_prod2 from "../../data/default_prod2.png";
import "../../styles/AddProduct.css";
import imageCompression from "browser-image-compression";
import { validateName, ValidText } from "../../contexts/Utils";

const AddEditBrand = () => {
  const navigate = useNavigate();
  const { currentColor, userID, userToken } = useStateContext();
  const [brand, setBrand] = useState("");
  const [getbrands, setBrands] = useState([]);
  const [brandOptions, setbrandOptions] = useState([]);
  const [brand_id, setbrand_id] = useState("");
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [ValError, setValError] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [ProductImage, setProductImage] = useState("");
  const [ProductImage1, setProductImage1] = useState(null);
  const fileInputRef = useRef(null);
  const [profile1] = useState(
    //dataURLtoFile(default_prod2, "default_prod2.jpg")
    null
  );
  // const [profile, setprofile] = useState(
  //   //dataURLtoFile(default_img, "default_img.jpg")
  //   null
  // );
  const [mode, setMode] = useState("Add");

  const ValidText1 = (txt, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (txt.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (ValidText(txt)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const validName1 = (name, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (name.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (validateName(name)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };

  const handleAddMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Add");
      setBrand("");
      setbrand_id("");
      setName("");
      setNote("");
      setProductImage("");
      setProductImage1(null);
      // setprofile(null);
      setUploadedImage(null);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Edit");
      setBrand("");
      setbrand_id("");
      setName("");
      setNote("");
      setProductImage("");
      setProductImage1(null);
      // setprofile(null);
      setUploadedImage(null);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Delete");
      setBrand("");
      setbrand_id("");
      setName("");
      setNote("");
      setProductImage("");
      setProductImage1(null);
      // setprofile(null);
      setUploadedImage(null);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      // console.log("Back");
      navigate("/Product/AddProduct", {
        state: { fromPage: "Brand" },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();

    try {
      setValError([]);
      const updatedErrors = [...ValError];

      if (
        (mode === "Edit" || mode === "Delete") &&
        (!brand_id || brand_id === "")
      ) {
        updatedErrors[0] = "Select a Brand!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[0] = "";

      if (name === "") {
        updatedErrors[1] = "Please enter name!";
        setValError(updatedErrors);
        return;
      }
      if (name) {
        if (validName1(name, 1) === false) {
          return;
        }
      }
      updatedErrors[1] = "";
      if (note !== "") {
        if (ValidText1(note, 2) === false) {
          return;
        }
      }
      updatedErrors[2] = "";
      setValError(updatedErrors);

      var z = null;
      if (ProductImage === null || ProductImage === "") {
        z = profile1;
      } else {
        if (ProductImage1) {
          const base64Image = ProductImage;
          const byteCharacters = atob(base64Image);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          z = blob;
        } else {
          const options = {
            maxSizeMB: 0.5, // Maximum file size in MB
            maxWidthOrHeight: 800, // Maximum width or height of the image
            useWebWorker: true, // Use web worker for faster compression
          };
          z = await imageCompression(ProductImage, options);
        }
      }

      if (mode === "Add") {
        if (window.confirm("Do you want to add brand?")) {
          AddBrandApi(name, note, z, userID, userToken)
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditBrand", {
                  state: { fromPage: "Brand" },
                });
                alert("Added successfully.");
                window.location.reload();
              } else {
                alert("Fail to add brand.");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      } else if (mode === "Edit") {
        if (window.confirm("Do you want to edit this brand?")) {
          EditBrandApi(brand_id, name, note, z, userID, userToken)
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditBrand", {
                  state: { fromPage: "Brand" },
                });
                alert("Updated successfully.");
                window.location.reload();
              } else {
                alert("Fail to edit brand!");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      } else if (mode === "Delete") {
        if (window.confirm(`Do you want to remove ${brand.label} Brand?`)) {
          DeleteBrandById(brand_id, userID, userToken)
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditBrand", {
                  state: { fromPage: "Brand" },
                });
                alert("Removed successfully.");
                window.location.reload();
              } else {
                alert("Fail to remove brand!");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleImageChange = (e) => {
    try {
      setProductImage1(null);
      //setFlag(1);
      const file = e.target.files[0];
      if (!file) {
        //setProductImage(default_prod2);
        setProductImage(null);
        // setprofile(null);
        setUploadedImage(null);
      } else {
        if (file.size > 4194304) {
          alert("Image File max size is 4 MB!");
          setProductImage(null);
          setUploadedImage(null);
          // setprofile(null);
          e.target.value = null;
          return;
        } else {
          // setprofile(file);
          setProductImage(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setUploadedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    } catch (err) {
      return false;
    }
  };

  const handleClickRemoveImage = () => {
    setProductImage(null);
    setProductImage1(null);
    setUploadedImage(null);
    //setProdImage(dataURLtoFile(default_prod2, "default_prod2.jpg"));
    fileInputRef.current.value = "";
  };

  const handleChangeBrand = (selectedOption) => {
    try {
      if (selectedOption && selectedOption.value) {
        setBrand(selectedOption);
        const selected_id = selectedOption.value;
        setbrand_id(selected_id);

        const resp1 = GetBrandById(selected_id, userID, userToken);
        resp1
          .then(function (result) {
            setName(result.data[0].name);
            setNote(result.data[0].details || "");
            setProductImage(result.data[0].profile);
            setProductImage1(result.data[0].profile);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        setbrand_id("");
        setBrand(selectedOption.label);
      }
    } catch (err) {}
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken) {
        GetAllBrands(userID, userToken)
          .then((resp) => {
            setBrands(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    const fetchStoreOptions = async () => {
      const fetchedStoreOptions = getbrands.map((item) => ({
        label: `${item.name}`,
        value: item.brand_id,
      }));
      setbrandOptions(fetchedStoreOptions);
    };

    fetchStoreOptions();
  }, [getbrands]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header
        title={
          mode === "Add"
            ? "ADD BRAND"
            : mode === "Edit"
            ? "EDIT BRAND"
            : "DELETE BRAND"
        }
      />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            className="justify-content-center"
            style={{ padding: "0" }}
          >
            <Col md={2} className="container-col" />
            <Col md={4} className="container-col">
              {(mode === "Edit" || mode === "Delete") && (
                <>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="label">Brand List:</label>
                      <Select
                        className="myreact-select container-select"
                        value={brand}
                        onChange={handleChangeBrand}
                        options={brandOptions}
                        isSearchable
                        placeholder="Select a Brand"
                      />
                      {ValError[0] && (
                        <p className="label-error-select">{ValError[0]}</p>
                      )}
                    </div>
                  </div>
                  <br />
                  <br />
                </>
              )}
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Name:</label>
                  <input
                    type="text"
                    name="brand_name"
                    value={name}
                    onChange={handleChangeName}
                    className="input"
                    placeholder="Brand Name"
                    onBlur={(e) => validName1(e.target.value, 1)}
                    disabled={mode === "Delete"}
                  />
                  {ValError[1] && <p className="label-error">{ValError[1]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Details:</label>
                  <textarea
                    name="detail"
                    placeholder="Brand Details"
                    value={note}
                    onChange={handleChangeNote}
                    className="textarea"
                    onBlur={(e) => ValidText1(e.target.value, 2)}
                    disabled={mode === "Delete"}
                  />
                  {ValError[2] && <p className="label-error">{ValError[2]}</p>}
                </div>
              </div>
              <br />
              <div className="mt-0 col-lg-12">
                <div className="form-group">
                  <label className="label">Profile:</label>
                  {ProductImage1 ? (
                    <div className="container-video-div">
                      <img
                        src={`data:image/jpeg;base64,${ProductImage1}`}
                        className="container-image"
                        alt="Customer"
                      />
                      <div className="crs-btn pl-2">
                        <button type="button" onClick={handleClickRemoveImage}>
                          ❌
                        </button>
                      </div>
                    </div>
                  ) : uploadedImage ? (
                    <div className="container-video-div">
                      <img
                        src={uploadedImage}
                        className="container-image"
                        alt="Customer"
                      />
                      <div className="crs-btn pl-2">
                        <button type="button" onClick={handleClickRemoveImage}>
                          ❌
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="container-video-div"
                      style={{ width: "80%" }}
                    >
                      <img
                        src={default_prod2}
                        className="container-image"
                        alt="Customer"
                      />
                    </div>
                  )}
                  <div className="mt-1 product-image-input">
                    <input
                      ref={fileInputRef}
                      className="flex justify-left"
                      type="file"
                      id="imageInput"
                      accept="image/*"
                      onChange={handleImageChange}
                      disabled={mode === "Delete"}
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={4} className="container-col">
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddMode}
              />
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Edit"
                borderRadius="10px"
                onClick={handleEditMode}
              />
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Delete"
                borderRadius="10px"
                onClick={handleDeleteMode}
              />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          color="white"
          margin="10px"
          padding="20px"
          bgColor={currentColor}
          text={
            mode === "Add" ? "ADD NEW" : mode === "Edit" ? "UPDATE" : "REMOVE"
          }
          borderRadius="10px"
          onClick={handleAddSubmit}
        />
        <Button
          color="white"
          margin="10px"
          padding="20px"
          bgColor={currentColor}
          text="BACK"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default AddEditBrand;
