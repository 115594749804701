import React, { useEffect, useState } from "react";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { useNavigate } from "react-router-dom";
import hello from "../../data/default_img.jpg";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  GetAllCustomersByStore,
  CheckCusDeleteStatus,
  DeleteCustomer,
} from "../../api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const Customers = () => {
  const [AllCustomers, setAllCustomers] = useState("");
  const [Customer_id, setCustomer_id] = useState("");
  const [Customer, setCustomer] = useState("");
  const { currentColor, storeG, userPermID, userID, userToken } =
    useStateContext();
  const [acc_Status, setacc_Status] = useState(1);
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const customerGridImage = (props) => (
    <div className="image flex gap-4">
      {props.profile === null ? (
        <div>
          <img
            className="rounded-xl w-16 h-16"
            src={hello}
            alt="customer"
            width={72}
          />
        </div>
      ) : (
        <img
          className="rounded-xl h-16"
          src={`data:image/jpeg;base64,${props.profile}`}
          alt="customer"
          width={72}
        />
      )}
    </div>
  );

  const customersGrid = [
    // { headerTemplate: ` `, type: "checkbox", width: "50" },
    {
      headerText: "",
      minWidth: "100",
      width: "100",
      maxWidth: "120",
      template: customerGridImage,
      textAlign: "left",
    },
    {
      field: "name",
      headerText: "Name",
      minWidth: "180",
      width: "360",
      maxWidth: "640",
      textAlign: "left",
    },
    {
      field: "phones",
      headerText: "Phone",
      minWidth: "140",
      width: "180",
      maxWidth: "200",
      textAlign: "Center",
    },
    {
      field: "email",
      headerText: "Email",
      minWidth: "140",
      width: "220",
      maxWidth: "240",
      textAlign: "left",
    },
    {
      field: "balance",
      headerText: "Balance",
      minWidth: "125",
      width: "130",
      maxWidth: "140",
      format: "C2",
      textAlign: "right",
    },
    // {
    //   field: "city",
    //   headerText: "City",
    //   minWidth: "120",
    //   width: "130",
    //   maxWidth: "180",
    //   textAlign: "left",
    // },
    {
      field: "contact_name",
      headerText: "Contact Name",
      minWidth: "180",
      width: "320",
      maxWidth: "420",
      textAlign: "left",
    },
    // {
    //   field: "opening_balance",
    //   headerText: "Open Bal",
    //   minWidth: "140",
    //   width: "140",
    //   maxWidth: "160",
    //   format: "C2",
    //   textAlign: "right",
    // },
  ];

  const handleAddCustomerClick = async (event) => {
    event.preventDefault();
    try {
      //console.log("Add new");
      navigate(`/Customer/AddCustomer/${storeG.store_id}`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditCustomerClick = async (event) => {
    event.preventDefault();
    try {
      //console.log("edit new");
      //console.log(Customer_id);
      if (Customer_id === 9999999) {
        alert("This Walk-in Customer can not be edited!");
      } else if (Customer_id !== "") {
        navigate(`/Customer/EditCustomer/${Customer_id}`);
      } else {
        alert("Please select customer to edit.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick2 = async (event) => {
    event.preventDefault();
    try {
      // console.log("edit new");
      if (Customer_id !== "") {
        navigate(`/Customer/ViewSale/${Customer_id}`);
      } else {
        alert("Please select customer to view sale.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewCustomerClick = async (event) => {
    try {
      //console.log("view store");
      if (Customer_id !== "") {
        navigate(`/Customer/ViewCustomer/${Customer_id}`);
      } else {
        alert("Please select customer to view.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick1 = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("delete customer");
      if (Customer_id === 9999999) {
        alert("This Walk-in Customer can not be deleted!");
      } else if (Customer_id !== "" && userID && userToken) {
        // if (defaultacc === 0) {
        if (acc_Status === 0) {
          if (window.confirm(`Are you sure you want to Delete ${Customer}?`)) {
            const response = await DeleteCustomer(
              Customer_id,
              userID,
              userToken
            );
            //console.log(response, "Response");
            if (response.status === 200) {
              window.location.reload();
              alert("Customer deleted successfully.");
            } else {
              alert("Customer failed to delete.");
            }
          }
          //console.log(acc_Status);
        } else {
          alert(
            `${Customer} ledger transactions exist.\nIt could not be deleted.`
          );
        }
        // } else {
        //   alert(`A${Account_id} is Defualt account.\nIt could not be deleted.`);
        // }
      } else {
        alert("Please select customer to delete.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRowSelected = (args) => {
    const selectedRowData = args.data;
    const resp1 = CheckCusDeleteStatus(
      selectedRowData.customer_id,
      userID,
      userToken
    );
    resp1
      .then(function (result) {
        //console.log(result.data[0].status);
        setacc_Status(result.data[0].status);
      })
      .catch((err) => {
        console.log(err.message);
      });
    setCustomer_id(selectedRowData.customer_id);
    setCustomer(selectedRowData.name);
    //console.log(selectedRowData.customer_id);
  };

  useEffect(() => {
    async function fetchData() {
      //console.log(store_id);
      if (storeG !== null && storeG.store_id !== 0 && userID && userToken) {
        trackPromise(
          Promise.all([
            GetAllCustomersByStore(storeG.store_id, userID, userToken),
          ])
            .then(([result]) => {
              setAllCustomers(result.data || []);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
      }
    }
    fetchData();
  }, [storeG, userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Accounts" title="CUSTOMERS" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {userPermID.includes(5023) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddCustomerClick}
              />
            </Col>
          )}
          {userPermID.includes(5024) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Update"
                  borderRadius="10px"
                  onClick={handleEditCustomerClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Delete"
                  borderRadius="10px"
                  onClick={handleViewEmployeesClick1}
                />
              </Col>
            </>
          )}
          {userPermID.includes(5022) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="View Sales"
                borderRadius="10px"
                onClick={handleViewEmployeesClick2}
              />
            </Col>
          )}
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          <GridComponent
            className="custom-grid"
            recordDoubleClick={
              userPermID.includes(5022) && handleViewCustomerClick
            }
            dataSource={AllCustomers}
            allowPaging={true}
            pageSettings={{ pageSize: 8 }}
            allowSorting
            allowResizing
            toolbar={["Search"]}
            rowSelected={handleRowSelected}
            rowHeight={72}
          >
            <ColumnsDirective>
              {customersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </>
      )}
    </div>
  );
};

export default Customers;
