import React from "react";
// import { ordersData, contextMenuItems, ordersGrid } from '../data/dummy';
import { Header } from "../components";
import error500 from "../data/error500.png";

const NotAvailable = () => {
  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <div className="mt-4">
        <Header category=" " title="Service Unavailable!" />
      </div>
      <img
        className="w-100"
        src={error500}
        alt="Email Icon"
        width="30px"
        style={{ height: "60vh", objectFit: "contain" }}
      ></img>
    </div>
  );
};

export default NotAvailable;
