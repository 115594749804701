import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  //Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  // GetAllSalesByID,
  //
  GetShipmentSaleOrderByID,
  GetShipmentProductsBySO_ID,
  EditShipment,
  //GetSaleOrderDetailsByID,
  EditSpecialOrder,
  GetSaleOrderDetailShipmentByID,
} from "../../api";
// import Select from "react-select";
import { Header } from "../../components";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";
// import NumberFormat from 'react-number-format/NumberFormat';
import { useStateContext } from "../../contexts/ContextProvider";

const Shipment = () => {
  let param = useParams();
  const [AllAccounts, setAllAccounts] = useState("");
  const [AllAccounts1, setAllAccounts1] = useState("");
  const [so_id, setso_id] = useState("");
  const { currentColor, userID, userToken } = useStateContext();
  const navigate = useNavigate();
  //const [store, setstore] = useState("");
  //const [getstores, setstores] = useState([]);
  const [store_id, setstore_id] = useState("");
  const [acc_notes, setAccNotes] = useState("");
  const [qty_shipped, setqty_shipped] = useState(0);

  const [so_date, setso_date] = useState("");
  const [row_id, setrow_id] = useState("");
  const [invoice, setinvoice] = useState("");
  const [customer, setcustomer] = useState("");
  const [last_qty_edit, setlast_qty_edit] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  //const [sstore_id, setsstore_id] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);
  const [prod_code, setprod_code] = useState("");
  const [ShipAddress, setShipAddress] = useState("");
  const [CardList, setcartList] = useState([]);
  const [sDatetime, setSDatetime] = useState("");
  const [sMinDatetime, setSMinDatetime] = useState("");
  const [sMaxDatetime, setSMaxDatetime] = useState("");
  const [dTNow, setDTNow] = useState(1);
  //const [qtyShipped, setqtyShipped] = useState([]);
  //const [ppo_id, setppo_id] = useState(0);
  // const [formData, setFormData] = useState({
  //   ship_date: new Date().toISOString().split("T")[0],
  // });
  const so_id_param = param.so_ids;

  const [formData1, setFormData1] = useState({
    so_id: 0,
    store_id: store_id,
    invoice_id: 0,
    shipments: [],
  });

  const handleChangeDTNow = () => {
    var now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    setSDatetime(now.toISOString().slice(0, 19));

    if (dTNow === 1) {
      setSMaxDatetime(now.toISOString().slice(0, 19));
      // now = new Date(now.getFullYear(), 0, 2);
      // setSMinDatetime(now.toISOString().slice(0, 19));
    }
    setDTNow((prev) => (prev === 0 ? 1 : 0));
  };

  const handleChangeSDateTime = (e) => {
    if (e === "undefined") {
      setSDatetime(null);
    } else {
      if (new Date(e) > new Date(sMinDatetime)) {
        setSDatetime(e);
      }
    }
  };

  const handleChangeAccNote = (e) => {
    setAccNotes(e.target.value);
  };
  const handleChangeShipAddress = (e) => {
    setShipAddress(e.target.value);
  };

  const handleChangeqty_shipped = (e) => {
    setqty_shipped(e.target.value);
  };

  const customersGrid = [
    {
      field: "item_no",
      headerText: "#",
      width: "70",
      textAlign: "right",
    },
    {
      field: "code",
      headerText: "Product No.",
      width: "135",
      textAlign: "Center",
    },
    {
      headerText: "Product",
      field: "product",
      width: "240",
      textAlign: "left",
    },
    {
      field: "item_note",
      headerText: "Notes",
      width: "240",
      textAlign: "left",
    },

    {
      field: "total_qty",
      headerText: "Qty",
      width: "80",
      textAlign: "right",
    },

    {
      field: "quantity_shipped",
      headerText: "Qty Ship to Date",
      width: "150",
      textAlign: "right",
    },

    {
      field: "last_qty",
      headerText: "Last Qty Ship'd",
      width: "150",
      textAlign: "right",
    },
  ];

  const handleAddEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      setIsEditMode(false);
      setso_id("");
      setIsUpdateButtonDisabled(true);
      setForceUpdate(false);
      setprod_code(row_id);
      setrow_id(null);
      // console.log("Back");
      // navigate("/sales");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBackClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      // console.log("Back");
      navigate("/Sales");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateItemClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      if (!sDatetime || sDatetime === "") {
        alert("Please select Shipping DateTime!");
        return;
      }
      //console.log(parseFloat(qty_shipped));
      if (parseFloat(qty_shipped) > 0) {
        const updatedItem = [...AllAccounts];
        const updatedItem1 = [...AllAccounts1];
        //console.log(last_qty_edit);

        var number = parseFloat(updatedItem[prod_code].quantity_shipped);
        var qty = number + parseFloat(qty_shipped);
        const last_qty_edits = [...last_qty_edit];
        var s = qty - parseFloat(last_qty_edits[prod_code].quantity_shipped);
        var s1 = qty - parseFloat(updatedItem[prod_code].quantity_shipped);
        var Sdt = null;
        if (dTNow) {
          var d = new Date();
          Sdt =
            [
              d.getFullYear(),
              (d.getMonth() + 1).toString().padStart(2, "0"),
              d.getDate().toString().padStart(2, "0"),
            ].join("-") +
            " " +
            [
              d.getHours().toString().padStart(2, "0"),
              d.getMinutes().toString().padStart(2, "0"),
              d.getSeconds().toString().padStart(2, "0"),
            ].join(":");
        } else {
          Sdt = sDatetime.replace("T", " ");
        }
        //console.log(qty, updatedItem[prod_code].total_qty);
        if (qty <= updatedItem[prod_code].total_qty) {
          //console.log(parseFloat(qty_shipped), s, last_qty_edits, number);
          const newitem = {
            item_no: updatedItem[prod_code].item_no,
            code: updatedItem[prod_code].code,
            details: updatedItem[prod_code].details,
            product: updatedItem[prod_code].product,
            total_qty: updatedItem[prod_code].total_qty,
            quantity_shipped: qty,
            date_shipped: Sdt,
            note: acc_notes,
            last_qty: s,
            ship_address: ShipAddress,
            product_id: updatedItem[prod_code].product_id,
          };
          // const newitem1 = {
          //   item_no: updatedItem[prod_code].item_no,
          //   code: updatedItem[prod_code].code,
          //   details: updatedItem[prod_code].details,
          //   product: updatedItem[prod_code].product,
          //   total_qty: updatedItem[prod_code].total_qty,
          //   quantity_shipped: qty,
          //   date_shipped: Sdt,
          //   note: acc_notes,
          //   last_qty: s1,
          //   ship_address: ShipAddress,
          //   product_id: updatedItem[prod_code].product_id,
          // };
          //console.log(newitem, newitem1);
          const updatedRecords = updatedItem.filter(
            (record) => record.item_no !== updatedItem[prod_code].item_no
          );
          if (parseFloat(qty_shipped) > 0) {
            setAllAccounts([...updatedRecords, newitem]);
            newitem.last_qty = s1;
            //console.log(newitem);
            setAllAccounts1([...updatedItem1, newitem]);
          }
          setForceUpdate(true);
          setIsEditMode(false);
          setIsUpdateButtonDisabled(false);
          setrow_id("");
          setShipAddress("");
          setqty_shipped(0);
          setrow_id("");
          setso_id(null);
          setAccNotes("");
        } else {
          alert("Qty Shipped cannot be greater than Ordered Qty!");
        }
      } else {
        alert("New Qty Shipped should be greater than ZERO!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewEmployeesClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      if (userID && userToken) {
        const updatedFormData = { ...formData1 };
        updatedFormData.so_id = so_id_param;
        updatedFormData.store_id = store_id;
        updatedFormData.invoice_id = invoice.toString();
        updatedFormData.shipments = [];
        var specOrder = {
          products: [],
          remove_products: [],
        };
        AllAccounts1.forEach((product, index) => {
          const shipment = {
            item_no: product.item_no,
            product_id: product.product_id,
            quantity: product.total_qty,
            quantity_shipped: product.last_qty,
            note: product.note,
            shipped_date: product.date_shipped,
            ship_address: product.ship_address,
          };
          updatedFormData.shipments.push(shipment);

          var dt = "",
            qq = 10;
          const prodexist = CardList.find(
            (item) => item.item_no === product.item_no
          );
          var t = 1;
          if (prodexist) {
            t = parseFloat(prodexist.spec_qty);
          }

          if (
            (parseFloat(prodexist.spec_qty) >= 0 && t > 0) ||
            prodexist.spec_qty > 0
          ) {
            if (prodexist.req_delivery_date) {
              dt = prodexist.req_delivery_date;
            }
            if (
              parseFloat(prodexist.spec_qty) <=
              parseFloat(product.last_qty) + parseFloat(prodexist.shipment)
            ) {
              qq = 13;
            }
            const Specproduct = {
              product_id: product.product_id,
              so_id: so_id_param,
              quantity_req: prodexist.spec_qty,
              item_no: product.item_no,
              quantity_order: 0,
              status_id: qq,
              req_delivery_date: dt,
              note: "",
            };
            specOrder.products.push(Specproduct);
          }
        });

        setFormData1(updatedFormData);
        //console.log(updatedFormData);
        const response = await EditShipment(updatedFormData, userID, userToken);
        if (response.status === 200) {
          if (specOrder.products.length > 0) {
            const resp = await EditSpecialOrder(specOrder, userID, userToken);
            if (resp.status === 200) {
              // console.log(resp.data);
              if (resp.data.id) {
              } else {
                alert("Special qty failed to update.");
                return;
              }
            } else {
              alert("Special qty failed to update.");
              return;
            }
          }
          alert("Shipment Updated Successfully");
          navigate("/Sales");
        } else {
          alert("Shipment Failed to Update");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancelClick = async (event) => {
    TimeoutUtility.resetTimeout();
    event.preventDefault();
    try {
      setIsUpdateButtonDisabled(false);
      // setIsEditMode(false);
      setForceUpdate(true);
      setIsEditMode(false);
      // setIsUpdateButtonDisabled(false);
      setso_id(null);
      setqty_shipped(0);
      setAccNotes("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onChangeGrid = (args) => {
    // const updatedItem = [...AllAccounts];
    // console.log("hehheh")
    // setAllAccounts(updatedItem);
    // setForceUpdate(false);
  };

  const handleRowSelected = (args) => {
    setso_id("");
    const selectedRowData = args.data;
    setso_id(selectedRowData ? selectedRowData.code : "");
    // console.log(selectedRowData.code);
    // console.log(args.rowIndex);
    setrow_id(args.rowIndex);
  };

  useEffect(() => {
    if (so_id_param && userID && userToken) {
      TimeoutUtility.resetTimeout();
      var now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      setSDatetime(now.toISOString().slice(0, 19));
      //setsstore_id(store_id_param);
      GetShipmentSaleOrderByID(so_id_param, userID, userToken)
        .then((resp) => {
          setcustomer(resp.data[0].customer);
          setso_date(resp.data[0].sale_date);
          setinvoice(resp.data[0].invoice_id);
          setstore_id(resp.data[0].store_id);
          var sal_dt = new Date(resp.data[0].s_date);
          sal_dt.setMinutes(sal_dt.getMinutes() - sal_dt.getTimezoneOffset());
          setSMinDatetime(sal_dt.toISOString().slice(0, 19));
        })
        .catch((err) => {
          console.log(err.message);
        });

      GetShipmentProductsBySO_ID(so_id_param, userID, userToken)
        .then((result) => {
          setlast_qty_edit(result.data || []);
          setAllAccounts(result.data || []);
        })
        .catch((err) => {
          console.log(err.message);
        });

      GetSaleOrderDetailShipmentByID(so_id_param, userID, userToken).then(
        function (result) {
          if (result.data) {
            const productList = result.data.map((item) => {
              return {
                item_no: item.item_no,
                product_id: item.product_id,
                name: item.product_name,
                code: item.code,
                unit_price: item.unit_price,
                cost_price: item.cost_price,
                quantity: item.quantity,
                discount: item.discount,
                total: item.total,
                shipment: item.product_shipped,
                spec_qty: item.quantity_req,
              };
            });
            //console.log(productList);

            setcartList((prevProductList) => [
              ...productList,
              ...prevProductList,
            ]);

            // const qtyShippedList = productList.map((product) => ({
            //   product_id: product.product_id,
            //   shipment: product.shipment,
            //   spec_qty: product.spec_qty,
            // }));

            // setqtyShipped((prevQtyShippedList) => [
            //   ...prevQtyShippedList,
            //   ...qtyShippedList,
            // ]);
          }
        }
      );
    }
  }, [so_id_param, userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="SHIPPING" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            //className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">SO #: </label>
                  <input
                    className="input"
                    required
                    type="text"
                    name="name"
                    value={so_id_param}
                    placeholder="Sales Order"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Customer: </label>
                  <input
                    type="text"
                    name="email"
                    value={customer}
                    placeholder="Customer Name"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">SO Date: </label>
                  <br />
                  <input
                    required
                    type="text"
                    name="phone"
                    value={so_date}
                    placeholder="SO Date"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              {/* </div>
                    <div className="article"> */}
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Customer Invoice: </label>
                  <br />
                  <input
                    type="text"
                    name="invoice"
                    value={invoice}
                    placeholder="Invoice No."
                    className="input"
                    readOnly
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            style={{
              padding: "0",
            }}
          >
            <Col md={12} className="container-col">
              <div className="flex justify-end">
                <button
                  style={{
                    padding: "10px",
                    backgroundColor:
                      (isUpdateButtonDisabled && !isEditMode) ||
                      !so_id ||
                      isEditMode
                        ? "grey"
                        : currentColor,
                    color: "#fff",
                    borderRadius: "10px",
                    margin: "3px",
                  }}
                  onClick={handleAddEmployeesClick}
                  disabled={
                    (isUpdateButtonDisabled && !isEditMode) ||
                    !so_id ||
                    isEditMode
                  }
                >
                  Edit / Ship
                </button>
              </div>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            style={{
              padding: "0",
            }}
          >
            <Col md={12} className="container-col">
              <GridComponent
                className="custom-grid"
                dataSource={AllAccounts}
                allowPaging={true}
                pageSettings={{ pageSize: 16 }}
                allowSorting
                allowResizing
                rowSelected={handleRowSelected}
                rowHeight={36}
                onChange={onChangeGrid}
              >
                <ColumnsDirective>
                  {customersGrid.map((item, index) => (
                    <ColumnDirective key={index} {...item} />
                  ))}
                </ColumnsDirective>
                <Inject
                  services={[Resize, Page, Toolbar, Selection, Sort, Filter]}
                />
              </GridComponent>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            style={{
              padding: "0",
            }}
          >
            <Col md={12} className="container-col">
              <div className="flex justify-end">
                <button
                  style={{
                    padding: "10px",
                    backgroundColor: !isUpdateButtonDisabled
                      ? "grey"
                      : currentColor,
                    color: "#fff",
                    borderRadius: "10px",
                    margin: "3px",
                  }}
                  disabled={!isUpdateButtonDisabled}
                  onClick={handleUpdateItemClick}
                >
                  Update Item
                </button>

                <button
                  style={{
                    padding: "10px",
                    backgroundColor: !isUpdateButtonDisabled
                      ? "grey"
                      : currentColor,
                    color: "#fff",
                    borderRadius: "10px",
                    margin: "3px",
                  }}
                  disabled={!isUpdateButtonDisabled}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
          <Row
            xs={1}
            sm={1}
            //className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">
                    <span>Ship DateTime:</span>
                    <span style={{ float: "right", paddingRight: "22%" }}>
                      Now{" "}
                      <input
                        type="checkbox"
                        checked={dTNow === 1}
                        disabled={!isUpdateButtonDisabled}
                        onChange={handleChangeDTNow}
                        style={{
                          width: "14px",
                          height: "14px",
                        }}
                      />
                    </span>
                  </label>
                  <div className="flex mb-2">
                    <input
                      disabled={dTNow || !isUpdateButtonDisabled}
                      className="input"
                      id="ship_date"
                      type="datetime-local"
                      style={{
                        background:
                          (dTNow || !isUpdateButtonDisabled) && "lightgray",
                      }}
                      value={sDatetime}
                      min={sMinDatetime}
                      max={sMaxDatetime}
                      onChange={(e) => handleChangeSDateTime(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">New Qty Ship'd: </label>
                  <br />
                  <input
                    required
                    type="number"
                    name="qty_shipped"
                    step="1.00"
                    value={qty_shipped}
                    placeholder="Quantity Shipped"
                    className="input"
                    onChange={handleChangeqty_shipped}
                    disabled={!isUpdateButtonDisabled}
                  />
                </div>
              </div>
              <div
                className="article-cus"
                style={{
                  marginRight: "auto",
                }}
              >
                <div>{/* disabled={!so_id} */}</div>
              </div>
            </Col>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Ship Address: </label>
                  <input
                    style={{
                      width: "90%",
                    }}
                    placeholder="Ship Address "
                    id="noteTextarea"
                    value={ShipAddress}
                    onChange={handleChangeShipAddress}
                    rows="2"
                    className="input"
                    disabled={!isUpdateButtonDisabled}
                  />
                </div>
              </div>
              <br />
            </Col>
          </Row>

          <Row
            xs={1}
            sm={1}
            //className="justify-content-center"
            style={{
              padding: "0",
            }}
          >
            <Col md={12} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Note: </label>
                  <input
                    placeholder="Note "
                    id="noteTextarea"
                    value={acc_notes}
                    rows="2"
                    onChange={handleChangeAccNote}
                    className="input"
                    disabled={!isUpdateButtonDisabled}
                    style={{ width: "95%" }}
                  />
                </div>
              </div>
              <br />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <button
          style={{
            backgroundColor: !forceUpdate ? "grey" : currentColor,
            color: "#fff",
            borderRadius: "10px",
            margin: "10px",
            padding: "20px",
          }}
          onClick={handleViewEmployeesClick}
          disabled={!forceUpdate}
        >
          Save Order
        </button>
        <button
          style={{
            backgroundColor: currentColor,
            color: "#fff",
            borderRadius: "10px",
            margin: "10px",
            padding: "20px",
          }}
          onClick={handleBackClick}
        >
          Back
        </button>
      </Row>
    </div>
  );
};

export default Shipment;
