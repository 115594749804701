import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = process.env.REACT_APP_API_URL;

export const LoginApi = async (username, password) => {
  try {
    //console.log("LoginApi");
    const response = await axios.post(`${BASE_URL}/login/userLogin`, {
      username: username,
      password: password,
    });
    return response;
  } catch (err) {
    //console.log(err);
    return false;
  }
};

export const AddUserApi = async (
  username,
  password,
  role_id,
  role_desc,
  employee_id,
  store_id,
  org_name,
  profile,
  jsonData,
  user_id,
  token
) => {
  try {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("role_id", role_id);
    formData.append("role_desc", role_desc);
    formData.append("employee_id", employee_id);
    formData.append("store_id", store_id);
    formData.append("org_name", org_name);
    formData.append("profile", profile);
    formData.append("jsonData", JSON.stringify(jsonData));
    const response = await axios.post(`${BASE_URL}/user/addUser`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        user_id: user_id,
        token: token,
      },
    });

    //console.log(response.data);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const EditUserApi = async (
  p_user_id,
  username,
  password,
  role_id,
  role_desc,
  employee_id,
  store_id,
  org_name,
  profile,
  jsonData,
  user_id,
  token
) => {
  try {
    const formData = new FormData();
    formData.append("p_user_id", p_user_id);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("role_id", role_id);
    formData.append("role_desc", role_desc);
    formData.append("employee_id", employee_id);
    formData.append("store_id", store_id);
    formData.append("org_name", org_name);
    formData.append("profile", profile);
    formData.append("jsonData", JSON.stringify(jsonData));
    //console.log("EditUserApi");
    const response = await axios.put(`${BASE_URL}/user/editUser`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        user_id: user_id,
        token: token,
      },
    });

    //console.log(response);
    return response;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const DeleteUser = async (p_user_id, user_id, token) => {
  try {
    //console.log("DeleteAccount");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/user/DeleteUser`,
      {
        p_user_id: p_user_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllUserRoles = async (user_id, token) => {
  try {
    //console.log("GetAllUserRoles");
    const response = await axios.post(
      `${BASE_URL}/user/getAllUserRoles`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllUserPermissions = async (user_id, token) => {
  try {
    //console.log("GetAllUserPermissions");
    const response = await axios.post(
      `${BASE_URL}/user/getAllUserPermissions`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetUserPermissions = async (p_user_id, user_id, token) => {
  try {
    //console.log("GetUserPermissions");
    const response = await axios.post(
      `${BASE_URL}/user/getUserPermissions`,
      {
        p_user_id: p_user_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllUsersByStore = async (store_id, user_id, token) => {
  try {
    //console.log("getAllUsersByStore");
    const response = await axios.post(
      `${BASE_URL}/user/getAllUsersByStore`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetUserById = async (p_user_id, user_id, token) => {
  try {
    //console.log("GetAllPurchaseByID");
    const response = await axios.post(
      `${BASE_URL}/user/getUserByID`,
      {
        p_user_id: p_user_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllUsers = async (user_id, token) => {
  try {
    //console.log("getAllUsers");
    const response = await axios.post(
      `${BASE_URL}/user/getAllUsers`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetUserList = async (user_id, token) => {
  try {
    //console.log("getAllUsers");
    const response = await axios.post(
      `${BASE_URL}/user/getUserList`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllUserActivities = async (p_user_id, user_id, token) => {
  try {
    //console.log("getAllUsers");
    const response = await axios.post(
      `${BASE_URL}/user/getUserActivities`,
      {
        p_user_id: p_user_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckUserDeleteStatus = async (p_user_id, user_id, token) => {
  try {
    //console.log("CheckUserDeleteStatus");
    // console.log(names);
    const response = await axios.post(
      `${BASE_URL}/user/CheckUserDeleteStatus`,
      {
        p_user_id: p_user_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const CheckUserNameExist = async (username, user_id, token) => {
  try {
    //console.log("CheckUserNameExist");
    const response = await axios.post(
      `${BASE_URL}/user/CheckUserNameExist`,
      {
        username: username,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetUserOnlyPermissions = async (p_user_id, user_id, token) => {
  try {
    //console.log("GetUserPermissions");
    const response = await axios.post(
      `${BASE_URL}/user/getUserOnlyPermissions`,
      {
        p_user_id: p_user_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetUserInfoByID = async (p_user_id, user_id, token) => {
  try {
    //console.log("GetUserPermissions");
    const response = await axios.post(
      `${BASE_URL}/user/getUserInfoByID`,
      {
        p_user_id: p_user_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddUserActivity = async (
  p_user_id,
  activity_id,
  note,
  user_id,
  token
) => {
  try {
    //console.log("AddUnit");
    //console.log(names);
    const response = await axios.post(
      `${BASE_URL}/user/addUserActivity`,
      {
        p_user_id: p_user_id,
        activity_id: activity_id,
        note: note,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
