import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const AddBrandApi = async (name, detail, profile, user_id, token) => {
  try {
    //console.log("AddBrandApi");
    //console.log(names);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("detail", detail);
    formData.append("profile", profile);

    const response = await axios.post(`${BASE_URL}/brand/addBrand`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        user_id: user_id,
        token: token,
      },
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddCategoryApi = async (names, parent_id, user_id, token) => {
  try {
    //console.log("AddCategoryApi");
    //console.log(names);
    const response = await axios.post(
      `${BASE_URL}/category/addCategory`,
      {
        name: names,
        parent_id: parent_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddParentCategoryApi = async (
  name,
  note,
  featured,
  profile,
  user_id,
  token
) => {
  try {
    //console.log("AddCategoryApi");
    //console.log(names);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("note", note);
    formData.append("featured", featured);
    formData.append("profile", profile);

    const response = await axios.post(
      `${BASE_URL}/category/addParentCategory`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const AddUnitApi = async (names, user_id, token) => {
  try {
    //console.log("AddUnit");
    //console.log(names);
    const response = await axios.post(
      `${BASE_URL}/unit/addUnit`,
      {
        name: names,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditBrandApi = async (
  brand_id,
  name,
  detail,
  profile,
  user_id,
  token
) => {
  try {
    //console.log("AddBrandApi");
    //console.log(names);
    const formData = new FormData();
    formData.append("brand_id", brand_id);
    formData.append("name", name);
    formData.append("detail", detail);
    formData.append("profile", profile);

    const response = await axios.put(`${BASE_URL}/brand/EditBrand`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        user_id: user_id,
        token: token,
      },
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditCategoryApi = async (
  category_id,
  names,
  parent_id,
  user_id,
  token
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/category/editCategory`,
      { category_id: category_id, name: names, parent_id: parent_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditParentCategoryApi = async (
  category_p_id,
  name,
  note,
  featured,
  profile,
  user_id,
  token
) => {
  try {
    const formData = new FormData();
    formData.append("category_p_id", category_p_id);
    formData.append("name", name);
    formData.append("featured", featured);
    formData.append("note", note);
    formData.append("profile", profile);

    const response = await axios.put(
      `${BASE_URL}/category/EditParentCategory`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          user_id: user_id,
          token: token,
        },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const EditUnitApi = async (unit_id, names, user_id, token) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/unit/EditUnit`,
      { unit_id: unit_id, name: names },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteBrandById = async (brand_id, user_id, token) => {
  try {
    //console.log("DeleteBrandById");
    //console.log(brand_id);
    const response = await axios.post(
      `${BASE_URL}/brand/deletebrandbyid`,
      {
        brand_id: brand_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteCategoryById = async (category_id, user_id, token) => {
  try {
    //console.log("DeleteCategoryById");
    //console.log(category_id);
    const response = await axios.post(
      `${BASE_URL}/category/deletecategorybyid`,
      {
        category_id: category_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteParentCategoryById = async (
  category_p_id,
  user_id,
  token
) => {
  try {
    //console.log("DeleteCategoryById");
    //console.log(category_id);
    const response = await axios.post(
      `${BASE_URL}/category/deleteparentcategorybyid`,
      {
        category_p_id: category_p_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const DeleteUnitById = async (unit_id, user_id, token) => {
  try {
    //console.log("DeleteUnitById");
    const response = await axios.post(
      `${BASE_URL}/unit/deleteunitbyid`,
      {
        unit_id: unit_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetBrandById = async (brand_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/brand/GetBrandById`,
      { brand_id: brand_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetCategoryById = async (category_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/category/GetCategoryById`,
      { category_id: category_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetParentCategoryById = async (category_p_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/category/GetParentCategoryById`,
      { category_p_id: category_p_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetUnitById = async (unit_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/unit/GetUnitById`,
      { unit_id: unit_id },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllBrands = async (user_id, token) => {
  try {
    //console.log("GetAllBrands");
    const response = await axios.post(
      `${BASE_URL}/brand/getallbrands`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllCategories = async (user_id, token) => {
  try {
    //console.log("GetAllCategories");
    const response = await axios.post(
      `${BASE_URL}/category/getallcategories`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllParentCategories = async (user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/category/getallparentcategories`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAllUnits = async (user_id, token) => {
  try {
    //console.log("GetAllUnits");
    const response = await axios.post(
      `${BASE_URL}/unit/getallunits`,
      {},
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
