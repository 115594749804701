import React from "react";
import { Navigate } from "react-router-dom";
import { useOnlineStatus } from "./OnlineStatus";
//import { useEffect, useState } from "react";

const ProtectedRoute = ({ children, perms }) => {
  const online = useOnlineStatus();
  // console.log(online, children.type.name);
  const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log(children.type.name);
  const def = <Navigate to="/UnAuthorized" />;
  const nf = <Navigate to="/NotFound" />;
  const na = <Navigate to="/NotAvailable" />;
  const includesAny = (arr, values) => values.some((v) => arr.includes(v));
  // const mainPages = [
  //   "Stores",
  //   "Inventory",
  //   "Products",
  //   "Sales",
  //   "Purchase",
  //   "Account",
  //   "Customers",
  //   "Supplier",
  //   "Employee",
  //   "Receipt",
  //   "Payment",
  //   "Journal",
  //   "Expense",
  //   "User",
  // ];
  //console.log(userData);
  if (!userData || !userData["user_id"]) {
    return <Navigate to="/login" />;
  }

  if (perms && perms.length > 0) {
    if (online) {
      //console.log(children.type.name);
      // console.log(perms);
      if (!includesAny(perms, [5000]) && children.type.name === "Overview") {
        return def;
      } else if (
        !includesAny(perms, [5001, 5002, 5003]) &&
        children.type.name === "Stores"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5001]) &&
        children.type.name === "ViewStore"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5002]) &&
        children.type.name === "AddStore"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5003]) &&
        children.type.name === "EditStore"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5004, 5005, 5006]) &&
        children.type.name === "Inventory"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5004]) &&
        children.type.name === "ViewInventory"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5005]) &&
        children.type.name === "AddOpeningBal"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5006]) &&
        (children.type.name === "InStock" || children.type.name === "OutStock")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5007, 5008, 5009]) &&
        children.type.name === "Products"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5007]) &&
        children.type.name === "ViewProduct"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5008]) &&
        children.type.name === "AddProduct"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5009]) &&
        children.type.name === "EditProduct"
      ) {
        return def;
      } else if (
        // !includesAny(perms, [5010, 5011, 5012]) &&
        children.type.name === "Estimates"
      ) {
        return nf;
      } else if (
        // !includesAny(perms, [5010]) &&
        children.type.name === "ViewEstimation"
      ) {
        return nf;
      } else if (
        // !includesAny(perms, [5011]) &&
        children.type.name === "AddEstimation"
      ) {
        return nf;
      } else if (
        // !includesAny(perms, [5012]) &&
        children.type.name === "EditEstimation"
      ) {
        return nf;
      }
      //This is for the restriction of extra pages
      else if (
        children.type.name === "ViewShipment" ||
        children.type.name === "Shipment" ||
        children.type.name === "ConvertEstimation" ||
        children.type.name === "SpecialOrders" ||
        children.type.name === "SpecialOrder" ||
        children.type.name === "ViewReceiveLog" ||
        children.type.name === "ReceiveLog"
      ) {
        return nf;
      } else if (
        !includesAny(perms, [5013, 5014, 5015]) &&
        (children.type.name === "Sales" ||
          children.type.name === "SalesReturns")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5013]) &&
        (children.type.name === "ViewSaleOrder" ||
          // children.type.name === "ViewShipment" ||
          children.type.name === "ViewSalesReturn")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5014]) &&
        (children.type.name === "AddSaleOrder" ||
          children.type.name === "AddSalesReturn")
        // children.type.name === "ConvertEstimation" ||
        //children.type.name === "Shipment"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5015]) &&
        (children.type.name === "EditSaleOrder" ||
          children.type.name === "EditSalesReturn")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5016, 5017, 5018]) &&
        children.type.name === "Purchase"
        // children.type.name === "SpecialOrders" ||
        // children.type.name === "SpecialOrder")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5016]) &&
        children.type.name === "ViewPurchaseOrder"
        // children.type.name === "ViewReceiveLog")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5017]) &&
        children.type.name === "AddPurchaseOrder"
        // children.type.name === "ReceiveLog")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5018]) &&
        children.type.name === "EditPurchaseOrder"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5019, 5020, 5021]) &&
        children.type.name === "Account"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5019]) &&
        children.type.name === "ViewAccount"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5020]) &&
        children.type.name === "AddAccount"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5021]) &&
        children.type.name === "EditAccount"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5022, 5023, 5024]) &&
        children.type.name === "Customers"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5022]) &&
        (children.type.name === "ViewCustomer" ||
          children.type.name === "ViewSale" ||
          children.type.name === "ViewSO")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5023]) &&
        children.type.name === "AddCustomer"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5024]) &&
        children.type.name === "EditCustomer"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5025, 5026, 5027]) &&
        children.type.name === "Supplier"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5025]) &&
        (children.type.name === "ViewSupplier" ||
          children.type.name === "ViewPurchase" ||
          children.type.name === "ViewPO")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5026]) &&
        (children.type.name === "AddSupplier" ||
          children.type.name === "ProductAssign")
      ) {
        return def;
      } else if (
        !includesAny(perms, [5027]) &&
        children.type.name === "EditSupplier"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5028, 5029, 5030]) &&
        children.type.name === "Employee"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5028]) &&
        children.type.name === "ViewEmployee"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5029]) &&
        children.type.name === "AddEmployee"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5030]) &&
        children.type.name === "EditEmployee"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5031, 5032, 5033]) &&
        children.type.name === "Receipt"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5031]) &&
        children.type.name === "ViewReceipt"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5032]) &&
        children.type.name === "AddReceipt"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5033]) &&
        children.type.name === "EditReceipt"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5034, 5035, 5036]) &&
        children.type.name === "Payment"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5034]) &&
        children.type.name === "ViewPayment"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5035]) &&
        children.type.name === "AddPayment"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5036]) &&
        children.type.name === "EditPayment"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5037, 5038, 5039]) &&
        children.type.name === "Journal"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5037]) &&
        children.type.name === "ViewJournal"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5038]) &&
        children.type.name === "AddJournal"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5039]) &&
        children.type.name === "EditJournal"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5040, 5041, 5042]) &&
        children.type.name === "Expense"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5040]) &&
        children.type.name === "ViewExpense"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5041]) &&
        children.type.name === "AddExpense"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5042]) &&
        children.type.name === "EditExpense"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5043, 5044, 5045]) &&
        children.type.name === "Users"
      ) {
        return def;
      } else if (
        !includesAny(perms, [5043]) &&
        (children.type.name === "ViewUser" ||
          children.type.name === "UserActivities")
      ) {
        return def;
        // } else if (
        //   !includesAny(perms, [5044]) &&
        //   children.type.name === "AddUser"
        // ) {
        //   return def;
      } else if (
        !includesAny(perms, [5045]) &&
        children.type.name === "EditUser"
      ) {
        return def;
      }
      // else if (
      //   children.type.name === "UnAuthorized" ||
      //   children.type.name === "NotFound"
      // ) {
      //   return children;
      // } else {
      //   return <Navigate to="/NotFound" />;
      // }
    } else {
      if (
        children.type.name === "ViewStore" ||
        children.type.name === "AddStore" ||
        children.type.name === "EditStore" ||
        children.type.name === "ViewInventory" ||
        children.type.name === "AddOpeningBal" ||
        children.type.name === "InStock" ||
        children.type.name === "OutStock" ||
        children.type.name === "ViewProduct" ||
        children.type.name === "AddProduct" ||
        children.type.name === "EditProduct" ||
        children.type.name === "Estimates" ||
        children.type.name === "ViewEstimation" ||
        children.type.name === "AddEstimation" ||
        children.type.name === "EditEstimation" ||
        children.type.name === "ViewShipment" ||
        children.type.name === "Shipment" ||
        children.type.name === "ConvertEstimation" ||
        children.type.name === "SpecialOrders" ||
        children.type.name === "SpecialOrder" ||
        children.type.name === "ViewReceiveLog" ||
        children.type.name === "ReceiveLog" ||
        children.type.name === "SalesReturns" ||
        children.type.name === "ViewSaleOrder" ||
        children.type.name === "ViewSalesReturn" ||
        children.type.name === "AddSalesReturn" ||
        children.type.name === "EditSaleOrder" ||
        children.type.name === "EditSalesReturn" ||
        children.type.name === "ViewPurchaseOrder" ||
        children.type.name === "AddPurchaseOrder" ||
        children.type.name === "EditPurchaseOrder" ||
        children.type.name === "ViewAccount" ||
        children.type.name === "AddAccount" ||
        children.type.name === "EditAccount" ||
        children.type.name === "ViewCustomer" ||
        children.type.name === "ViewSale" ||
        children.type.name === "ViewSO" ||
        children.type.name === "AddCustomer" ||
        children.type.name === "EditCustomer" ||
        children.type.name === "ViewSupplier" ||
        children.type.name === "ViewPurchase" ||
        children.type.name === "ViewPO" ||
        children.type.name === "AddSupplier" ||
        children.type.name === "ProductAssign" ||
        children.type.name === "EditSupplier" ||
        children.type.name === "ViewEmployee" ||
        children.type.name === "AddEmployee" ||
        children.type.name === "EditEmployee" ||
        children.type.name === "ViewReceipt" ||
        children.type.name === "AddReceipt" ||
        children.type.name === "EditReceipt" ||
        children.type.name === "ViewPayment" ||
        children.type.name === "AddPayment" ||
        children.type.name === "EditPayment" ||
        children.type.name === "ViewJournal" ||
        children.type.name === "AddJournal" ||
        children.type.name === "EditJournal" ||
        children.type.name === "ViewExpense" ||
        children.type.name === "AddExpense" ||
        children.type.name === "EditExpense" ||
        children.type.name === "ViewUser" ||
        children.type.name === "UserActivities" ||
        children.type.name === "EditUser"
      ) {
        return na;
      } else if (
        includesAny(perms, [5000]) &&
        children.type.name === "Overview"
      ) {
        return children;
      } else if (
        includesAny(perms, [5001, 5002, 5003]) &&
        children.type.name === "Stores"
      ) {
        return children;
      } else if (
        includesAny(perms, [5004, 5005, 5006]) &&
        children.type.name === "Inventory"
      ) {
        return children;
      } else if (
        includesAny(perms, [5007, 5008, 5009]) &&
        children.type.name === "Products"
      ) {
        return children;
      } else if (
        includesAny(perms, [5013, 5014, 5015]) &&
        children.type.name === "Sales"
      ) {
        return children;
      } else if (
        includesAny(perms, [5014]) &&
        children.type.name === "AddSaleOrder"
      ) {
        return children;
      } else if (
        includesAny(perms, [5016, 5017, 5018]) &&
        children.type.name === "Purchase"
      ) {
        return children;
      } else if (
        includesAny(perms, [5019, 5020, 5021]) &&
        children.type.name === "Account"
      ) {
        return children;
      } else if (
        includesAny(perms, [5022, 5023, 5024]) &&
        children.type.name === "Customers"
      ) {
        return children;
      } else if (
        includesAny(perms, [5025, 5026, 5027]) &&
        children.type.name === "Supplier"
      ) {
        return children;
      } else if (
        includesAny(perms, [5028, 5029, 5030]) &&
        children.type.name === "Employee"
      ) {
        return children;
      } else if (
        includesAny(perms, [5031, 5032, 5033]) &&
        children.type.name === "Receipt"
      ) {
        return children;
      } else if (
        includesAny(perms, [5034, 5035, 5036]) &&
        children.type.name === "Payment"
      ) {
        return children;
      } else if (
        includesAny(perms, [5037, 5038, 5039]) &&
        children.type.name === "Journal"
      ) {
        return children;
      } else if (
        includesAny(perms, [5040, 5041, 5042]) &&
        children.type.name === "Expense"
      ) {
        return children;
      } else if (
        includesAny(perms, [5043, 5044, 5045]) &&
        children.type.name === "Users"
      ) {
        return children;
      }
    }
  }

  // else if (
  //   userData["role"] === "admin" &&
  //   (children.type.name === "Employee" ||
  //     children.type.name === "Customers" ||
  //     children.type.name === "Inventory" ||
  //     children.type.name === "AddInventory" ||
  //     children.type.name === "AddProduct" ||
  //     children.type.name === "EditProduct" ||
  //     children.type.name === "In_Stock" ||
  //     children.type.name === "Out_Stock" ||
  //     children.type.name === "Inventory")
  // ) {
  //   return <Navigate to="/ecommerce" />;
  // }
  // else if (
  //   userData["role"] === "manager" &&
  //   (children.type.name === "Employee" ||
  //   children.type.name === "Customers" ||
  //   children.type.name === "Inventory" ||
  //   children.type.name === "AddInventory" ||
  //   children.type.name === "AddProduct" ||
  //   children.type.name === "EditProduct" ||
  //   children.type.name === "In_Stock" ||
  //   children.type.name === "Out_Stock" ||
  //   children.type.name === "ViewInventory")
  // ) {
  //   return <Navigate to="/ecommerce" />;
  // }

  return children;
};

export default ProtectedRoute;

// if (perms && perms.length > 0) {
//   if (!includesAny(perms, [5000]) && children.type.name === "Overview") {
//     return def;
//   } else if (
//     !includesAny(perms, [5001, 5002, 5003]) &&
//     children.type.name === "Stores"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5001]) &&
//     children.type.name === "ViewStore"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5002]) &&
//     children.type.name === "AddStore"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5003]) &&
//     children.type.name === "EditStore"
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5004, 5005, 5006]) &&
//     children.type.name === "Inventory"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5004]) &&
//     children.type.name === "ViewInventory"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5005]) &&
//     children.type.name === "AddOpeningBal"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5006]) &&
//     (children.type.name === "InStock" || children.type.name === "OutStock")
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5007, 5008, 5009]) &&
//     children.type.name === "Products"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5007]) &&
//     children.type.name === "ViewProduct"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5008]) &&
//     children.type.name === "AddProduct"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5009]) &&
//     children.type.name === "EditProduct"
//   ) {
//     return def;
//   } else if (
//     // !includesAny(perms, [5010, 5011, 5012]) &&
//     children.type.name === "Estimates"
//   ) {
//     return nf;
//   } else if (
//     // !includesAny(perms, [5010]) &&
//     children.type.name === "ViewEstimation"
//   ) {
//     return nf;
//   } else if (
//     // !includesAny(perms, [5011]) &&
//     children.type.name === "AddEstimation"
//   ) {
//     return nf;
//   } else if (
//     // !includesAny(perms, [5012]) &&
//     children.type.name === "EditEstimation"
//   ) {
//     return nf;
//   }
//   //This is for the restriction of extra pages
//   else if (
//     children.type.name === "ViewShipment" ||
//     children.type.name === "Shipment" ||
//     children.type.name === "ConvertEstimation" ||
//     children.type.name === "SpecialOrders" ||
//     children.type.name === "SpecialOrder" ||
//     children.type.name === "ViewReceiveLog" ||
//     children.type.name === "ReceiveLog"
//   ) {
//     return nf;
//   } else if (
//     !includesAny(perms, [5013, 5014, 5015]) &&
//     (children.type.name === "Sales" || children.type.name === "SalesReturns")
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5013]) &&
//     (children.type.name === "ViewSaleOrder" ||
//       // children.type.name === "ViewShipment" ||
//       children.type.name === "ViewSalesReturn")
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5014]) &&
//     (children.type.name === "AddSaleOrder" ||
//       children.type.name === "AddSalesReturn")
//     // children.type.name === "ConvertEstimation" ||
//     //children.type.name === "Shipment"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5015]) &&
//     (children.type.name === "EditSaleOrder" ||
//       children.type.name === "EditSalesReturn")
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5016, 5017, 5018]) &&
//     children.type.name === "Purchase"
//     // children.type.name === "SpecialOrders" ||
//     // children.type.name === "SpecialOrder")
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5016]) &&
//     children.type.name === "ViewPurchaseOrder"
//     // children.type.name === "ViewReceiveLog")
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5017]) &&
//     children.type.name === "AddPurchaseOrder"
//     // children.type.name === "ReceiveLog")
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5018]) &&
//     children.type.name === "EditPurchaseOrder"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5019, 5020, 5021]) &&
//     children.type.name === "Account"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5019]) &&
//     children.type.name === "ViewAccount"
//   ) {
//     return def;
//   } else if (
//     (!online || !includesAny(perms, [5020])) &&
//     children.type.name === "AddAccount"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5021]) &&
//     children.type.name === "EditAccount"
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5022, 5023, 5024]) &&
//     children.type.name === "Customers"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5022]) &&
//     (children.type.name === "ViewCustomer" ||
//       children.type.name === "ViewSale" ||
//       children.type.name === "ViewSO")
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5023]) &&
//     children.type.name === "AddCustomer"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5024]) &&
//     children.type.name === "EditCustomer"
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5025, 5026, 5027]) &&
//     children.type.name === "Supplier"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5025]) &&
//     (children.type.name === "ViewSupplier" ||
//       children.type.name === "ViewPurchase" ||
//       children.type.name === "ViewPO")
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5026]) &&
//     (children.type.name === "AddSupplier" ||
//       children.type.name === "ProductAssign")
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5027]) &&
//     children.type.name === "EditSupplier"
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5028, 5029, 5030]) &&
//     children.type.name === "Employee"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5028]) &&
//     children.type.name === "ViewEmployee"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5029]) &&
//     children.type.name === "AddEmployee"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5030]) &&
//     children.type.name === "EditEmployee"
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5031, 5032, 5033]) &&
//     children.type.name === "Receipt"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5031]) &&
//     children.type.name === "ViewReceipt"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5032]) &&
//     children.type.name === "AddReceipt"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5033]) &&
//     children.type.name === "EditReceipt"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5034, 5035, 5036]) &&
//     children.type.name === "Payment"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5034]) &&
//     children.type.name === "ViewPayment"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5035]) &&
//     children.type.name === "AddPayment"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5036]) &&
//     children.type.name === "EditPayment"
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5037, 5038, 5039]) &&
//     children.type.name === "Journal"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5037]) &&
//     children.type.name === "ViewJournal"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5038]) &&
//     children.type.name === "AddJournal"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5039]) &&
//     children.type.name === "EditJournal"
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5040, 5041, 5042]) &&
//     children.type.name === "Expense"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5040]) &&
//     children.type.name === "ViewExpense"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5041]) &&
//     children.type.name === "AddExpense"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5042]) &&
//     children.type.name === "EditExpense"
//   ) {
//     return def;
//   } else if (
//     !includesAny(perms, [5043, 5044, 5045]) &&
//     children.type.name === "Users"
//   ) {
//     return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5043]) &&
//     (children.type.name === "ViewUser" ||
//       children.type.name === "UserActivities")
//   ) {
//     return def;
//     // } else if (
//     //   !includesAny(perms, [5044]) &&
//     //   children.type.name === "AddUser"
//     // ) {
//     //   return def;
//   } else if (
//     !online &&
//     !includesAny(perms, [5045]) &&
//     children.type.name === "EditUser"
//   ) {
//     return def;
//   }
//   // else if (
//   //   children.type.name === "UnAuthorized" ||
//   //   children.type.name === "NotFound"
//   // ) {
//   //   return children;
//   // } else {
//   //   return <Navigate to="/NotFound" />;
//   // }

//   // }
// }
