import { MdOutlineCancel } from "react-icons/md";
import { AddProductImage, GetOrderProductDetailById } from "../api";
import "../styles/ViewOrderProd.css";
import { useEffect, useState, useRef } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { FcAddImage } from "react-icons/fc";
import imageCompression from "browser-image-compression";

const ViewOrderProduct = ({ close, product_id, store_id }) => {
  const [product, setProduct] = useState("");
  const {
    activeProdMenu,
    setActiveProdMenu,
    activeProdMenuId,
    setActiveProdMenuId,
    userID,
    userToken,
  } = useStateContext();
  const sidebarRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleBackgroundClick = (e) => {
    //e.stopPropagation();
  };

  const handleOutsideClick = (e) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
      close();
    }
  };

  const insertImage = async (imgg, product_idss) => {
    const options = {
      maxSizeMB: 0.5, // Maximum file size in MB
      maxWidthOrHeight: 800, // Maximum width or height of the image
      useWebWorker: true, // Use web worker for faster compression
    };

    try {
      const compressedFile = await imageCompression(imgg, options);

      const response = await AddProductImage(
        product_idss,
        compressedFile,
        userID,
        userToken
      );
      if (response.status === 200) {
        await GetOrderProductDetailById(
          activeProdMenuId.product_id,
          activeProdMenuId.store_id,
          userID,
          userToken
        )
          .then((resp) => {
            setProduct(resp.data[0] || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        alert("Product image failed to add");
      }
    } catch (error) {
      console.error("Error compressing the image:", error);
    }
  };

  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 4194304) {
          alert("Image File max size is 4 MB!");
          e.target.value = null;
          // setUploadedImage(null);
          // setprofile(null);
          //console.log("not");
          return;
        } else {
          //setprofile(file);
          //var prod = product;
          //const reader = new FileReader();
          //reader.onloadend = () => {
          //setUploadedImage(reader.result);
          //prod.image = reader.result;
          //setProduct({ ...product, image: reader.result });
          await insertImage(file, product.product_id);
          //};
          //reader.readAsDataURL(file);
          //console.log(prod);
        }
      }
    } catch (err) {
      // return false;
    }
  };

  useEffect(() => {
    async function fetchData() {
      await GetOrderProductDetailById(
        activeProdMenuId.product_id,
        activeProdMenuId.store_id,
        userID,
        userToken
      )
        .then((resp) => {
          setProduct(resp.data[0] || []);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    fetchData();
    // document.addEventListener("mousedown", handleOutsideClick);
    // return () => {
    //   document.removeEventListener("mousedown", handleOutsideClick);
    // };
  }, [activeProdMenu, activeProdMenuId]);

  return (
    <div className=" h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeProdMenu && (
        <aside
        //className="sidebar1"
        //onClick={handleBackgroundClick}
        //ref={sidebarRef}
        >
          <div style={{ paddingTop: "20px" }}>
            <MdOutlineCancel
              style={{ cursor: "pointer" }}
              color="black"
              size={50}
              onClick={() =>
                //close()
                {
                  setActiveProdMenu(false);
                  setActiveProdMenuId({ product_id: null, store_id: null });
                }
              }
              className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
            />
          </div>

          <div>
            <label
              style={{
                fontSize: "22px",
                width: "100%",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              More about Product
              {/* {`${product.name}`} */}
            </label>

            <hr className="line" />
            {product.image ? (
              <div
                className="rounded"
                style={{
                  width: "92%",
                  height: "256px",
                  textAlign: "center",
                  backgroundColor: "lightgray",
                  margin: "4%",
                }}
              >
                <img
                  src={`data:image/jpeg;base64,${product.image}`}
                  alt={`product ${product.product_id}`}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                    textAlign: "center",
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "92%",
                  height: "256px",
                  marginTop: "4%",
                  marginBottom: "4%",
                  marginLeft: "20%",
                  marginRight: "20%",
                  alignContent: "center",
                }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  name="image"
                  id="image"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                <FcAddImage
                  id="icon"
                  size="64%"
                  cursor="pointer"
                  onClick={() => fileInputRef.current.click()}
                />
              </div>
            )}
            <hr
              className="line"
              style={{
                paddingBottom: "4px",
                // paddingTop: "20px",
              }}
            />
            <div
              style={{
                width: "100%",
                textAlign: "left",
                paddingLeft: "10%",
              }}
            >
              <span
                style={{
                  fontSize: "19px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >{`${product.code} ${product.name}`}</span>
              <br />

              <span
                style={{
                  fontSize: "13px",
                  textAlign: "left",
                }}
              >{`${product.details}`}</span>
              <br />
              <br />
              <span
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                }}
              >{`Unit: `}</span>
              <span
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  fontWeight: "lighter",
                }}
              >{`${product.unit_id}`}</span>
              <br />
              <span
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                }}
              >{`Category: `}</span>
              <span
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  fontWeight: "lighter",
                }}
              >{`${product.category_id}`}</span>
              <br />
              <span
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                }}
              >{`Brand: `}</span>
              <span
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  fontWeight: "lighter",
                }}
              >{`${product.brand_id}`}</span>
            </div>

            <hr
              className="line"
              style={{
                paddingBottom: "4px",
                // paddingTop: "20px",
              }}
            />

            <div
              style={{
                width: "100%",
                textAlign: "left",
                paddingLeft: "10%",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                }}
              >{`Inventory InStock: `}</span>
              <span
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  fontWeight: "lighter",
                }}
              >{`${product.unit_instock}.00`}</span>
              <br />
              <span
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                }}
              >{`Supplier: `}</span>
              {product.vendor ? (
                <span
                  style={{
                    fontSize: "18px",
                    textAlign: "left",
                    fontWeight: "lighter",
                  }}
                >{`${product.vendor}`}</span>
              ) : (
                <span
                  style={{
                    fontSize: "18px",
                    textAlign: "left",
                    fontWeight: "lighter",
                  }}
                >{`N/A`}</span>
              )}
            </div>
          </div>
        </aside>
      )}
    </div>
  );
};

export default ViewOrderProduct;
