import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { GetUserById, GetUserPermissions } from "../../api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";

const ViewUser = () => {
  const { currentColor, userID, userToken } = useStateContext();
  let param = useParams();
  const user_id_id = param.user_id;
  const [UserName, setUserName] = useState("");
  // const [Pass, setPass] = useState("");
  const [Notes, setNotes] = useState("");
  const [Role, setRole] = useState("select");
  const [Employee, setEmployee] = useState("select");
  const [Store, setStore] = useState("select");
  const [getPerm, setGetPerm] = useState([]);
  const [ProdImage, setProdImage] = useState("");

  const navigate = useNavigate();

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("Back");
      navigate("/Users");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (user_id_id && userID && userToken) {
        TimeoutUtility.resetTimeout();
        GetUserPermissions(user_id_id, userID, userToken)
          .then((resp) => {
            const groupedItems = resp.data.reduce(
              (acc, item) => {
                if (acc[acc.length - 1].length >= 4) {
                  return [...acc, [item]];
                }
                acc[acc.length - 1].push(item);
                return acc;
              },
              [[]]
            );
            setGetPerm(groupedItems || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        GetUserById(user_id_id, userID, userToken)
          .then((result) => {
            setUserName(result.data[0].username);
            // setPass(result.data[0].password);
            setProdImage(result.data[0].profile);
            setNotes(result.data[0].role_desc || "");
            setRole(result.data[0].roles);
            setEmployee(result.data[0].employee || "");
            setStore(result.data[0].store);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [user_id_id, userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="VIEW USER" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row xs={1} sm={1} style={{ padding: "0" }}>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Username:</label>
                  <input
                    className="input"
                    type="text"
                    name="name"
                    value={UserName}
                    placeholder="Username"
                    readOnly
                  />
                </div>
              </div>
              <br />
              {/* <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Password:</label>
                  <input
                    type="password"
                    name="pass"
                    value={Pass}
                    placeholder="Password"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <br /> */}
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Role:</label>
                  <input
                    type="text"
                    name="Role"
                    value={Role}
                    placeholder="User Role"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Default Store:</label>
                  <input
                    type="text"
                    name="Store"
                    value={Store}
                    placeholder="User Store"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Employee:</label>
                  <input
                    type="text"
                    name="Employee"
                    value={Employee}
                    placeholder="User Employee Name"
                    className="input"
                    readOnly
                  />
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Role Description:</label>
                  <textarea
                    placeholder="Role Description"
                    id="noteTextarea"
                    value={Notes}
                    className="textarea"
                    readOnly
                  />
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Profile:</label>
                  <div className="container-video-div" style={{ width: "80%" }}>
                    {ProdImage ? (
                      <img
                        src={`data:image/jpeg;base64,${ProdImage}`}
                        className="container-image"
                        alt="User"
                      />
                    ) : (
                      <p>No Image Available </p>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label mb-1">Permissions:</label>
                  {getPerm.map((items, index) => (
                    <Row key={index}>
                      {items.map((item, i) => (
                        <Col key={i} md={3} style={{ paddingRight: "0px" }}>
                          <label
                            className="label-checkbox"
                            style={{
                              fontSize: "13px",
                              width: "100%",
                              cursor: "default",
                            }}
                          >
                            <input
                              className="checkbox"
                              name="SelectAll"
                              type="checkbox"
                              value={item.permission_id}
                              checked={item.checker}
                              readOnly={true}
                            />
                            {` ${item.name}`}
                          </label>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </div>
              </div>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button ml-2"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default ViewUser;
