import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { validateName, ValidText } from "../../contexts/Utils";
import {
  EditUserApi,
  GetUserById,
  CheckUserNameExist,
  GetAllUserRoles,
  GetAllEmpNameNoUsers,
  GetAllStores,
  GetUserPermissions,
} from "../../api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";
import default_img from "../../data/default_img.jpg";
import imageCompression from "browser-image-compression";

const EditUser = () => {
  const { currentColor, userID, userToken } = useStateContext();
  let param = useParams();
  const user_id_id = param.user_id;
  const fileInputRef = useRef(null);
  const [UserName, setUserName] = useState("");
  const [UserName1, setUserName1] = useState("");
  const [Pass, setPass] = useState("");
  const [Notes, setNotes] = useState("");
  const [OrgName, setOrgName] = useState("");
  const [getRole, setGetRole] = useState([]);
  const [Role, setRole] = useState("select");
  const [getEmployee, setGetEmployee] = useState([]);
  const [Employee, setEmployee] = useState("select");
  const [getStore, setGetStore] = useState([]);
  const [Store, setStore] = useState("select");
  const [getPerm, setGetPerm] = useState([]);
  const [loadBtn, setLoadBtn] = useState(false);

  const [ProductImage, setProductImage] = useState("");
  const [ProductImage1, setProductImage1] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [ValError, setValError] = useState([]);
  const [profile1] = useState(null);

  const navigate = useNavigate();

  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleChangePass = (e) => {
    setPass(e.target.value);
  };

  const handleChangeRole = (e) => {
    setRole(e.target.value);
    if (e.target.value !== "select" || e.target.value !== "Select Role") {
      const updatedErrors = [...ValError];
      updatedErrors[4] = "";
      setValError(updatedErrors);
    }
  };

  const handleChangeEmployee = (e) => {
    setEmployee(e.target.value);
    if (e.target.value !== "select" && e.target.value !== "Select Employee") {
      const updatedErrors = [...ValError];
      updatedErrors[24] = "";
      setValError(updatedErrors);
    }
  };

  const handleChangeStore = (e) => {
    setStore(e.target.value);
    if (e.target.value !== "select" && e.target.value !== "Select Store") {
      const updatedErrors = [...ValError];
      updatedErrors[25] = "";
      setValError(updatedErrors);
    }
  };

  const handleChangeOrgName = (e) => {
    setOrgName(e.target.value);
  };

  const handleChangeNotes = (e) => {
    setNotes(e.target.value);
  };

  const validName1 = (name, ii) => {
    const updatedErrors = [...ValError];
    if (name.trim().length === 0) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return false;
    }
    if (validateName(name)) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return true;
    }
    updatedErrors[ii] = "Invalid field!";
    setValError(updatedErrors);
    return false;
  };

  const ValidText1 = (txt, ii) => {
    const updatedErrors = [...ValError];
    if (txt.trim().length === 0 || txt.trim() === "") {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return false;
    }
    if (ValidText(txt)) {
      updatedErrors[ii] = "";
      setValError(updatedErrors);
      return true;
    }
    updatedErrors[ii] = "Invalid field!";
    setValError(updatedErrors);
    return false;
  };

  const handleClickRemoveImage = () => {
    setProductImage(null);
    setProductImage1(null);
    setUploadedImage(null);
    fileInputRef.current.value = "";
  };

  const handleImageChange = (e) => {
    try {
      setProductImage1(null);
      const file = e.target.files[0];
      if (!file) {
        setProductImage(null);
        setUploadedImage(null);
      } else {
        if (file.size > 4194304) {
          alert("Image File max size is 4 MB!");
          setProductImage(null);
          setUploadedImage(null);
          e.target.value = null;
          return;
        } else {
          setProductImage(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setUploadedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    } catch (err) {
      return false;
    }
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      navigate("/Users");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    TimeoutUtility.resetTimeout();
    setLoadBtn(true);
    var resp = 0;
    try {
      setValError([]);
      const updatedErrors = [...ValError];
      var Emp_id = null;

      const { SelectAll } = document.forms[0];

      if (UserName === "") {
        updatedErrors[0] = "Please enter Username!";
        setValError(updatedErrors);
        return;
      }
      if (UserName) {
        if (validName1(UserName, 0) === false) {
          return;
        }
      }
      if (UserName1 !== UserName) {
        var exi = 0;
        await CheckUserNameExist(UserName, userID, userToken)
          .then((resp) => {
            exi = resp.data[0].name;
          })
          .catch((err) => {
            console.log(err.message);
          });
        if (exi === 1) {
          updatedErrors[0] = "User name must be unique!";
          setValError(updatedErrors);
          return;
        }
      }
      updatedErrors[0] = "";

      if (Pass === "") {
        updatedErrors[1] = "Please enter Password!";
        setValError(updatedErrors);
        return;
      }

      if (Pass.length < 8) {
        updatedErrors[1] = "Please enter atleast 8 characters!";
        setValError(updatedErrors);
        return;
      }
      if (Pass === Pass.toLowerCase()) {
        updatedErrors[1] =
          "Please enter atleast one Uppercase character [A-Z]!";
        setValError(updatedErrors);
        return;
      }
      if (Pass === Pass.toUpperCase()) {
        updatedErrors[1] = "Please enter atleast one Lower character [a-z]!";
        setValError(updatedErrors);
        return;
      }
      if (!/\d/.test(Pass)) {
        updatedErrors[1] = "Please enter atleast one decimal character [0-9]!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[1] = "";

      if (Role === "select" || Role === "Select Role") {
        updatedErrors[23] = "Please select User Role!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[23] = "";

      if (OrgName === "") {
        updatedErrors[4] = "Please enter Report Org Name!";
        setValError(updatedErrors);
        return;
      }
      if (OrgName !== "") {
        if (validName1(OrgName, 4) === false) {
          return;
        }
      }
      updatedErrors[4] = "";

      if (
        !Employee ||
        Employee === "select" ||
        Employee === "Select Employee"
      ) {
        Emp_id = 0;
      } else {
        var empid = getEmployee.find((item) => item.name === Employee);
        Emp_id = empid.employee_id;
      }
      updatedErrors[24] = "";

      if (Store === "select" || Store === "Select Store") {
        updatedErrors[25] = "Please select User Default Store!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[25] = "";

      var z = null;
      if (ProductImage === null || ProductImage === "") {
        z = profile1;
      } else {
        if (ProductImage1) {
          const base64Image = ProductImage;
          const byteCharacters = atob(base64Image);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          z = blob;
          //z = ProductImage;
        } else {
          const options = {
            maxSizeMB: 0.5, // Maximum file size in MB
            maxWidthOrHeight: 800, // Maximum width or height of the image
            useWebWorker: true, // Use web worker for faster compression
          };
          z = await imageCompression(ProductImage, options);
        }
      }

      const Role_id = getRole.find((item) => item.name === Role);
      const Store_id = getStore.find((item) => item.name === Store);

      var perm_ids = [];
      SelectAll.forEach((element) => {
        if (element.checked) {
          perm_ids.push(element.value);
        }
      });
      var Perm = { permissions: perm_ids };

      const response = await EditUserApi(
        user_id_id,
        UserName,
        Pass,
        Role_id.role_id,
        Notes,
        Emp_id,
        Store_id.store_id,
        OrgName,
        z,
        Perm,
        userID,
        userToken
      );
      resp = response.status;
      if (resp === 200) {
        alert("User updated successfully.");
      } else {
        alert("User failed to update.");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadBtn(false);
      if (resp === 200) {
        navigate("/Users");
      }
    }
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (user_id_id && userID && userToken) {
        GetAllEmpNameNoUsers(user_id_id, userID, userToken)
          .then((resp) => {
            setGetEmployee(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        GetUserPermissions(user_id_id, userID, userToken)
          .then((resp) => {
            const groupedItems = resp.data.reduce(
              (acc, item) => {
                if (acc[acc.length - 1].length >= 4) {
                  return [...acc, [item]];
                }
                acc[acc.length - 1].push(item);
                return acc;
              },
              [[]]
            );
            setGetPerm(groupedItems || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        GetUserById(user_id_id, userID, userToken)
          .then((result) => {
            setUserName(result.data[0].username);
            setUserName1(result.data[0].username);
            setPass(result.data[0].password);
            setOrgName(result.data[0].org_name);
            setProductImage(result.data[0].profile);
            setProductImage1(result.data[0].profile);
            setUploadedImage(result.data[0].profile);
            setNotes(result.data[0].role_desc || "");
            setRole(result.data[0].roles);
            setEmployee(result.data[0].employee);
            setStore(result.data[0].store);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [user_id_id, userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken) {
        GetAllUserRoles(userID, userToken)
          .then((resp) => {
            setGetRole(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        GetAllStores(userID, userToken)
          .then((resp) => {
            setGetStore(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="EDIT USER" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row xs={1} sm={1} style={{ padding: "0" }}>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Username:</label>
                  <input
                    className="input"
                    required
                    type="text"
                    onChange={handleChangeUserName}
                    value={UserName}
                    name="username"
                    placeholder="Username"
                    autoFocus
                    onBlur={(e) => validName1(e.target.value, 0)}
                  />
                  <span className="label-imp">*</span>
                  {ValError[0] && <p className="label-error">{ValError[0]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Password:</label>
                  <input
                    className="input"
                    required
                    type="password"
                    onChange={handleChangePass}
                    value={Pass}
                    name="pass"
                    placeholder="Password"
                    onBlur={(e) => validName1(e.target.value, 1)}
                  />
                  <span className="label-imp">*</span>
                  {ValError[1] && <p className="label-error">{ValError[1]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="RoleSelect">
                    Role:
                  </label>
                  <select
                    className="select container-select"
                    id="RoleSelect"
                    value={Role}
                    onChange={handleChangeRole}
                  >
                    <option defaultValue="-1">Select Role</option>
                    {getRole.map((item) => (
                      <option key={item.role_id}>{item.name}</option>
                    ))}
                  </select>
                  <span className="label-imp">*</span>
                  {ValError[23] && (
                    <p className="label-error">{ValError[23]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="StoreSelect">
                    Default Store:
                  </label>
                  <select
                    className="select container-select"
                    id="StoreSelect"
                    value={Store}
                    onChange={handleChangeStore}
                  >
                    <option defaultValue="-1">Select Store</option>
                    {getStore.map((item) => (
                      <option key={item.store_id}>{item.name}</option>
                    ))}
                  </select>
                  <span className="label-imp">*</span>
                  {ValError[25] && (
                    <p className="label-error">{ValError[25]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Report Org Name:</label>
                  <input
                    className="input"
                    required
                    type="text"
                    onChange={handleChangeOrgName}
                    value={OrgName}
                    name="org_name"
                    placeholder="Report Org Name"
                    maxLength={50}
                    onBlur={(e) => validName1(e.target.value, 4)}
                  />
                  <span className="label-imp">*</span>
                  {ValError[4] && <p className="label-error">{ValError[4]}</p>}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="EmployeeSelect">
                    Employee:
                  </label>
                  <select
                    className="select container-select"
                    id="EmployeeSelect"
                    value={Employee}
                    onChange={handleChangeEmployee}
                  >
                    <option defaultValue="-1">Select Employee</option>
                    {getEmployee.map((item) => (
                      <option key={item.employee_id}>{item.name}</option>
                    ))}
                  </select>
                  {ValError[24] && (
                    <p className="label-error">{ValError[24]}</p>
                  )}
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Note: </label>
                  <textarea
                    placeholder="User Note "
                    id="noteTextarea"
                    value={Notes || ""}
                    onBlur={(e) => ValidText1(e.target.value, 8)}
                    className="textarea"
                    onChange={handleChangeNotes}
                  />
                  {ValError[8] && <p className="label-error">{ValError[8]}</p>}
                </div>
              </div>
              <br />
              <div className="mt-0 col-lg-12">
                <div className="form-group">
                  <label className="label">Profile:</label>
                  {ProductImage1 ? (
                    <div className="container-video-div">
                      <img
                        src={`data:image/jpeg;base64,${ProductImage1}`}
                        className="container-image"
                        alt="User"
                      />
                      <div className="crs-btn pl-2">
                        <button type="button" onClick={handleClickRemoveImage}>
                          ❌
                        </button>
                      </div>
                    </div>
                  ) : uploadedImage ? (
                    <div className="container-video-div">
                      <img
                        src={uploadedImage}
                        className="container-image"
                        alt="User"
                      />
                      <div className="crs-btn pl-2">
                        <button type="button" onClick={handleClickRemoveImage}>
                          ❌
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="container-video-div"
                      style={{ width: "80%" }}
                    >
                      <img
                        src={default_img}
                        className="container-image"
                        alt="User"
                      />
                    </div>
                  )}
                  <div className="mt-1 product-image-input">
                    <input
                      ref={fileInputRef}
                      className="flex justify-left"
                      type="file"
                      id="imageInput"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label mb-2">Permissions: </label>
                  {getPerm.map((items, index) => (
                    <Row key={index}>
                      {items.map((item, i) => (
                        <Col key={i} md={3} style={{ paddingRight: "0px" }}>
                          <label
                            className="label-checkbox"
                            style={{ fontSize: "13px", width: "100%" }}
                          >
                            <input
                              className="checkbox"
                              name="SelectAll"
                              type="checkbox"
                              value={item.permission_id}
                              defaultChecked={item.checker}
                            />
                            {` ${item.name}`}
                          </label>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </div>
              </div>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button mr-2"
          bgColor={currentColor}
          text="Update"
          borderRadius="10px"
          onClick={handleSubmit}
          disabled={loadBtn}
        />
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button ml-2"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default EditUser;
