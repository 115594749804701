import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddUserApi,
  CheckUserNameExist,
  GetAllEmpNameNoUsers,
  GetAllStores,
  GetAllUserPermissions,
  GetAllUserRoles,
} from "../../api";
import { Header, Button } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import default_img from "../../data/default_img.jpg";
import "../../styles/AddProduct.css";
import { Container, Col, Row } from "react-bootstrap";
import { validateName, ValidText } from "../../contexts/Utils";
import imageCompression from "browser-image-compression";

const AddUser = () => {
  const { currentColor, userID, userToken } = useStateContext();
  const [note, setNote] = useState("");
  const [getRole, setGetRole] = useState([]);
  const [Role, setRole] = useState("select");
  const [getEmployee, setGetEmployee] = useState([]);
  const [Employee, setEmployee] = useState("select");
  const [getStore, setGetStore] = useState([]);
  const [Store, setStore] = useState("select");
  const [getPerm, setGetPerm] = useState([]);
  const navigate = useNavigate();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loadBtn, setLoadBtn] = useState(false);

  const [profile, setprofile] = useState(
    //dataURLtoFile(default_img, "default_img.jpg")
    null
  );

  const [ValError, setValError] = useState([]);

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      // console.log("Back");
      navigate("/Users");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validName1 = (name, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (name.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (validateName(name)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const ValidText1 = (txt, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (txt.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (ValidText(txt)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const handleImageChange = (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 4194304) {
          alert("Image File max size is 4 MB!");
          e.target.value = null;
          setUploadedImage(null);
          setprofile(null);
          return;
        } else {
          setprofile(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setUploadedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    } catch (err) {
      // return false;
    }
  };

  const handleChangeRole = (e) => {
    setRole(e.target.value);
    if (e.target.value !== "select" && e.target.value !== "Select Role") {
      const updatedErrors = [...ValError];
      updatedErrors[23] = "";
      setValError(updatedErrors);
    } else {
      const updatedErrors = [...ValError];
      updatedErrors[23] = "Please select User Role!";
      setValError(updatedErrors);
    }
  };

  const handleChangeEmployee = (e) => {
    setEmployee(e.target.value);
    if (e.target.value !== "select" && e.target.value !== "Select Employee") {
      const updatedErrors = [...ValError];
      updatedErrors[24] = "";
      setValError(updatedErrors);
    } else {
      // const updatedErrors = [...ValError];
      // updatedErrors[24] = "Please select User Employee Name!";
      // setValError(updatedErrors);
    }
  };

  const handleChangeStore = (e) => {
    setStore(e.target.value);
    if (e.target.value !== "select" && e.target.value !== "Select Store") {
      const updatedErrors = [...ValError];
      updatedErrors[25] = "";
      setValError(updatedErrors);
    } else {
      const updatedErrors = [...ValError];
      updatedErrors[25] = "Please select User Default Store!";
      setValError(updatedErrors);
    }
  };

  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    TimeoutUtility.resetTimeout();
    setLoadBtn(true);
    var resp = 0;
    try {
      const { username, pass, org_name, SelectAll } = document.forms[0];
      //console.log(username.value, pass.value);

      var Emp_id = null;

      setValError([]);
      const updatedErrors = [...ValError];

      if (username.value === "") {
        updatedErrors[0] = "Please enter Username!";
        setValError(updatedErrors);
        return;
      }
      if (username.value !== "") {
        if (validName1(username.value, 0) === false) {
          return;
        }
      }
      var exi = 0;
      await CheckUserNameExist(username.value, userID, userToken)
        .then((resp) => {
          exi = resp.data[0].name;
        })
        .catch((err) => {
          console.log(err.message);
        });
      if (exi === 1) {
        updatedErrors[0] = "User name must be unique!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[0] = "";

      if (pass.value === "") {
        updatedErrors[1] = "Please enter Password!";
        setValError(updatedErrors);
        return;
      }
      if (pass.value.length < 8) {
        updatedErrors[1] = "Please enter atleast 8 characters!";
        setValError(updatedErrors);
        return;
      }
      if (pass.value === pass.value.toLowerCase()) {
        updatedErrors[1] =
          "Please enter atleast one Uppercase character [A-Z]!";
        setValError(updatedErrors);
        return;
      }
      if (pass.value === pass.value.toUpperCase()) {
        updatedErrors[1] = "Please enter atleast one Lower character [a-z]!";
        setValError(updatedErrors);
        return;
      }
      if (!/\d/.test(pass.value)) {
        updatedErrors[1] = "Please enter atleast one decimal character [0-9]!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[1] = "";

      if (Role === "select" || Role === "Select Role") {
        updatedErrors[23] = "Please select User Role!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[23] = "";

      if (org_name.value === "") {
        updatedErrors[4] = "Please enter Report Org Name!";
        setValError(updatedErrors);
        return;
      }
      if (username.value !== "") {
        if (validName1(username.value, 4) === false) {
          return;
        }
      }
      updatedErrors[4] = "";

      if (Employee === "select" || Employee === "Select Employee") {
        Emp_id = null;
      } else {
        var empid = getEmployee.find((item) => item.name === Employee);
        Emp_id = empid.employee_id;
      }
      updatedErrors[24] = "";

      if (Store === "select" || Store === "Select Store") {
        updatedErrors[25] = "Please select User Default Store!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[25] = "";

      var prof = null;
      if (profile) {
        const options = {
          maxSizeMB: 0.5, // Maximum file size in MB
          maxWidthOrHeight: 800, // Maximum width or height of the image
          useWebWorker: true, // Use web worker for faster compression
        };
        prof = await imageCompression(profile, options);
      }

      const Role_id = getRole.find((item) => item.name === Role);
      const Store_id = getStore.find((item) => item.name === Store);

      var perm_ids = [];
      SelectAll.forEach((element) => {
        if (element.checked) {
          perm_ids.push(element.value);
        }
      });
      var Perm = { permissions: perm_ids };
      //console.log(Perm);
      const response = await AddUserApi(
        username.value,
        pass.value,
        Role_id.role_id,
        note,
        Emp_id,
        Store_id.store_id,
        org_name.value,
        prof,
        Perm,
        userID,
        userToken
      );
      //console.log(response, "Response");
      resp = response.status;
      if (resp === 200) {
        alert("User added successfully.");
      } else {
        alert("User failed to add!");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadBtn(false);
      if (resp === 200) {
        navigate("/Users");
      }
    }
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken) {
        GetAllUserRoles(userID, userToken)
          .then((resp) => {
            setGetRole(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        GetAllEmpNameNoUsers(null, userID, userToken)
          .then((resp) => {
            setGetEmployee(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        GetAllStores(userID, userToken)
          .then((resp) => {
            setGetStore(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
        GetAllUserPermissions(userID, userToken)
          .then((resp) => {
            const groupedItems = resp.data.reduce(
              (acc, item) => {
                if (acc[acc.length - 1].length >= 4) {
                  return [...acc, [item]];
                }
                acc[acc.length - 1].push(item);
                return acc;
              },
              [[]]
            );
            setGetPerm(groupedItems || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header title="ADD USER" />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row xs={1} sm={1} style={{ padding: "0" }}>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Username:</label>
                  <div>
                    <input
                      className="input"
                      required
                      type="text"
                      name="username"
                      placeholder="Username"
                      autoFocus
                      onBlur={(e) => validName1(e.target.value, 0)}
                    />
                    <span className="label-imp">*</span>
                    {ValError[0] && (
                      <p className="label-error">{ValError[0]}</p>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Password:</label>
                  <div>
                    <input
                      className="input"
                      required
                      type="password"
                      name="pass"
                      placeholder="Password"
                      onBlur={(e) => validName1(e.target.value, 1)}
                    />
                    <span className="label-imp">*</span>
                    {ValError[1] && (
                      <p className="label-error">{ValError[1]}</p>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="RoleSelect">
                    Role:
                  </label>
                  <select
                    className="select container-select"
                    id="RoleSelect"
                    value={Role}
                    onChange={handleChangeRole}
                  >
                    <option defaultValue="-1">Select Role</option>
                    {getRole.map((item) => (
                      <option key={item.role_id}>{item.name}</option>
                    ))}
                  </select>
                  <span className="label-imp">*</span>
                  {ValError[23] && (
                    <p className="label-error">{ValError[23]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="StoreSelect">
                    Default Store:
                  </label>
                  <select
                    className="select container-select"
                    id="StoreSelect"
                    value={Store}
                    onChange={handleChangeStore}
                  >
                    <option defaultValue="-1">Select Store</option>
                    {getStore.map((item) => (
                      <option key={item.store_id}>{item.name}</option>
                    ))}
                  </select>
                  <span className="label-imp">*</span>
                  {ValError[25] && (
                    <p className="label-error">{ValError[25]}</p>
                  )}
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Report Org Name:</label>
                  <div>
                    <input
                      className="input"
                      required
                      type="text"
                      name="org_name"
                      placeholder="Report Org Name"
                      maxLength={50}
                      onBlur={(e) => validName1(e.target.value, 4)}
                    />
                    <span className="label-imp">*</span>
                    {ValError[4] && (
                      <p className="label-error">{ValError[4]}</p>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label" htmlFor="EmployeeSelect">
                    Employee:
                  </label>
                  <select
                    className="select container-select"
                    id="EmployeeSelect"
                    value={Employee}
                    onChange={handleChangeEmployee}
                  >
                    <option defaultValue="-1">Select Employee</option>
                    {getEmployee.map((item) => (
                      <option key={item.employee_id}>{item.name}</option>
                    ))}
                  </select>
                  {/* <span className="label-imp">*</span> */}
                  {ValError[24] && (
                    <p className="label-error">{ValError[24]}</p>
                  )}
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={3} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Role Description: </label>
                  <textarea
                    placeholder="Role Description"
                    id="noteTextarea"
                    value={note}
                    onChange={handleChangeNote}
                    className="textarea"
                    onBlur={(e) => ValidText1(e.target.value, 8)}
                  />
                  {ValError[8] && <p className="label-error">{ValError[8]}</p>}
                </div>
              </div>
              <br />
              <div className="mt-0 col-lg-12">
                <div className="form-group">
                  <label className="label">Profile:</label>
                  <div className="container-video-div" style={{ width: "80%" }}>
                    {profile ? (
                      <img
                        src={uploadedImage}
                        className="container-image"
                        alt="User"
                      />
                    ) : (
                      <img
                        src={default_img}
                        className="container-image"
                        alt="User"
                      />
                    )}
                  </div>
                  <div className="mt-1 product-image-input">
                    <input
                      className="flex justify-left"
                      type="file"
                      id="imageInput"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={6} className="container-col">
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label mb-2">Permissions: </label>
                  {getPerm.map((items, index) => (
                    <Row key={index}>
                      {items.map((item, i) => (
                        <Col key={i} md={3} style={{ paddingRight: "0px" }}>
                          <label
                            className="label-checkbox"
                            style={{ fontSize: "13px", width: "100%" }}
                          >
                            <input
                              className="checkbox"
                              name="SelectAll"
                              type="checkbox"
                              value={item.permission_id}
                            />
                            {` ${item.name}`}
                          </label>
                        </Col>
                      ))}
                    </Row>
                  ))}
                </div>
              </div>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button"
          bgColor={currentColor}
          text="Add"
          borderRadius="10px"
          onClick={handleSubmit}
          disabled={loadBtn}
        />
        <Button
          margin="10px"
          padding="20px"
          color="white"
          className="custom-button"
          bgColor={currentColor}
          text="Back"
          borderRadius="10px"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default AddUser;
