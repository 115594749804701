import React, { useEffect, useState } from "react";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { useNavigate } from "react-router-dom";
import hello from "../../data/default_img.jpg";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  GetAllUsers,
  //CheckUserDeleteStatus,
  //DeleteUser
} from "../../api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../LoadingIndicator";

const Users = () => {
  const [AllUsers, setAllUsers] = useState("");
  const [User_id, setUser_id] = useState("");
  // const [User, setUser] = useState("");
  const { currentColor, userPermID, userID, userToken } = useStateContext();
  // const [acc_Status, setacc_Status] = useState(1);
  const { promiseInProgress } = usePromiseTracker();
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const userGridImage = (props) => (
    <div className="image flex gap-4">
      {props.profile === null ? (
        <div>
          <img
            className="rounded-xl w-16 h-16"
            src={hello}
            alt="user"
            width={72}
          />
        </div>
      ) : (
        <img
          className="rounded-xl h-16"
          src={`data:image/jpeg;base64,${props.profile}`}
          alt="user"
          width={72}
        />
      )}
    </div>
  );

  const usersGrid = [
    {
      headerText: "",
      minWidth: "100",
      width: "100",
      maxWidth: "120",
      template: userGridImage,
      textAlign: "left",
    },
    {
      field: "username",
      headerText: "Username",
      minWidth: "180",
      width: "260",
      maxWidth: "340",
      textAlign: "left",
    },
    {
      field: "roles",
      headerText: "Role",
      minWidth: "140",
      width: "180",
      maxWidth: "200",
      textAlign: "left",
    },
    {
      field: "role_desc",
      headerText: "Role Description",
      minWidth: "200",
      width: "360",
      maxWidth: "560",
      textAlign: "left",
    },
    {
      field: "employee",
      headerText: "Employee Name",
      minWidth: "200",
      width: "240",
      maxWidth: "320",
      textAlign: "left",
    },
    {
      field: "act_datetime",
      headerText: "Last Activity DateTime",
      minWidth: "240",
      width: "250",
      maxWidth: "280",
      textAlign: "center",
    },
  ];

  // const handleAddUserClick = async (event) => {
  //   event.preventDefault();
  //   try {
  //     //console.log("Add new");
  //     navigate(`/Users/AddUser`);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleEditUserClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      if (User_id === 100) {
        alert("This User can not be edited!");
      } else if (User_id !== "") {
        navigate(`/Users/EditUser/${User_id}`);
      } else {
        alert("Please select user to edit.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewUserClick = async (event) => {
    try {
      TimeoutUtility.resetTimeout();
      //console.log("view store");
      if (User_id !== "") {
        navigate(`/Users/ViewUser/${User_id}`);
      } else {
        alert("Please select user to view.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewActsClick = async (event) => {
    try {
      TimeoutUtility.resetTimeout();
      //console.log("view store");
      navigate(`/Users/UserActivities`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleViewEmployeesClick1 = async (event) => {
  //   event.preventDefault();
  //   TimeoutUtility.resetTimeout();
  //   try {
  //     //console.log("delete user");
  //     if (User_id !== "" && userID && userToken) {
  //       // if (defaultacc === 0) {
  //       if (acc_Status === 0) {
  //         if (window.confirm(`Are you sure you want to Delete ${User}?`)) {
  //           const response = await DeleteUser(User_id, userID, userToken);
  //           //console.log(response, "Response");
  //           if (response.status === 200) {
  //             window.location.reload();
  //             alert("User deleted successfully.");
  //           } else {
  //             alert("User failed to delete.");
  //           }
  //         }
  //         //console.log(acc_Status);
  //       } else {
  //         alert(`${User} activity exist.\nIt could not be deleted.`);
  //       }
  //       // } else {
  //       //   alert(`A${Account_id} is Defualt account.\nIt could not be deleted.`);
  //       // }
  //     } else {
  //       alert("Please select user to delete.");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleRowSelected = (args) => {
    TimeoutUtility.resetTimeout();
    const selectedRowData = args.data;
    // const resp1 = CheckUserDeleteStatus(
    //   selectedRowData.user_id,
    //   userID,
    //   userToken
    // );
    // resp1
    //   .then(function (result) {
    //     setacc_Status(result.data[0].status);
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //   });
    setUser_id(selectedRowData.user_id);
    //setUser(selectedRowData.username);
  };

  useEffect(() => {
    async function fetchData() {
      TimeoutUtility.resetTimeout();
      if (userID && userToken) {
        //console.log(store_id);
        //if (storeG !== null && storeG.store_id !== 0) {
        trackPromise(
          Promise.all([GetAllUsers(userID, userToken)])
            .then(([result]) => {
              setAllUsers(result.data || []);
              //console.log(result.data);
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => {
              setloading(false);
            })
        );
        //}
      }
    }
    fetchData();
  }, [userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Admin" title="USERS" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {/* {userPermID.includes(5044) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddUserClick}
              />
            </Col>
          )} */}
          {userPermID.includes(5045) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Update"
                  borderRadius="10px"
                  onClick={handleEditUserClick}
                />
              </Col>
              {/* <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Delete"
                  borderRadius="10px"
                  onClick={handleViewEmployeesClick1}
                />
              </Col> */}
            </>
          )}
          {userPermID.includes(5043) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="View Activities"
                borderRadius="10px"
                onClick={handleViewActsClick}
              />
            </Col>
          )}
        </Row>
      </Container>
      {loading || promiseInProgress ? (
        <LoadingIndicator />
      ) : (
        <>
          <GridComponent
            className="custom-grid"
            recordDoubleClick={userPermID.includes(5043) && handleViewUserClick}
            dataSource={AllUsers}
            allowPaging={true}
            pageSettings={{ pageSize: 8 }}
            allowSorting
            allowResizing
            toolbar={["Search"]}
            rowSelected={handleRowSelected}
            rowHeight={72}
          >
            <ColumnsDirective>
              {usersGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject
              services={[Resize, Page, Toolbar, Selection, Edit, Sort, Filter]}
            />
          </GridComponent>
        </>
      )}
    </div>
  );
};

export default Users;
