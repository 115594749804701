import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GetAllUnits,
  DeleteUnitById,
  AddUnitApi,
  EditUnitApi,
  GetUnitById,
} from "../../api";
import Select from "react-select";
import { Header, Button } from "../../components";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import "../../styles/AddProduct.css";
import { validateName } from "../../contexts/Utils";

const AddEditUnit = () => {
  const navigate = useNavigate();
  const { currentColor, userID, userToken } = useStateContext();
  const [unit, setUnit] = useState("");
  const [getunits, setUnits] = useState([]);
  const [unitOptions, setunitOptions] = useState([]);
  const [unit_id, setunit_id] = useState("");
  const [name, setName] = useState("");
  const [ValError, setValError] = useState([]);
  const [mode, setMode] = useState("Add");

  const validName1 = (name, ii) => {
    try {
      const updatedErrors = [...ValError];
      if (name.trim().length === 0) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return false;
      }
      if (validateName(name)) {
        updatedErrors[ii] = "";
        setValError(updatedErrors);
        return true;
      }
      updatedErrors[ii] = "Invalid field!";
      setValError(updatedErrors);
      return false;
    } catch (err) {
      return false;
    }
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleAddMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Add");
      setUnit("");
      setunit_id("");
      setName("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Edit");
      setUnit("");
      setunit_id("");
      setName("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteMode = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      setMode("Delete");
      setUnit("");
      setunit_id("");
      setName("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBackClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      // console.log("Back");
      navigate("/Product/AddProduct", {
        state: { fromPage: "Unit" },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();

    try {
      setValError([]);
      const updatedErrors = [...ValError];

      if (
        (mode === "Edit" || mode === "Delete") &&
        (!unit_id || unit_id === "")
      ) {
        updatedErrors[0] = "Select a Unit!";
        setValError(updatedErrors);
        return;
      }
      updatedErrors[0] = "";

      if (name === "") {
        updatedErrors[1] = "Please enter name!";
        setValError(updatedErrors);
        return;
      }
      if (name) {
        if (validName1(name, 1) === false) {
          return;
        }
      }
      updatedErrors[1] = "";

      if (mode === "Add") {
        if (window.confirm("Do you want to add Unit?")) {
          AddUnitApi(name, userID, userToken)
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditUnit", {
                  state: { fromPage: "Unit" },
                });
                alert("Added successfully.");
                window.location.reload();
              } else {
                alert("Fail to add Unit!");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      } else if (mode === "Edit") {
        if (window.confirm("Do you want to edit this Unit?")) {
          EditUnitApi(unit_id, name, userID, userToken)
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditUnit", {
                  state: { fromPage: "Unit" },
                });
                alert("Updated successfully.");
                window.location.reload();
              } else {
                alert("Fail to edit Unit!");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      } else if (mode === "Delete") {
        if (window.confirm(`Do you want to remove ${unit.label} Unit?`)) {
          DeleteUnitById(unit_id, userID, userToken)
            .then((res) => {
              if (res.status === 200) {
                navigate("/Product/AddEditUnit", {
                  state: { fromPage: "Unit" },
                });
                alert("Removed successfully.");
                window.location.reload();
              } else {
                alert("Fail to remove Unit!");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChangeUnit = (selectedOption) => {
    try {
      if (selectedOption && selectedOption.value) {
        setUnit(selectedOption);
        const selected_id = selectedOption.value;
        setunit_id(selected_id);

        const resp1 = GetUnitById(selected_id, userID, userToken);
        resp1
          .then(function (result) {
            setName(result.data[0].name);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        setUnit(selectedOption.label);
        setunit_id("");
      }
    } catch (err) {}
  };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    async function fetchData() {
      if (userID && userToken) {
        GetAllUnits(userID, userToken)
          .then((resp) => {
            setUnits(resp.data || []);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }
    fetchData();
  }, [userID, userToken]);

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    const fetchStoreOptions = async () => {
      const fetchedStoreOptions = getunits.map((item) => ({
        label: `${item.name}`,
        value: item.unit_id,
      }));
      setunitOptions(fetchedStoreOptions);
    };

    fetchStoreOptions();
  }, [getunits]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header
        title={
          mode === "Add"
            ? "ADD UNIT"
            : mode === "Edit"
            ? "EDIT UNIT"
            : "DELETE UNIT"
        }
      />
      <form>
        <Container
          className="g-0 justify-center"
          fluid="true"
          style={{ paddingLeft: "8%", paddingRight: "8%", paddingTop: "18px" }}
        >
          <Row
            xs={1}
            sm={1}
            className="justify-content-center"
            style={{ padding: "0" }}
          >
            <Col md={2} className="container-col" />
            <Col md={4} className="container-col">
              {(mode === "Edit" || mode === "Delete") && (
                <>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="label">Unit List:</label>
                      <Select
                        className="myreact-select container-select"
                        value={unit}
                        onChange={handleChangeUnit}
                        options={unitOptions}
                        isSearchable
                        placeholder="Select a Unit"
                      />
                      {ValError[0] && (
                        <p className="label-error-select">{ValError[0]}</p>
                      )}
                    </div>
                  </div>
                  <br />
                  <br />
                </>
              )}
              <div className="col-lg-12">
                <div className="form-group">
                  <label className="label">Name: </label>
                  <input
                    type="text"
                    className="input"
                    name="unit_name"
                    value={name}
                    onChange={handleChangeName}
                    placeholder="Unit Name"
                    onBlur={(e) => validName1(e.target.value, 1)}
                    disabled={mode === "Delete"}
                  />
                  {ValError[1] && <p className="label-error">{ValError[1]}</p>}
                </div>
              </div>
              <br />
              <br />
            </Col>
            <Col md={4} className="container-col">
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Add"
                borderRadius="10px"
                onClick={handleAddMode}
              />
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Edit"
                borderRadius="10px"
                onClick={handleEditMode}
              />
              <Button
                color="white"
                margin="10px"
                padding="20px"
                bgColor={currentColor}
                text="Delete"
                borderRadius="10px"
                onClick={handleDeleteMode}
              />
            </Col>
          </Row>
        </Container>
      </form>
      <Row md={"auto"} className="justify-content-center">
        <Button
          color="white"
          margin="10px"
          padding="20px"
          bgColor={currentColor}
          text={
            mode === "Add" ? "ADD NEW" : mode === "Edit" ? "UPDATE" : "REMOVE"
          }
          borderRadius="10px"
          className="custom-button ml-2"
          onClick={handleAddSubmit}
        />
        <Button
          color="white"
          margin="10px"
          padding="20px"
          bgColor={currentColor}
          text="BACk"
          borderRadius="10px"
          className="custom-button ml-2"
          onClick={handleBackClick}
        />
      </Row>
    </div>
  );
};

export default AddEditUnit;
