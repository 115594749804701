import axios from "axios";

//const BASE_URL = "http://147.182.241.192:3001";
const BASE_URL = process.env.REACT_APP_API_URL;

export const GetInvStkReport = async (
  dateFrom,
  dateTo,
  store_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/inventoryStockReport`,
      { date_from: dateFrom, date_to: dateTo, store_id: store_id },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetInvLedReport = async (
  dateFrom,
  dateTo,
  product_id,
  store_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/inventoryLedgerReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        product_id: product_id,
        store_id: store_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetInvPrfReport = async (
  dateFrom,
  dateTo,
  product_id,
  store_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/inventoryProfitReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        product_id: product_id,
        store_id: store_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccLedgerReport = async (
  dateFrom,
  dateTo,
  store_id,
  account_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/accountLedgerReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        store_id: store_id,
        account_id: account_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccLedgerSummaryReport = async (
  dateFrom,
  dateTo,
  store_id,
  account_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/accountLedgerSummaryReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        store_id: store_id,
        account_id: account_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSummaryReport = async (
  dateFrom,
  dateTo,
  store_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/summaryReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        store_id: store_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleInvoice = async (store_id, so_id, type, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/saleInvoice`,
      {
        store_id: store_id,
        so_id: so_id,
        type: type,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleDetailReport = async (
  dateFrom,
  dateTo,
  store_id,
  product_id,
  customer_id,
  vendor_id,
  uuser_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/saleDetailReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        store_id: store_id,
        product_id: product_id,
        customer_id: customer_id,
        vendor_id: vendor_id,
        uuser_id: uuser_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetSaleSummaryReport = async (
  dateFrom,
  dateTo,
  store_id,
  product_id,
  customer_id,
  vendor_id,
  uuser_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/saleSummaryReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        store_id: store_id,
        product_id: product_id,
        customer_id: customer_id,
        vendor_id: vendor_id,
        uuser_id: uuser_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPurchaseDetailReport = async (
  dateFrom,
  dateTo,
  store_id,
  product_id,
  vendor_id,
  uuser_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/purchaseDetailReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        store_id: store_id,
        product_id: product_id,
        vendor_id: vendor_id,
        uuser_id: uuser_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetPurchaseSummaryReport = async (
  dateFrom,
  dateTo,
  store_id,
  product_id,
  vendor_id,
  uuser_id,
  user_id,
  token
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/purchaseSummaryReport`,
      {
        date_from: dateFrom,
        date_to: dateTo,
        store_id: store_id,
        product_id: product_id,
        vendor_id: vendor_id,
        uuser_id: uuser_id,
      },
      { responseType: "blob", headers: { user_id: user_id, token: token } } // Receive the response as a Blob (binary data)
    );
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetAccountListforRep = async (store_id, user_id, token) => {
  try {
    //console.log("GetAllAccounts");
    const response = await axios.post(
      `${BASE_URL}/report/getAccountListforRep`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const GetProductListforRep = async (store_id, user_id, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report/getProductListforRep`,
      {
        store_id: store_id,
      },
      {
        headers: { user_id: user_id, token: token },
      }
    );
    return await response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
