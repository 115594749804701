import { MdOutlineCancel } from "react-icons/md";
import { GetAllPaymentPO, GetAllPaymentSO } from "../api";
import "../styles/ViewOrderProd.css";
import { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";

const ViewOrderPayments = ({}) => {
  const [payments, setPayments] = useState([]);
  const [orderId, setOrderId] = useState("");
  const {
    activePayment,
    setActivePayment,
    activePaymentId,
    setActivePaymentId,
    userID,
    userToken,
  } = useStateContext();

  useEffect(() => {
    async function fetchData() {
      if (activePayment && userID && userToken) {
        if (activePaymentId.so_id) {
          await GetAllPaymentSO(activePaymentId.so_id, userID, userToken)
            .then((resp) => {
              setPayments(resp.data || []);
              setOrderId("S" + activePaymentId.so_id);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else if (activePaymentId.po_id) {
          await GetAllPaymentPO(activePaymentId.po_id, userID, userToken)
            .then((resp) => {
              setPayments(resp.data || []);
              setOrderId("P" + activePaymentId.po_id);
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      }
    }
    fetchData();
  }, [activePayment, activePaymentId, userID, userToken]);

  const formatCurrency = (number) => {
    return number.toLocaleString("en-PK", {
      style: "currency",
      currency: "PKR",
      minimumFractionDigits: 2,
    });
  };

  return (
    <div className="h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activePayment && (
        <aside>
          <div style={{ paddingTop: "20px" }}>
            <MdOutlineCancel
              style={{ cursor: "pointer" }}
              color="black"
              size={50}
              onClick={() => {
                setActivePayment(false);
                setActivePaymentId({ so_id: null, po_id: null });
              }}
              className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
            />
          </div>
          <div>
            <label
              style={{
                fontSize: "21px",
                width: "100%",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {orderId} Payments
            </label>
            <hr className="line" />
            {payments.length === 0 ? (
              <label
                style={{
                  fontSize: "20px",
                  width: "100%",
                  fontWeight: "lighter",
                  textAlign: "center",
                }}
              >
                No Payment Found!
              </label>
            ) : (
              <>
                {payments?.map((item, index) => (
                  <>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        paddingLeft: "10%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "19px",
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >{`${item.id}:`}</span>
                      <br />
                      <span
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >{`${item.datetime}`}</span>
                      <br />
                      <br />
                      <span
                        style={{
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >{`From: `}</span>
                      <span
                        style={{
                          fontSize: "18px",
                          textAlign: "left",
                          fontWeight: "lighter",
                        }}
                      >{`${item.From}`}</span>
                      <br />
                      <span
                        style={{
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >{`To: `}</span>
                      <span
                        style={{
                          fontSize: "18px",
                          textAlign: "left",
                          fontWeight: "lighter",
                        }}
                      >{`${item.To}`}</span>
                      <br />
                      <span
                        style={{
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >{`Amount: `}</span>
                      <span
                        style={{
                          fontSize: "18px",
                          textAlign: "left",
                          fontWeight: "lighter",
                        }}
                      >{`${formatCurrency(item.amount_paid)}`}</span>
                      <br />
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                      >{`Note: `}</span>
                      <span
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                          fontWeight: "lighter",
                        }}
                      >{`${item.note}`}</span>
                    </div>
                    <hr className="line" />
                  </>
                ))}
              </>
            )}
          </div>
        </aside>
      )}
    </div>
  );
};

export default ViewOrderPayments;
