import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Toolbar,
  Sort,
  Filter,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { GetAllProductsInv } from "../../api";
import { Header, Button } from "../../components";
import "../../styles/viewCustomer.css";
import { useStateContext } from "../../contexts/ContextProvider";
import { Col, Container, Row } from "react-bootstrap";

const Inventory = () => {
  const navigate = useNavigate();
  const { currentColor, userPermID, userID, userToken } = useStateContext();
  const [AllInventory, setAllInventory] = useState("");
  const [p_id, setp_id] = useState("");

  const InventoryGrid = [
    {
      field: "code",
      headerText: "Product No.",
      minWidth: "155",
      width: "160",
      maxWidth: "180",
      textAlign: "left",
    },
    {
      field: "name",
      headerText: "Product Name",
      minWidth: "180",
      width: "420",
      maxWidth: "780",
      textAlign: "left",
    },
    {
      field: "unit_instock",
      headerText: "Total Stk Qty",
      minWidth: "125",
      width: "130",
      maxWidth: "140",
      textAlign: "right",
    },
    {
      field: "unit",
      headerText: "Unit",
      minWidth: "100",
      width: "100",
      maxWidth: "180",
      textAlign: "left",
    },
    // {
    //   field: "opening_balance",
    //   // template: customerGridImage1,
    //   headerText: "Open Bal",
    //   minWidth: "140",
    //   width: "140",
    //   maxWidth: "160",
    //   textAlign: "right",
    // },
  ];

  const handleRowSelected = (args) => {
    const selectedRowData = args.data;
    setp_id(selectedRowData.product_id);
    TimeoutUtility.resetTimeout();
    //console.log(selectedRowData.product_id);
    // console.log('Selected Product Code:', productcode);
  };

  const handleAddInvClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("Add new");
      navigate("/Inventory/Add");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInStockInvClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("Add new");
      navigate("/Inventory/InStock");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOutStockInvClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("Add new");
      navigate("/Inventory/OutStock");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleStkRepClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      navigate(`/Inventory/InventoryStockReport`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLedgRepClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      navigate(`/Inventory/InventoryLedgerReport`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleProfRepClick = async (event) => {
    event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      navigate(`/Inventory/ItemProfitLossReport`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleProdDetailClick = async (event) => {
    // event.preventDefault();
    TimeoutUtility.resetTimeout();
    try {
      //console.log("View Inventory");
      if (p_id !== "") {
        navigate(`/Inventory/ViewInventory/${p_id}`);
      } else {
        alert("Please select product to view.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleProdsClick = async (event) => {
  //   event.preventDefault();
  //   try {
  //     //console.log("Products");
  //     navigate(`/Inventory/Product`);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  useEffect(() => {
    TimeoutUtility.resetTimeout();
    if (userID && userToken) {
      const resp = GetAllProductsInv(userID, userToken);
      resp
        .then(function (result) {
          setAllInventory(result.data);
          //console.log(result.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [userID, userToken]);

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      <Header category="Stock" title="INVENTORY" />
      <Container fluid className="g-0 p-0 justify-end">
        <Row xs={2} className="button-row justify-content-end font-normal">
          {userPermID.includes(5005) && (
            <Col md="auto" style={{ padding: "0" }}>
              <Button
                margin="6px"
                color="white"
                bgColor={currentColor}
                text="Add Prod to Inv"
                borderRadius="10px"
                onClick={handleAddInvClick}
              />
            </Col>
          )}
          {userPermID.includes(5006) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="In-Stock"
                  borderRadius="10px"
                  onClick={handleInStockInvClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Out-Stock"
                  borderRadius="10px"
                  onClick={handleOutStockInvClick}
                />
              </Col>
            </>
          )}
          {userPermID.includes(5004) && (
            <>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Inv Stk Report"
                  borderRadius="10px"
                  onClick={handleStkRepClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Inv Ledger Report"
                  borderRadius="10px"
                  onClick={handleLedgRepClick}
                />
              </Col>
              <Col md="auto" style={{ padding: "0" }}>
                <Button
                  margin="6px"
                  color="white"
                  bgColor={currentColor}
                  text="Item Profit/Loss Rep"
                  borderRadius="10px"
                  onClick={handleProfRepClick}
                />
              </Col>
            </>
          )}
          {/* <Col md="auto" style={{ padding: "0" }}>
            <Button
              margin="6px"
              color="white"
              bgColor={currentColor}
              text="View"
              borderRadius="10px"
              onClick={handleProdDetailClick}
            />
          </Col> */}
          {/* <Button
          margin="6px"
          color="white"
          bgColor={currentColor}
          text="Products"
          borderRadius="10px"
          onClick={handleProdsClick}
        /> */}
        </Row>
      </Container>
      <GridComponent
        className="custom-grid"
        dataSource={AllInventory}
        allowPaging={true}
        pageSettings={{ pageSize: 16 }}
        allowSorting
        recordDoubleClick={userPermID.includes(5004) && handleProdDetailClick}
        allowResizing
        //allowTextWrap={true}
        toolbar={["Search"]}
        //width="auto"
        //height={680}
        rowSelected={handleRowSelected}
        rowHeight={36}
      >
        <ColumnsDirective>
          {InventoryGrid &&
            InventoryGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, Filter, Page, Toolbar, Selection]} />
      </GridComponent>
      {/* <button onClick={handleSubmit}>Submit</button> */}
    </div>
  );
};

export default Inventory;
