import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimeoutUtility from "../../contexts/TimeoutUtility";
import {
  //GetAllCustomersName,
  //GetProductNameCodeInv,
  GetProductByIdSale,
  //GetSaleCustomerById,
  GetSaleOrderDetailsByID,
  GetSaleOrderCustomerByID,
  DeleteEditSaleOrderProduct,
  EditEstimationApi,
  //RemoveProd_fromShipmentTrans,
  //GetProductByStoreID,
  //EditSaleOrderStatusBYSo_id,
  //EditSaleOrderStatus,
  getAllVendorsNameByPurchaseProduct,
  getProductFilterSubString,
  GetAccToIdBySaleOrder1,
} from "../../api";
import { Header } from "../../components";
import Select from "react-select";
import { useStateContext } from "../../contexts/ContextProvider";
import "../../styles/sale.css";
import { Card } from "react-bootstrap";
//import Sidebar from "../../components/ViewOrderProduct";
import "../../styles/viewCustomer.css";
import { Col, Container, Row } from "react-bootstrap";
import { openNewTab } from "../../contexts/SO_Invoice";

const EditEstimation = () => {
  const {
    currentColor,
    //activeProdMenu,
    setActiveProdMenu,
    setActiveProdMenuId,
    userID,
    userToken,
  } = useStateContext();
  //const navigate = useNavigate();
  //const [GetCustomer, setGetCustomer] = useState([]);
  //const [Customer, setCustomer] = useState("");
  //const [CustomerOptions, setCustomerOptions] = useState([]);
  //const [GetProduct, setGetProduct] = useState([]);
  const [product, setProducts] = useState("");
  const [qty, setqty] = useState(1.0);
  const [productOptions, setProductOptions] = useState([]);
  const [p_code, setp_code] = useState("");
  const [customer_ids, setcustomer_ids] = useState("");
  const [CardList, setcartList] = useState([]);
  const [total_amount, settotalAmount] = useState(0);
  const [tax, settax] = useState(0);
  const [totaldiscount, settotaldiscount] = useState(0.0);
  const [total_item, settotalitem] = useState(0);
  const [index1, setindex1] = useState("");
  //const [status_id, setstatus_id] = useState("");
  const [grandtotal, setgrandtotal] = useState(0);
  const [note, setnote] = useState("");
  //const [CustomerDetail, setCustomerDetail] = useState([]);
  //const [cusPhone, setcusPhone] = useState("");
  //const [trackingno, settrackingno] = useState("");
  const [projectname, setprojectname] = useState("");
  const [acc_from_bal, setacc_from_bal] = useState("");
  //const [shipmethod, setshipmethod] = useState("");
  const [ProductStr, setProductStr] = useState("");
  const [cusFlag, setcusFlag] = useState("");
  const [invoice_id, setinvoice_id] = useState("");
  const [projectname2, setprojectname2] = useState(0);
  const [deleteprodList, setdeleteprodList] = useState([]);
  // const [OrderStatus, setOrderStatus] = useState("");
  //const [orderstatuslable, setorderstatuslable] = useState("");
  //const [ValError, setValError] = useState([]);
  //const [unshippedList, setunshippedList] = useState([]);
  //const [qtyShipped, setqtyShipped] = useState([]);
  //const [projectname1, setprojectname1] = useState("");
  //const [productID, setproductID] = useState("");
  //const [toggle, setToggle] = useState(false);
  const [dot, setdot] = useState(0);
  const [isPageFrozen, setIsPageFrozen] = useState(false);
  //const [req_date, setreq_date] = useState();
  const [saveClick, setsaveClick] = useState(false);
  const [store_id_param, setstore_id_param] = useState("");
  const [print_flag, setprint_flag] = useState(false);

  let param = useParams();
  // console.log(param.so_ids)
  // const paramString = String(param.so_ids);
  const so_id_param = param.so_ids;
  // const [so_id_param, store_id_param] = paramString.split("_");

  const [formData] = useState({
    so_id: 0,
    est_sale: "E",
    customer_id: 0,
    project_name: "",
    total: 0,
    amount_paid: 0,
    amount_pending: 0,
    user_id: null,
    so_note: "",
    total_price: 0,
    discount: 0,
    tax: 0,
    shipment: 0,
    store_id: 0,
    invoice_id: 0,
    sale_products: [],
    shipments: [],
  });

  const [formData1, setFormData1] = useState({
    so_id: so_id_param,
    store_id: 0,
    invoice_id: 0,
    product_id: 0,
  });

  const keypadButtons = [
    "7",
    "8",
    "9",
    "4",
    "5",
    "6",
    "1",
    "2",
    "3",
    ".",
    "0",
    "00",
  ];

  const [activeInput, setActiveInput] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  //const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplier_ids, setsupplier_ids] = useState("");
  const [supplier_name, setsupplier_name] = useState("-");
  const [SupplierOptions, setSupplierOptions] = useState([]);
  const [GetSupplier, setGetSupplier] = useState([]);
  const [oDatetime, setODatetime] = useState("");

  const handleInputClick = (inputId, index) => {
    setActiveInput(inputId);
    setindex1(index);
  };

  // const handleChangeActive = (index) => {
  //   const newCartList = [...CardList];
  //   newCartList[index].shipment = newCartList[index].shipment === 0 ? 1 : 0;
  //   setcartList(newCartList);
  // };

  const handleKeypadClick = (value) => {
    // try {
    if (activeInput !== null) {
      const newCartList = [...CardList];
      const indexToUpdate = index1;

      if (
        activeInput === "quantity" ||
        activeInput === "unit_price" ||
        activeInput === "discount" ||
        activeInput === "shipment"
      ) {
        var currentValue = String(
          newCartList[indexToUpdate][activeInput] || ""
        );
        // console.log(currentValue);
        if (value === "." && currentValue.includes(".")) {
          return;
        }

        if (value === "." && !currentValue.includes(".")) {
          if (currentValue === "") {
            currentValue = "0";
          }
          newCartList[indexToUpdate][activeInput] = currentValue + ".0";
          setcartList(newCartList);
          setdot(1);
          return;
        }
        var newValue = currentValue + String(value);
        if (dot === 1) {
          var [integerPart] = currentValue.split(".");
          if (integerPart === "") {
            integerPart = "0";
          }
          newValue = integerPart + "." + value;
          setdot(0);
        }

        if (!isNaN(newValue)) {
          // if (activeInput === "shipment" || activeInput === "spec_order") {
          //   // newValue = String(parseInt(newValue));
          //   if (parseFloat(newValue) > newCartList[indexToUpdate].quantity) {
          //     if (activeInput === "shipment") {
          //       alert("Ship qty must be less or equal to total qty.");
          //     } else {
          //       alert("Special order qty must be less or equal to total qty.");
          //     }

          //     newCartList[indexToUpdate][activeInput] =
          //       newCartList[indexToUpdate][activeInput] + "";
          //     setcartList(newCartList);
          //     setdot(0);
          //     return;
          //   }
          // }
          newCartList[indexToUpdate][activeInput] = newValue;
          newCartList[indexToUpdate].total =
            newCartList[indexToUpdate].unit_price *
              newCartList[indexToUpdate].quantity -
            newCartList[indexToUpdate].discount *
              newCartList[indexToUpdate].quantity;
          //-newCartList[indexToUpdate].discount;

          setcartList(newCartList);
          setdot(0);
        }
      } else if (activeInput === "tax" && index1 === -2) {
        const currentTaxValue = String(tax || "");

        if (value === "." && currentTaxValue.includes(".")) {
          return;
        }

        const newValue = currentTaxValue + String(value || "");
        if (!isNaN(newValue)) {
          settax(newValue);
        }
      } else if (activeInput === "shipment1" && index1 === -3) {
        const currentShipmentValue = String(projectname2 || "");

        if (value === "." && currentShipmentValue.includes(".")) {
          return;
        }

        const newValue = currentShipmentValue + String(value || "");
        if (!isNaN(newValue)) {
          setprojectname2(newValue);
        }
      }
    }
  };

  const handleAddcartClick = () => {
    const isProductInCart = CardList.some(
      (item) => item.product_id === product.value
    );
    if (!isProductInCart && product.value && userID && userToken) {
      const resp1 = GetProductByIdSale(
        p_code,
        store_id_param,
        userID,
        userToken
      );
      resp1
        .then(function (result) {
          const defaultProduct = {
            product_id: result.data[0].product_id,
            name: result.data[0].name,
            code: result.data[0].code,
            unit_price: result.data[0].unit_price,
            cost_price: result.data[0].cost_price,
            quantity: qty,
            item_no: 0,
            discount: result.data[0].discount,
            total:
              result.data[0].unit_price * qty - result.data[0].discount * qty,
            // - result.data[0].discount,
            // shipment: 0,
            shipment: qty,
            image: result.data[0].image,
            req_delivery_date: null,
            details: result.data[0].details,
            supplier_id: supplier_ids,
            supplier_name: supplier_name,
            notes: "",
          };

          setcartList((prevProductList) => [
            defaultProduct,
            ...prevProductList,
          ]);
          setProducts("");
          setSelectedProduct("");
          setSelectedSupplier("");
          setSupplierOptions([]);
          setqty(1);

          setProductOptions((prevOptions) =>
            prevOptions.filter(
              (option) => option.values !== result.data[0].product_id
            )
          );
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    setSelectedProduct("");
    setSelectedSupplier("");
    setSupplierOptions([]);
    setqty(1);
  };

  // const handleChangeCustomer = (selectedOption) => {
  //   if (selectedOption && selectedOption.value) {
  //     setSelectedCustomer(selectedOption);
  //     setActiveInput("customer");
  //     setCustomer(selectedOption);
  //     setcustomer_ids(selectedOption.value);
  //     getCustomerDetail(selectedOption.value);
  //     if (selectedOption.value) {
  //       setGetProduct([]);
  //       // getProductsAll();
  //     } else {
  //       setGetProduct([]);
  //     }
  //     setcartList([]);
  //     settax(0);
  //     settotaldiscount(0);
  //     settotalitem(0);
  //     setSelectedProduct("");
  //     setnote("");
  //     settrackingno("");
  //     setprojectname("");
  //     setprojectname1("");
  //     setprojectname2(0);
  //     setshipmethod("");
  //   }
  // };

  const handleChangeSupplier = (selectedOption) => {
    TimeoutUtility.resetTimeout();
    if (selectedOption && selectedOption.value) {
      setSelectedSupplier(selectedOption);
      setActiveInput("customer");
      setsupplier_ids(selectedOption.value);
      setsupplier_name(selectedOption.label);
    } else {
      setSelectedSupplier(null);
      setActiveInput("customer");
      setsupplier_ids("");
      setsupplier_name("");
    }
  };

  const handleChangeProduct1 = async (selectedOption, event) => {
    if (
      event.key === "Enter" &&
      selectedOption &&
      selectedOption.values &&
      userID &&
      userToken
    ) {
      setSelectedProduct(selectedOption);
      setqty(1);
      await getAllVendorsNameByPurchaseProduct(
        selectedOption.values,
        userID,
        userToken
      )
        .then((resp) => {
          setGetSupplier(resp.data || []);
        })
        .catch((err) => {
          console.log(err.message);
        });
      setActiveInput("product");
      setProducts(selectedOption);
      const selectedProduct = selectedOption.values;
      setp_code(selectedProduct);
      handleAddcartClick();
    }
  };

  const handleChangeProduct = async (selectedOption) => {
    if (selectedOption && selectedOption.values && userID && userToken) {
      setSelectedProduct(selectedOption);
      setqty(1);
      await getAllVendorsNameByPurchaseProduct(
        selectedOption.values,
        userID,
        userToken
      )
        .then((resp) => {
          setGetSupplier(resp.data || []);
        })
        .catch((err) => {
          console.log(err.message);
        });
      var ProductStr1 = ProductStr;
      ProductStr1 = ProductStr1 + selectedOption.values + " ";
      setProductStr(ProductStr1);
      setActiveInput("product");
      setProducts(selectedOption);
      const selectedProduct = selectedOption.values;
      setp_code(selectedProduct);
    }
  };

  const handleChangeQty = (e) => {
    setActiveInput("qty");
    setqty(e.target.value);
    //setindex1(-2);
  };

  const handleNotesChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].notes = value;
    setcartList(newCartList);
    setActiveInput("notes");
    setindex1(index);
  };

  const handleChangeNote = (e) => {
    setnote(e.target.value);
  };
  const handleChangeProjectName2 = (e) => {
    setActiveInput("shipment1");
    setprojectname2(e.target.value);
    setindex1(-3);
  };
  // const handleChangeProjectName1 = (e) => {
  //   setprojectname1(e.target.value);
  // };
  const handleChangeProjectName = (e) => {
    setprojectname(e.target.value);
  };
  // const handleChangeTrackingNo = (e) => {
  //   settrackingno(e.target.value);
  // };
  // const handleChangeShipMethod = (e) => {
  //   setshipmethod(e.target.value);
  // };

  // const getCustomerDetail = async (id) => {
  //   try {
  //     const resp = await GetSaleCustomerById(id);
  //     setCustomerDetail(resp.data || []);
  //     // console.log(resp.data[0].phone);
  //     setcusPhone(resp.data[0].phone);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const getProductsAll = async () => {
  //   try {
  //     const resp = await GetProductByStoreID(store_id_param);
  //     setGetProduct(resp.data || []);
  //     console.log(resp.data);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const UpdateStatus = async (stat, id) => {
  //   // imgg.preventDefault();
  //   const response = await EditSaleOrderStatusBYSo_id(stat, id);
  //   if (response.status === 200) {
  //     // if(response.data[0][0].result){
  //     alert("Sale Order Status Updated Successfully");
  //     // }
  //     // else{
  //     //   alert("Order Status Failed to Update!");
  //     // }
  //   } else {
  //     alert("Failed to Update Sale Order Status");
  //   }
  // };

  const handleSaleOrderClick = async (e) => {
    if (CardList.length === 0) {
      alert("Estimation cart is empty.");
      return;
    }
    setIsPageFrozen(true);
    setsaveClick(!saveClick);
  };

  // const handlePrintOrderClick = async (e) => {
  //   setprint_flag(true);
  //   handleSaleOrderClick();
  // };

  useEffect(() => {
    async function SaveOrder() {
      if (saveClick && userID && userToken) {
        const updatedFormData = { ...formData };

        if (tax === null) {
          updatedFormData.tax = 0;
        } else {
          updatedFormData.tax = tax;
        }

        if (projectname === null) {
          updatedFormData.project_name = "";
        } else {
          updatedFormData.project_name = projectname;
        }

        if (projectname2 === null) {
          updatedFormData.shipment = 0;
        } else {
          updatedFormData.shipment = projectname2;
        }

        updatedFormData.so_id = so_id_param;
        updatedFormData.est_sale = "E";
        updatedFormData.customer_id = customer_ids;
        updatedFormData.total = grandtotal;
        updatedFormData.amount_pending = grandtotal;
        updatedFormData.amount_paid = 0.0;
        updatedFormData.so_note = note;
        updatedFormData.total_price = grandtotal;
        updatedFormData.discount = totaldiscount;
        updatedFormData.invoice_id = invoice_id;
        updatedFormData.store_id = store_id_param;
        updatedFormData.user_id = userID;
        updatedFormData.sale_products = [];
        //updatedFormData.shipments = [];

        //console.log(qtyShipped);
        const uniqueValues = new Set(
          CardList.map((v) => String(v.product_id) + v.notes)
        );

        if (uniqueValues.size < CardList.length) {
          //console.log(uniqueValues);
          alert(
            `Duplicate notes for same products found.\nSales Order failed to create.`
          );
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          return;
        } else {
          CardList.forEach((product, index) => {
            // const qtyShippedProduct = qtyShipped.find(
            //   (item) =>
            //     parseInt(item.product_id) === parseInt(product.product_id)
            // );

            //console.log("Product:", product);
            //console.log("QtyShippedProduct:", qtyShippedProduct);

            // if (parseInt(product.shipment) > 0) {
            //   // console.log('Condition 1: Shipment > 0');
            //   if (qtyShippedProduct) {
            //     // console.log('Condition 2: QtyShippedProduct found');
            //     const shippedQuantity =
            //       parseInt(product.shipment) -
            //       parseInt(qtyShippedProduct.shipment);
            //     //console.log(parseInt(qtyShippedProduct.shipment));
            //     const shipment = {
            //       product_id: product.product_id,
            //       s_quantity: product.quantity,
            //       quantity_shipped: shippedQuantity,
            //       shipped_note: "",
            //     };
            //     updatedFormData.shipments.push(shipment);
            //   } else {
            //     // console.log('Condition 3: QtyShippedProduct not found');
            //     const shipment = {
            //       product_id: product.product_id,
            //       s_quantity: product.quantity,
            //       quantity_shipped: product.shipment,
            //       shipped_note: "",
            //     };
            //     updatedFormData.shipments.push(shipment);
            //   }
            // }
            var dt = null;
            if (
              product.req_delivery_date !== null ||
              product.req_delivery_date !== ""
            ) {
              dt = product.req_delivery_date;
            }

            const saleProduct = {
              product_id: product.product_id,
              supplier_id: product.supplier_id,
              item_no: product.item_no,
              quantity: product.quantity,
              price: product.unit_price,
              cost_price: product.cost_price,
              item_note: product.notes,
              discount: product.discount,
              req_delivery_date: dt,
            };
            updatedFormData.sale_products.push(saleProduct);
          });

          //console.log(updatedFormData);
          // setFormData(updatedFormData);
          if (deleteprodList.length === 0) {
          } else {
            DeleteProductfrom_SaleOrder();
          }

          // if (unshippedList.length === 0) {
          // } else {
          //   DeleteUnshippedProductsfrom_SaleOrder();
          // }
          const response = await EditEstimationApi(
            updatedFormData,
            userID,
            userToken
          );
          if (response.status === 200) {
            if (print_flag && so_id_param) {
              alert("Estimation updated successfully.");
              await openNewTab(so_id_param);
              setprint_flag(false);
              window.location.reload();
            }
            //   if (orderstatuslable === "OPEN") {
            //     // console.log(response.data[0][0].so_id)
            //     if (so_id_param) {
            //       // console.log(response.data[0][0].so_id)
            //       UpdateStatus(80, so_id_param);
            //     } else {
            //       UpdateStatus(81, so_id_param);
            //     }
            //   } else {
            //     // return;
            //   }
            if (!print_flag) {
              alert("Estimation updated successfully.");
            }
          } else {
            alert("Estimation failed to update.");
            setsaveClick(!saveClick);
            setIsPageFrozen(false);
            return;
          }
          setsaveClick(!saveClick);
          setIsPageFrozen(false);
          if (!print_flag) {
            window.location.reload();
          }
        }
      }
    }
    SaveOrder();
  }, [saveClick]);

  // const handleStatusClick = async (event) => {
  //   event.preventDefault();
  //   if (CardList.length === 0) {
  //     alert("Estimation Cart is Empty");
  //     return;
  //   }
  //   var f = 0;
  //   CardList.forEach((element) => {
  //     if (parseInt(element.quantity) <= parseInt(element.shipment)) {
  //       f = 1;
  //     } else {
  //       // f = 0;
  //       if (orderstatuslable === "OPEN") {
  //         if (
  //           window.confirm(
  //             `Quantity is not completely Shipped.\n Still want to Continue?`
  //           )
  //         ) {
  //           f = 1;
  //         } else {
  //           f = 0;
  //         }
  //       } else {
  //         f = 1;
  //       }
  //       // alert("Order cannot be Closed.\nQuantity is not completely Shipped");
  //       // return;
  //     }
  //     // f  = 1;
  //   });

  //   if (f === 1 || orderstatuslable === "CLOSE") {
  //     if (OrderStatus === "MARK AS OPEN") {
  //       setOrderStatus("MARK AS CLOSE");
  //       setorderstatuslable("OPEN");
  //     } else {
  //       setOrderStatus("MARK AS OPEN");
  //       setorderstatuslable("CLOSE");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   // handleStatusClick();
  // }, [OrderStatus]);

  const handleViewEmployeesClick2 = async (event) => {
    event.preventDefault();
    try {
      const so_ids = so_id_param + "_" + store_id_param;
      const path = `/Estimates/ConvertEstimate/${so_ids}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClearClick = async (event) => {
    if (window.confirm(`Are you sure you want to Clear the order?`)) {
      setcartList([]);
      settax(0);
      settotaldiscount(0);
      settotalitem(0);
      setSelectedProduct("");
      setnote("");
      //settrackingno("");
      setprojectname("");
      //setprojectname1("");
      setprojectname2(0);
      //setshipmethod("");
    }
  };

  const handleToggle = (value) => {
    //setToggle((pre) => !pre);
    // if (!viewOrderProductOpen) {
    //   openViewOrderProduct();
    // }
    setActiveProdMenuId({
      product_id: value.product_id,
      store_id: store_id_param,
    });
    setActiveProdMenu(true);
    //setproductID(value.product_id);
  };

  const handleNewClick = async (event) => {
    event.preventDefault();
    try {
      // console.log("Add new");
      const path = `/Estimates/AddEstimation/${store_id_param}`;
      window.open(path, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const CalculateAllFields = () => {
    let total_amt = 0,
      total_itm = 0;
    let total_dis = 0;
    let grandTotal = 0.0;
    let taxx = 0,
      dd = 0;

    if (tax) {
      taxx = parseFloat(tax);
    }

    for (let i = 0; i < CardList.length; i++) {
      let a = 0,
        b = 0,
        c = 0;
      if (CardList[i].total) {
        a = parseFloat(CardList[i].total);
      }
      if (CardList[i].discount) {
        b = parseFloat(CardList[i].discount);
      }
      if (CardList[i].quantity) {
        c = parseFloat(CardList[i].quantity);
      }
      if (projectname2) {
        dd = parseFloat(projectname2);
      }
      total_amt = total_amt + a + b * c;
      total_dis = total_dis + b * c;
      total_itm = total_itm + c;

      // grandTotal = ((parseFloat(CardList[i].unit_price) - parseFloat(CardList[i].discount)) * parseFloat(CardList[i].quantity));
    }
    settotaldiscount(total_dis);
    settotalAmount(total_amt);
    settotalitem(total_itm);

    taxx = (taxx / 100) * (total_amt - total_dis);
    grandTotal = total_amt - total_dis + taxx + dd;

    setgrandtotal(grandTotal);
  };

  const handleBackClick = async (event) => {
    if (
      window.confirm(
        `Are you sure you want to Close without saving the changes?`
      )
    ) {
      // console.log(CardList);
      window.close();
    }
  };

  // const handleShipmentClick = (inputId, index) => {
  //   setActiveInput(inputId);
  //   setindex1(index);
  // };
  const handleUnitPriceChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].unit_price = value;
    newCartList[index].total =
      value * parseFloat(newCartList[index].quantity) -
      parseFloat(newCartList[index].quantity) *
        parseFloat(newCartList[index].discount);
    setcartList(newCartList);
    setActiveInput("unit_price");
    setindex1(index);
  };

  // const handleShipmentChange = (index, value) => {
  //   const newCartList = [...CardList];
  //   if (value > newCartList[index].quantity) {
  //     if (
  //       window.confirm(
  //         `Ship qty greater than total qty.\nStill want to continue?`
  //       )
  //     ) {
  //       newCartList[index].shipment = value;
  //       setcartList(newCartList);
  //       setActiveInput("shipment");
  //       setindex1(index);
  //     }
  //   } else if (
  //     value < newCartList[index].quantity &&
  //     orderstatuslable === "CLOSE"
  //   ) {
  //     if (
  //       window.confirm(`Order is already shipped.\nStill want to continue?`)
  //     ) {
  //       newCartList[index].shipment = value;
  //       setcartList(newCartList);
  //       setActiveInput("shipment");
  //       setindex1(index);
  //     }
  //   } else {
  //     newCartList[index].shipment = value;
  //     setcartList(newCartList);
  //     setActiveInput("shipment");
  //     setindex1(index);
  //   }
  // };
  // const handleQtyBlur = (index, value) => {
  //   const newCartList = [...CardList];
  //   const newqtyShipped = [...qtyShipped];

  //   if(value < newCartList[index].shipment){
  //     if(window.confirm(`Total qty must be greater or equal to ship qty.\nStill want to continue?`)){

  //     }
  //     else{
  //       newCartList[index].quantity = newqtyShipped[index].quantity;
  //       newCartList[index].total =
  //       newqtyShipped[index].quantity * newCartList[index].unit_price -
  //       newqtyShipped[index].quantity * newCartList[index].unit_price * newCartList[index].discount;
  //       setcartList(newCartList);
  //     }
  //   }
  //   setActiveInput("quantity");
  //   setindex1(index);
  // };

  const handleQuantityChange = (index, value) => {
    const newCartList = [...CardList];
    //const a = newCartList[index].quantity;
    newCartList[index].quantity = value;
    newCartList[index].shipment = value;
    newCartList[index].total =
      value * parseFloat(newCartList[index].unit_price) -
      value * parseFloat(newCartList[index].discount);
    setcartList(newCartList);
    setActiveInput("quantity");
    setindex1(index);
  };

  const handleDiscountChange = (index, value) => {
    const newCartList = [...CardList];
    newCartList[index].discount = value;
    newCartList[index].total =
      newCartList[index].quantity * newCartList[index].unit_price -
      newCartList[index].quantity * value;
    setcartList(newCartList);
    setActiveInput("discount");
    setindex1(index);
  };
  const formatCurrency = (number) => {
    return number.toLocaleString("en-PK", {
      style: "currency",
      currency: "PKR",
      minimumFractionDigits: 2,
    });
  };

  const handleChangeTax = (e) => {
    setActiveInput("tax");
    settax(e.target.value);
    setindex1(-2);
  };

  const DeleteProductfrom_SaleOrder = async () => {
    const updatedFormData = { ...formData1 };
    updatedFormData.store_id = store_id_param;
    updatedFormData.invoice_id = invoice_id;
    setFormData1(updatedFormData);
    try {
      await Promise.all(
        deleteprodList.map(async (item) => {
          // console.log(item);
          if (item.item_no !== 0) {
            const deleteFormData = {
              ...updatedFormData,
              product_id: item.product_id,
              item_no: item.item_no,
            };
            const response = await DeleteEditSaleOrderProduct(
              deleteFormData,
              userID,
              userToken
            );

            if (response.status !== 200) {
              console.error(`Product ${item.product_id} failed to remove.`);
            }
          }
        })
      );
    } catch (error) {
      console.error("Error during product removal:", error);
      alert("An error occurred during product removal.");
    }
  };

  // const DeleteUnshippedProductsfrom_SaleOrder = async () => {
  //   console.log(unshippedList);
  //   const shippableProductIds = CardList.filter(
  //     (product) => product.shipment > 0
  //   ).map((product) => product.product_id);
  //   const updatedUnshippedList = unshippedList.filter(
  //     (productId) => !shippableProductIds.includes(productId)
  //   );
  //   setunshippedList(updatedUnshippedList);
  //   const updatedFormData = { ...formData1 };
  //   updatedFormData.invoice_id = invoice_id;
  //   setFormData1(updatedFormData);
  //   console.log("heheh " + updatedUnshippedList);
  //   try {
  //     await Promise.all(
  //       updatedUnshippedList.map(async (productId) => {
  //         const deleteFormData = { ...updatedFormData, product_id: productId };
  //         const response = await RemoveProd_fromShipmentTrans(deleteFormData);

  //         if (response.status !== 200) {
  //           console.error(`Product shipment  ${productId} failed to remove.`);
  //         }
  //       })
  //     );
  //   } catch (error) {
  //     console.error("Error during product shipment removal:", error);
  //     alert("An error occurred during product shipment removal.");
  //   }
  // };

  const handleDeleteClick = async (index) => {
    const removedProduct = CardList[index];
    if (
      window.confirm(`Do you want to remove ${removedProduct.code} from Cart?`)
    ) {
      setdeleteprodList((prevArray) => [
        ...prevArray,

        {
          product_id: removedProduct.product_id,
          item_no: removedProduct.item_no,
        },
      ]);
      const item1 = CardList[index].product_id;
      const newCartList = [...CardList];
      newCartList.splice(index, 1);
      setcartList(newCartList);

      const currentIdsArray = ProductStr.split(" ");
      const updatedIdsArray = currentIdsArray.filter(
        (id) => id !== String(item1)
      );
      const updatedProductIds = updatedIdsArray.join(" ");
      setProductStr(updatedProductIds);
    }
  };

  const customFilter = (option, inputValue) => {
    const optionSearchField = String(option.value).toLowerCase();
    const lowerCasedInput = inputValue.toLowerCase();
    return optionSearchField.includes(lowerCasedInput);
  };

  function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }

  const handleInputChange = (inputValue) => {
    // if (customer_ids) {
    if (inputValue && inputValue.length >= 2 && userID && userToken) {
      const searchResults = getProductFilterSubString(
        store_id_param,
        inputValue,
        ProductStr,
        userID,
        userToken
      );
      searchResults
        .then((response) => {
          const productsArray = response.data;
          // const filteredProducts = productsArray.filter(
          //   (product) =>
          //     !CardList.some((item) => item.product_id === product.product_id)
          // );
          // const newProductOptions = filteredProducts.map((item) => ({
          const filteredProducts = productsArray.filter(
            (product) => new Date(product.open_datetime) <= new Date(oDatetime)
          );
          const newProductOptions = filteredProducts.map((item) => ({
            values: item.product_id,
            value: `${item.code} ${item.productname} ${
              item.details
            } ${formatCurrency(item.unit_price)}`.toLowerCase(),
            label: (
              <div
                style={{
                  display: "flex",
                  marginTop: "4px",
                }}
              >
                {item.image && (
                  <div
                    style={{
                      flex: "0 0 10%",
                    }}
                  >
                    <img
                      className="rounded-xl"
                      src={`data:image/jpeg;base64,${item.image}`}
                      alt={`Product ${item.code}`}
                      style={{
                        maxWidth: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    flex: item.image ? "0 0 78%" : "0 0 88%",
                    paddingLeft: "0px",
                  }}
                >
                  <div>
                    <div style={{ fontWeight: "bold", fontSize: "16px" }}>{`${
                      item.code
                    } ${truncate(
                      item.productname,
                      item.image ? 48 : 54
                    )}`}</div>
                  </div>
                  <div style={{ fontSize: "14px" }}>{`${truncate(
                    item.details,
                    item.image ? 68 : 76
                  )}`}</div>
                </div>

                <div
                  style={{
                    flex: "0 0 12%",
                    fontWeight: "500",
                    color: currentColor,
                    textAlign: "right",
                    verticalAlign: "middle",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "15px",
                  }}
                >{`${formatCurrency(item.unit_price)}`}</div>
              </div>
            ),
          }));
          setProductOptions(newProductOptions);
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    } else {
      // setProductOptions(productOptions1);
    }
    // }
  };

  const handleChangeReqDate = (index, e) => {
    const newCartList = [...CardList];
    if (e === "undefined") {
      newCartList[index].req_delivery_date = null;
      //setreq_date(null);
    } else {
      newCartList[index].req_delivery_date = e;
      //setreq_date(e);
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (so_id_param && userID && userToken) {
        // GetAllCustomersName()
        //   .then((resp) => {
        //     setGetCustomer(resp.data || []);
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });

        GetAccToIdBySaleOrder1(so_id_param, userID, userToken)
          .then((resp) => {
            // setacc_to_id(resp.data[0].name);
            setacc_from_bal(resp.data[0].end_balance);
            // console.log(resp.data[0].name);
            // console.log(resp.data[0].end_balance);
          })
          .catch((err) => {
            console.log(err.message);
          });

        var errr = 0;
        GetSaleOrderCustomerByID(so_id_param, userID, userToken)
          .then((resp) => {
            if (resp.data[0].est_sale === "E") {
              setcusFlag(
                // resp.data[0].customer_id + " " +
                resp.data[0].name
              );
              setcustomer_ids(resp.data[0].customer_id);
              setstore_id_param(resp.data[0].store_id);
              //setprojectname1(resp.data[0].customer_po_no);
              //setcusPhone(resp.data[0].customer_po_no);
              //settrackingno(resp.data[0].tracking_no);
              setprojectname(resp.data[0].project_name);
              //setshipmethod(resp.data[0].ship_method);
              settax(resp.data[0].tax);
              setnote(resp.data[0].so_note);
              setinvoice_id(resp.data[0].invoice_id);
              //console.log(resp.data[0].invoice_id);
              setprojectname2(resp.data[0].shipment);
              //setstatus_id(resp.data[0].status_id);

              // if (resp.data[0].status_id === 81) {
              //   setOrderStatus("MARK AS OPEN");
              //   setorderstatuslable("CLOSE");
              // } else {
              //   setOrderStatus("MARK AS CLOSE");
              //   setorderstatuslable("OPEN");
              // }
              var d1 = new Date(resp.data[0].est_date);
              d1.setMinutes(d1.getMinutes() - d1.getTimezoneOffset());
              setODatetime(d1.toISOString().slice(0, 19));
            } else {
              errr = 1;
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
        //setcartList([]);

        // getProductsAll();
        //   getProductsAll();
        if (errr === 0) {
          GetSaleOrderDetailsByID(so_id_param, userID, userToken).then(
            function (result) {
              //console.log(result.data);
              if (result.data) {
                const productList = result.data.map((item) => {
                  var reqDeliveryDate = "";
                  if (item.req_delivery_date !== null) {
                    reqDeliveryDate = new Date(item.req_delivery_date);
                    reqDeliveryDate.setUTCHours(24);
                    reqDeliveryDate = reqDeliveryDate
                      .toISOString()
                      .split("T")[0];
                  }
                  return {
                    product_id: item.product_id,
                    name: item.product_name,
                    code: item.code,
                    unit_price: item.unit_price,
                    quantity: item.quantity,
                    cost_price: item.cost_price,
                    discount: item.discount,
                    total: (item.unit_price - item.discount) * item.quantity,
                    item_no: item.item_no,
                    shipment: item.product_shipped,
                    image: item.image,
                    details: item.details,
                    notes: item.item_note,
                    req_delivery_date: reqDeliveryDate,
                  };
                });

                setcartList(() => [...(productList || [])]);

                // const qtyShippedList = productList.map((product) => ({
                //   product_id: product.product_id,
                //   shipment: product.shipment,
                //   unit_price: product.unit_price,
                //   discount: product.discount,
                //   item_no: product.item_no,
                //   notes: product.item_note,
                // }));

                // setqtyShipped((prevQtyShippedList) => [
                //   ...prevQtyShippedList,
                //   ...(qtyShippedList || []),
                // ]);

                // setqtyShipped([CardList]);
                // const unshippedSet = new Set();

                // productList.forEach((product, index) => {
                //   if (product.shipment > 0) {
                //     unshippedSet.add(product.product_id);
                //   }
                // });
                //const unshippedArray = Array.from(unshippedSet);
                //setunshippedList(unshippedArray);

                //console.log(unshippedArray);
              }
            }
          );
        } else {
          alert("Estimate converted to Sales Order!");
          window.close();
        }
        // const productIdsInCart = CardList.map((product) => product.product_id);
        // setProductOptions((prevOptions) =>
        //   prevOptions.filter((option) => !productIdsInCart.includes(option.value))
        // );
      }
    }
    fetchData();
  }, [so_id_param, userID, userToken]);

  useEffect(() => {
    const fetchSupplierOptions = async () => {
      if (GetSupplier) {
        const fetchedSupplierOption = GetSupplier.map((item) => ({
          label: `${item.vendor}`,
          value: item.vendor_id,
        }));
        //console.log(fetchedCustomerOption);
        //setDefaultSupplier(fetchedSupplierOption[0]);
        setSupplierOptions(fetchedSupplierOption);
        setSelectedSupplier(fetchedSupplierOption[0]);
        if (fetchedSupplierOption[0]) {
          setsupplier_ids(fetchedSupplierOption[0].value);
          setsupplier_name(fetchedSupplierOption[0].label);
          //getCustomerDetail(fetchedCustomerOption[0].value);
        }
      }
      //setcartList([]);
    };
    fetchSupplierOptions();
  }, [GetSupplier]);

  // useEffect(() => {
  //   const fetchProductOptions = async () => {
  //     const fetchedProductOptions = GetProduct.map((item) => ({
  //       label: `${item.code} ${item.productname}`,
  //       value: item.product_id,
  //     }));
  //     setProductOptions(fetchedProductOptions);
  //   };
  //   fetchProductOptions();
  // }, [GetProduct, customer_ids]);

  // useEffect(() => {
  //   const fetchCustomerOptions = async () => {
  //     const fetchedCustomerOption = GetCustomer.map((item) => ({
  //       label: `${item.name}`,
  //       value: item.customer_id,
  //       balance: item.balance,
  //       account_id: item.account_id,
  //     }));
  //     setCustomerOptions(fetchedCustomerOption);
  //     //setcartList([]);
  //   };
  //   fetchCustomerOptions();
  // }, [GetCustomer]);

  useEffect(() => {
    CalculateAllFields();
    //console.log(cusFlag);
    //setSelectedCustomer(cusFlag);
    // const productIdsInCart = CardList.map((product) => product.product_id);
    // setProductOptions((prevOptions) =>
    //   prevOptions.filter((option) => !productIdsInCart.includes(option.value))
    // );
  }, [CardList, tax, projectname2]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxHeight: "70px",
      height: "70px",
      fontSize: "14px",
    }),
    option: (provided) => ({
      ...provided,
      maxHeight: "70px",
      fontSize: "14px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "250px",
    }),
  };

  return (
    <div className="m-0 md:m-4 p-4 md:p-8 bg-white rounded-3xl">
      {isPageFrozen && <div className="overlay-freeze" />}
      <Container fluid className="g-0 p-0 justify-end">
        <Row
          xs={1}
          sm={1}
          className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={7} className="container-col">
            <Header title="EDIT ESTIMATION" />
          </Col>
          <Col md={2} className="container-col">
            <label className="label" style={{ fontSize: "12px" }}>
              <span style={{ paddingLeft: "8px" }}>Estimation DateTime:</span>
            </label>
            <input
              disabled
              className="input"
              id="o_date"
              type="datetime-local"
              style={{
                textAlign: "left",
                fontSize: "12px",
                width: "178px",
                background: "lightgray",
              }}
              value={oDatetime}
            />
          </Col>
          <Col md={3} className="container-col">
            <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
                // textAlign: "right",
              }}
            >
              E# {so_id_param}
            </label>
            {/* <label
              style={{
                marginLeft: "32px",
                fontWeight: "bold",
                fontSize: "18px",
                backgroundColor: currentColor,
                color: "white",
                padding: "4px",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderRadius: "5px",
              }}
            >
              ORDER STATUS: {orderstatuslable}
            </label> */}
          </Col>
        </Row>

        <Row
          xs={1}
          sm={1}
          className="justify-content-center"
          style={{
            padding: "0",
          }}
        >
          <Col md={9} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <label className="label" style={{ fontSize: "18px" }}>
                    Customer:
                  </label>
                  <div className="sale-input-div">
                    <div className="sale-input">
                      <input
                        className="input"
                        style={{
                          width: "100%",
                          height: "36px",
                        }}
                        type="text"
                        value={cusFlag}
                        readOnly
                        disabled
                      />
                    </div>
                    <div
                      className="sale-bal ml-4"
                      style={{ backgroundColor: currentColor }}
                    >
                      <label>{formatCurrency(acc_from_bal)}</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0",
                }}
              >
                <Col md={12} className="container-col">
                  <div style={{ display: "flex" }}>
                    <label
                      htmlFor="ProductSelect"
                      className="label"
                      style={{ fontSize: "18px", flex: "56%" }}
                    >
                      Product:
                    </label>
                    <label
                      className="label"
                      style={{ fontSize: "18px", flex: "12%" }}
                    >
                      Quantity:
                    </label>
                    <label
                      className="label"
                      style={{ fontSize: "18px", flex: "32%" }}
                    >
                      Supplier:
                    </label>
                  </div>
                  <div className="sale-input-div">
                    <div className="sale-input">
                      <Select
                        inputId="ProductSelect"
                        className="myreact-select-prod"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        value={selectedProduct}
                        onChange={handleChangeProduct}
                        options={productOptions}
                        isSearchable
                        placeholder="Search Product With Name / Code"
                        //isClearable
                        styles={customStyles}
                        onKeyDown={(event) =>
                          handleChangeProduct1(selectedProduct, event)
                        }
                        filterOption={customFilter}
                        onInputChange={handleInputChange}
                      />
                      <input
                        id="qty"
                        type="number"
                        step="1"
                        min="0"
                        max="1000000"
                        className="input myreact-select-prod-qty"
                        //defaultValue={1.0}
                        value={qty}
                        placeholder="Quantity"
                        //onClick={() => handleInputClick("tax", -2)}
                        onChange={handleChangeQty}
                      />
                      <Select
                        className="myreact-select-supp"
                        inputId="supplier"
                        menuPlacement="bottom"
                        menuPosition="fixed"
                        value={selectedSupplier}
                        onChange={handleChangeSupplier}
                        options={SupplierOptions}
                        isSearchable
                        placeholder="Product Supplier"
                        isClearable
                        styles={customStyles}
                      />
                    </div>
                    <button
                      className="sale-bal ml-4"
                      type="button"
                      style={{
                        backgroundColor: currentColor,
                        fontWeight: "1000",
                        fontSize: "18px",
                        height: "100%",
                      }}
                      onClick={handleAddcartClick}
                    >
                      +
                    </button>
                  </div>
                </Col>
              </Row>
              <br />
              <br />
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale"
              >
                <div className="m-0 p-0">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="table-sale-tr">
                        <th style={{ width: "80px" }}>CODE</th>
                        <th style={{ width: "320px" }}>PRODUCT</th>
                        <th style={{ width: "75px" }}>PRICE</th>
                        <th style={{ width: "75px" }}>DISC</th>
                        <th style={{ width: "65px" }}>QTY</th>
                        <th style={{ width: "75px" }}>TOTAL</th>
                        <th style={{ width: "90px" }}>SUPPLIER</th>
                        <th style={{ width: "110px" }}>REQ DATE</th>
                        <th style={{ width: "280px" }}>NOTES</th>
                        <th style={{ width: "35px" }}>DEL</th>
                      </tr>
                    </thead>
                    <tbody className="pos-table-body">
                      {CardList?.map((item, index) => (
                        <tr key={index}>
                          <td onClick={() => handleToggle(item)}>
                            {item.code}
                          </td>
                          <td onClick={() => handleToggle(item)}>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              {item.image && (
                                <div
                                  className="image flex gap-4"
                                  style={{
                                    maxWidth: "72px",
                                    height: "72px",
                                    flex: "0 0 20%",
                                  }}
                                >
                                  <img
                                    className="rounded-xl"
                                    src={`data:image/jpeg;base64,${item.image}`}
                                    alt={`Product ${item.code}`}
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  flex: item.image ? "0 0 80%" : "0 0 100%",
                                  paddingTop: !item.image && "8px",
                                  paddingBottom: !item.image && "7px",
                                  alignSelf: "center",
                                }}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  {truncate(item.name, item.image ? 38 : 44)}
                                </div>
                                <div>
                                  {truncate(item.details, item.image ? 78 : 88)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="unit_price"
                                type="number"
                                step="1.00"
                                min="0"
                                value={item.unit_price}
                                onClick={() =>
                                  handleInputClick("unit_price", index)
                                }
                                onChange={(e) =>
                                  handleUnitPriceChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="discount"
                                min="0"
                                max={item.unit_price}
                                type="number"
                                step="1.00"
                                value={item.discount}
                                onClick={() =>
                                  handleInputClick("discount", index)
                                }
                                onChange={(e) =>
                                  handleDiscountChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="quantity"
                                type="number"
                                step="1.00"
                                min="0"
                                value={item.quantity}
                                onClick={() =>
                                  handleInputClick("quantity", index)
                                }
                                onChange={(e) =>
                                  handleQuantityChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>{formatCurrency(item.total)}</td>
                          <td>{item.supplier_name}</td>
                          <td>
                            <div className="centered-input">
                              <input
                                className="input"
                                id="req_date"
                                type="date"
                                value={item.req_delivery_date}
                                onChange={(e) =>
                                  handleChangeReqDate(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="centered-input">
                              <textarea
                                className="textarea"
                                id="notes"
                                type="text"
                                maxLength={250}
                                value={item.notes}
                                onClick={() => handleInputClick("notes", index)}
                                onChange={(e) =>
                                  handleNotesChange(index, e.target.value)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <button onClick={() => handleDeleteClick(index)}>
                              ❌
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center table-container-sale1"
              >
                <table className="borderless w-100 h-100">
                  <tbody>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ paddingTop: "8px" }}
                      >
                        SUB TOTAL:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          paddingTop: "8px",
                        }}
                      >
                        {formatCurrency(total_amount)}
                      </td>
                      <td
                        className="table-grand-sum"
                        rowSpan="5"
                        style={{ backgroundColor: currentColor }}
                      >
                        <div className="tgs-label">
                          GRAND TOTAL
                          <br />
                          <span className="tgs-value">
                            {formatCurrency(grandtotal)}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">DISCOUNT:</td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                        }}
                      >
                        {formatCurrency(totaldiscount)}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">TAX:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"%"}
                        </label>
                        <input
                          id="tax"
                          type="number"
                          step="1"
                          min="0"
                          max="100"
                          className="input table-sum-tb"
                          value={tax}
                          onClick={() => handleInputClick("tax", -2)}
                          onChange={handleChangeTax}
                          style={{
                            color: currentColor,
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="table-sum-label">SHIPPING:</td>
                      <td className="table-sum-cash">
                        <label
                          style={{
                            color: currentColor,
                          }}
                        >
                          {"$"}
                        </label>
                        <input
                          type="number"
                          name="shipment"
                          min="0"
                          step="1.00"
                          value={projectname2}
                          onChange={handleChangeProjectName2}
                          onClick={() => handleInputClick("shipment1", -3)}
                          className="input table-sum-tb"
                          style={{
                            marginLeft: "13px",
                            color: currentColor,
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="table-sum-label"
                        style={{ marginBottom: "8px" }}
                      >
                        TOTAL ITEMS:
                      </td>
                      <td
                        className="table-sum-cash"
                        style={{
                          color: currentColor,
                          marginBottom: "8px",
                        }}
                      >
                        {total_item || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </div>
          </Col>
          <Col md={3} className="container-col">
            <div className="card-sale">
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleSaleOrderClick}
                  >
                    <div className="action-btn">SAVE</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      //background: currentColor,
                      background: "gray",
                    }}
                    //onClick={handlePrintOrderClick}
                  >
                    <div className="action-btn">PRINT</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleNewClick}
                  >
                    <div className="action-btn">NEW</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleClearClick}
                  >
                    <div className="action-btn">CLEAR</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  padding: "0px",
                }}
              >
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingRight: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleViewEmployeesClick2}
                  >
                    <div className="action-btn">CONVERT TO SALES</div>
                  </Card>
                </Col>
                <Col
                  md={6}
                  className="container-col"
                  style={{ paddingLeft: "5px", paddingBottom: "10px" }}
                >
                  <Card
                    className="action-btns-card"
                    style={{
                      background: currentColor,
                    }}
                    onClick={handleBackClick}
                  >
                    <div className="action-btn">CLOSE</div>
                  </Card>
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <Col md={12} className="container-col right-table-field">
                  <label>Contact:</label>
                  <input
                    type="text"
                    name="project name"
                    value={projectname}
                    onChange={handleChangeProjectName}
                    placeholder="Contact"
                    className="input"
                  />
                </Col>
                <Col
                  md={12}
                  className="container-col  right-table-field"
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "16px",
                  }}
                >
                  <textarea
                    placeholder="Estimation Note"
                    id="noteTextarea"
                    value={note}
                    onChange={handleChangeNote}
                    className="textarea"
                  />
                </Col>
              </Row>
              <Row
                xs={1}
                sm={1}
                className="justify-content-center"
                style={{ padding: "0px" }}
              >
                {keypadButtons.map((number, index) => (
                  <Col md={4} className="container-col" key={index}>
                    <Card
                      className="keypad-button1"
                      style={{
                        border: "1px solid " + currentColor,
                        color: currentColor,
                      }}
                      key={number}
                      onClick={() => handleKeypadClick(number)}
                    >
                      {number}
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          {/* {toggle && (
            <div className="overlay1">
              <Sidebar
                close={() => setToggle(false)}
                product_id={productID}
                store_id={store_id_param}
              />
            </div>
          )} */}
        </Row>
      </Container>
    </div>
  );
};
export default EditEstimation;
